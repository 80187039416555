import React from "react";
import "./styles.scss";
import { FormatterHelperService } from "./../../../../libraries/common/formatter-helper.service";
import Checklist from './../../../../assets/img/about/subscription/checklist.svg'
import { Button, Spinner } from "@shopify/polaris";

declare interface IAboutUsCards {
    subscriptionBadgeType: string;
    subscriptionIcon: string;
    subscriptionTitle: string;
    subscriptionPaid: number;
    additionalCrossOutPaid?: number;
    subscriptionButtonText: string;
    subscriptionAvailableFeatures: Array<string>;
    subscriptionOnSubscribeClick: (urlLink: string) => void;
    loading?: boolean;
}

export class AboutUsCards extends React.Component<IAboutUsCards, any> {
    render() {
        return (
            <div className="about-us-cards my-3 py-3 row">
                <div className="col-12 d-flex flex-column">
                    <div className="d-flex position-relative ml-2 mb-3">
                        <img className="subscription-icon" src={this.props.subscriptionIcon} alt="subscription-mini-icon" />
                        <div className={`subscription-badge ${this.props.subscriptionBadgeType}`}>
                            {this.props.subscriptionBadgeType}
                        </div>
                    </div>
                    <div className="subscription-title mx-2 mb-2">
                        {this.props.subscriptionTitle}
                    </div>
                    {this.props.additionalCrossOutPaid && (
                        <div className="subscription-cross-out subscription-cross-out-plans mx-2">
                            {this.props.additionalCrossOutPaid}
                        </div>
                    )}
                    <div className={`subscription-paid mx-2 ${!this.props.additionalCrossOutPaid && "pt-3"}`}>
                        <p className="the-currency">
                            {this.props.subscriptionPaid > 0 && <span className="dollar">$</span>}
                            {this.props.subscriptionPaid > 0 ? FormatterHelperService.formatterCurrency(this.props.subscriptionPaid, "es-US", {
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                            }) : "Custom"}
                            {/* {this.props.subscriptionBadgeType !== 'custom' && (
                                <span className="subscription-paid-text-month">/{this.props.subscriptionBadgeType === "annual" ? "Tahun" : "Bulan"}</span>
                            )} */}
                        </p>
                    </div>
                    <div className="mx-1 mt-4 subscription-feature-content">
                        <p className="subscription-feature-text pb-3">Fitur</p>
                        <div className="subscription-features row pt-1">
                            {this.props.subscriptionAvailableFeatures.map((value, key) => (
                                <div className="col-12 d-flex pb-2" key={key}>
                                    <img src={Checklist} alt="available feature for subscription" />
                                    <span className="pl-2 subscription-feature-li" dangerouslySetInnerHTML={{ __html: value }}></span>
                                </div>
                            ))}
                        </div>
                        <div className={`subscription-btn ${this.props.loading && "disable-btn"}`}>
                            <button
                                disabled={this.props.loading}
                                onClick={() => this.props.subscriptionOnSubscribeClick(this.props.subscriptionBadgeType)}
                            >
                                {!this.props.loading && this.props.subscriptionButtonText}
                                {this.props.loading && (
                                    <Spinner
                                        accessibilityLabel="Loading..."
                                        size="small"
                                    />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                {this.props.children}
            </div>
        )
    }
}