import * as _ from 'lodash';

export class MessageParserService {

  public static parseSpesific(response: any) {
    if (_.has(response.response, 'data.errors')) {
    // if (_.has(response, 'data.errors') && _.has(response, 'data.data')) {
      // _.set(response.data, 'summary', {});

      return MessageParserService.handleErrors(response.response);
    }
  }

  private static handleErrors(response: any) {
    const errorMessages = [];

    _.forEach(response.data.errors, error => {
      const errorMessage = _.get(error, 'error_message');
      const errorCode = error.error_code;
      if (errorMessage || errorCode) {
        if (errorMessage && errorCode) {
          errorMessages.push(`${errorMessage} [${errorCode}]`);
        } else if (errorMessage && !errorCode) {
          errorMessages.push(errorMessage);
        } else if (!errorMessage && errorCode) {
          errorMessages.push(`Error code ${errorCode}`);
        }

        if (errorCode) {
          _.set(response.data, `summary.${error.error_code}`, true);
        }
      }
    });

    return errorMessages;
  }
}
