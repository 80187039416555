/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { CommonHelperService } from './../../../libraries/common/common-helper.service';
import { Link, useHistory } from 'react-router-dom';
import { Translation } from 'react-i18next';
import style from './main-navbar.module.scss';
import { AboutUsRestService } from './../../../features/about/about-us-rest.service'
import { useAppBridge } from '@shopify/app-bridge-react';
import { AppContext } from '../../../store/app-context';

function NavLink(props) {
  const history = useHistory();
  const isActive = history.location.pathname === props.href;

  return (
    <Link to={props.href} className={
      CommonHelperService.conclass(
        style.navbarItem,
        isActive ? style.navbarItemActive : ''
      )
    }>
      {props.label}
    </Link>
  );
}

export function MainNavbarLayout() {
  const history = useHistory();
  const context = useAppBridge();
  const { dispatch } = useContext(AppContext);
  const hostOrigin = context.hostOrigin.replace(/^https:\/\//, '');
  const [isHidden, setIsHidden] = useState(false);
  // for checking flag isSubsActive or Not
  useEffect(() => {
    // use andi-development-store.myshopify.com on Function GetSubscript.. for testing only
    AboutUsRestService.getSubscriptionPlanIsActiveFlag(hostOrigin)
      .then(res => {
        if (res?.data) {
          const { status, payment, redirectUrl } = res.data;
          dispatch({
            type: 'SET_SUBSCRIPTION_DATA',
            payload: { status, payment }
          })
          setIsHidden(status === "inactive" || status === "expired" || payment === "pending" ? false : true);
          const pathName = history.location.pathname;
          if (status === "inactive" && payment === "paid") {
            window.open(redirectUrl, "_blank", "noopener noreferrer");
          }
          if (status === "inactive" && payment === "pending" && pathName !== '/about') history.push('/about');
          if ((status === "expired" || status === "cancelled") && pathName !== "/about") history.push('/about');
        }
      })
      .catch(err => console.log(err))
  }, [
    history,
    hostOrigin
  ]);

  return (
    <Translation>
      {
        t => (
          <div
            className={
              style.navbar
            }
          >
            {isHidden && (
              <>
                <NavLink
                  href="/manage-order"
                  label={
                    t('navbar.order')
                  }
                />
                <NavLink
                  href="/config"
                  label={
                    t('navbar.config')
                  }
                />
                <NavLink
                  href="/authentication"
                  label={
                    t('navbar.authentication')
                  }
                />
              </>
            )}
            <NavLink
              href="/about"
              label={
                t('navbar.about')
              }
            />
          </div>
        )
      }
    </Translation>
  );
}
