import React from "react";
import { ReactNode } from "react";
import './shipping.scss'

export class ShippingActiveCourierList extends React.Component<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props) {
        super(props);
    }

    render(): ReactNode {
        return (
            <div className="d-flex">
                {this.props?.shippingData?.couriers?.map((val, index) => (val === 'grab' || val === 'gosend') ? <></> : (
                    <div className="active-img-courier" key={index}>
                        <img src={`./../../assets/img/${val}.png`} alt={val} className="img-fluid" />
                    </div>
                ))}
            </div>
        )
    }
}