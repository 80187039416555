import React from 'react';
import { MessageParserService } from '../../libraries/common/message-parser.service';
import dayjs from 'dayjs';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  ChoiceList,
  DatePicker,
  Filters,
  Frame,
  Layout,
  Link as Links,
  Modal,
  Pagination,
  ResourceItem,
  ResourceList,
  Select,
  Spinner,
  TextContainer,
  TextField,
  Toast,
  Tooltip,
} from '@shopify/polaris';
import { Link, withRouter } from 'react-router-dom';
import { RefreshMinor } from '@shopify/polaris-icons';
import { Form as ReactFinalForm } from 'react-final-form';
import { ManageOrderRestService } from './manage-order-rest.service';
import _ from 'lodash';
import './manage-order.scss';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import ExportExcelService from '../../libraries/export/export-excel.service';
import { HandleService } from '../../libraries/handle/handle.service';
import { catchError, finalize, tap } from 'rxjs/operators';

export class ManageOrderFormState {
  orderData: any = [];
  selectedRows: any = [];
  // disabledPrintShippingLabel: boolean = false;
  isLoading = false;
  isLoadingShipping = false;
  handleQueryValueRemove = null;
  appliedFilters: any[] = [];
  showCourier = false;
  showSort = false;
  counterShipLabel: any;
  selectedCounterShipLabel = '';
  selectedSearch = null;
  selectedConfirm: string[] = [];
  selectedPayment: string[] = [];
  selectedFulfillment: string[] = [];
  selectedPickupDate: any = new Date();
  selectedShipment: string[] = [];
  selectedCash: string[] = [];
  selectedStatusOrder: string[] = [];
  selectedLocationCheck: string[] = [];
  selectedCourier: string[] = [];
  selectedOptionDate: string[] = [];
  selectedSort: string[] = ['ASC'];
  startDate: any = dayjs(new Date()).format('YYYY-MM-DD');
  monthStart: any = new Date().getMonth();
  yearStart: any = new Date().getFullYear();
  selectedStartDate: any = {
    start: new Date(),
    end: new Date(),
  };
  endDate: any;
  monthEnd: any = new Date().getMonth();
  yearEnd: any = new Date().getFullYear();
  selectedEndDate: any = {
    start: new Date(),
    end: new Date(),
  };
  trackAwbModal = false;
  changeStatusModal = false;
  changeStatusModalTitle = '';
  changeStatusModalType = '';
  updateOrderInsuranceModal = false;
  updateOrderInsuranceModalTitle = '';
  updateOrderInsuranceToState = true;
  printSLabelModal = false;
  successToast = false;
  failToast = false;
  successMessage: any = 'Success change status';
  failMessage: any = 'Failed change status';
  trackingInfo: any;
  thermalMode = false;
  params = {
    deliveryType: null,
    startDate: null,
    endDate: null,
    exportCount: '',
    exportType: '',
    search: '',
    payment: '',
    fulfillment: '',
    addressStatus: '',
    shipment: '',
    courier: '',
    isCOD: null,
    isInsuranced: null,
    statusOrder: '',
    orderNumbers: '',
    airwayBills: '',
  };
  orderNumbers = '';
  airwayBills = '';
  isCOD: string[] = [];
  isInsuranced: string[] = [];
  pageNumber = 1;
  skipNumber = 0;
  page: any = {
    take: 500,
    skip: 0,
  };
  dataPDF: any;
  month: any = new Date().getMonth();
  year: any = new Date().getFullYear();
  selectedDate: any = new Date();
  selectedReadyToShip: any = ['Pickup'];
  timePickUp: any;
  shippingLabelURL: any;
}

export class ManageOrderFormComponent extends React.Component<
  any,
  ManageOrderFormState
> {
  delay = null;
  handleService: HandleService;

  constructor(props) {
    super(props);
    this.state = new ManageOrderFormState();
    this.handleService = new HandleService();
    this._renderItem = this._renderItem.bind(this);
    this._renderFilter = this._renderFilter.bind(this);
    this.toggleShowSort = this.toggleShowSort.bind(this);
    this.filters = this.filters.bind(this);
    this.saveFilterToEndpoint = this.saveFilterToEndpoint.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.handlePaymentRemove = this.handlePaymentRemove.bind(this);
    this.handleFulfillmentChange = this.handleFulfillmentChange.bind(this);
    this.handleFulfillmentRemove = this.handleFulfillmentRemove.bind(this);
    this.handleShipmentChange = this.handleShipmentChange.bind(this);
    this.handleShipmentRemove = this.handleShipmentRemove.bind(this);
    this.handleCashChange = this.handleCashChange.bind(this);
    this.handleCashRemove = this.handleCashRemove.bind(this);
    this.handleStatusOrderChange = this.handleStatusOrderChange.bind(this);
    this.handleStatusOrderRemove = this.handleStatusOrderRemove.bind(this);
    this.handleConfirmRemove = this.handleConfirmRemove.bind(this);
    this.handleCourierChange = this.handleCourierChange.bind(this);
    this.handleCourierRemove = this.handleCourierRemove.bind(this);
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
    this.handlePaymentMethodRemove = this.handlePaymentMethodRemove.bind(this);
    this.handleInsuranceChange = this.handleInsuranceChange.bind(this);
    this.handleInsuranceRemove = this.handleInsuranceRemove.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchRemove = this.handleSearchRemove.bind(this);
    this.handleClearDate = this.handleClearDate.bind(this);
    this.handleClearAll = this.handleClearAll.bind(this);
    this.handleChangeStatusOrder = this.handleChangeStatusOrder.bind(this);
    this.handleUpdateOrderInsurance = this.handleUpdateOrderInsurance.bind(
      this
    );
    this.cancelPickupOrder = this.cancelPickupOrder.bind(this);
    this.trackingAwb = this.trackingAwb.bind(this);
    this._renderAwb = this._renderAwb.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.handlePrintShippingLabel = this.handlePrintShippingLabel.bind(this);
    this.handleLocationCrossCheckRemove = this.handleLocationCrossCheckRemove.bind(
      this
    );
    this.handleThermalModeChange = this.handleThermalModeChange.bind(this);
    this.handleCounterShipLabelRemove = this.handleCounterShipLabelRemove.bind(
      this
    );
    this.handleMultiOrderNumbersChange = this.handleMultiOrderNumbersChange.bind(
      this
    );
    this.handleMultiOrderNumbersClick = this.handleMultiOrderNumbersClick.bind(
      this
    );
    this.handleMultiOrderNumbersRemove = this.handleMultiOrderNumbersRemove.bind(
      this
    );
    this.handleMultiAirwayBillsChange = this.handleMultiAirwayBillsChange.bind(
      this
    );
    this.handleMultiAirwayBillsClick = this.handleMultiAirwayBillsClick.bind(
      this
    );
    this.handleMultiAirwayBillsRemove = this.handleMultiAirwayBillsRemove.bind(
      this
    );
  }

  componentDidMount() {
    ManageOrderRestService.getFilter()
      .then((response) => {
        if (response?.success) {
          try {
            const existingFiltersObject = JSON.parse(response?.data);
            const appliedFilters =
              existingFiltersObject.appliedFilters &&
              existingFiltersObject.appliedFilters.length &&
              existingFiltersObject.appliedFilters.map((item) => {
                switch (item.key) {
                  case 'confirm':
                    item.onRemove = this.handleConfirmRemove;
                    break;
                  case 'selectedPayment':
                    item.onRemove = this.handlePaymentRemove;
                    break;
                  case 'selectedShipment':
                    item.onRemove = this.handleShipmentRemove;
                    break;
                  case 'selectedFulfillment':
                    item.onRemove = this.handleFulfillmentRemove;
                    break;
                  case 'selectedCourier':
                    item.onRemove = this.handleCourierRemove;
                    break;
                  case 'isCOD':
                    item.onRemove = this.handlePaymentMethodRemove;
                    break;
                  case 'isInsuranced':
                    item.onRemove = this.handleInsuranceRemove;
                    break;
                  case 'selectedStatusOrder':
                    item.onRemove = this.handleStatusOrderRemove;
                    break;
                  case 'addressStatus':
                    item.onRemove = this.handleLocationCrossCheckRemove;
                    break;
                  case 'selectedDate':
                    item.onRemove = this.handleClearDate;
                    break;
                  case 'counterShippingLabel':
                    item.onRemove = this.handleCounterShipLabelRemove;
                    break;
                  case 'orderNumbers':
                    item.onRemove = this.handleMultiOrderNumbersRemove;
                    break;
                  case 'airwayBills':
                    item.onRemove = this.handleMultiAirwayBillsRemove;
                }

                return item;
              });

            this.setState(
              {
                params: existingFiltersObject.params,
                counterShipLabel: existingFiltersObject.counterShipLabel,
                selectedCounterShipLabel:
                  existingFiltersObject.selectedCounterShipLabel,
                appliedFilters,
                selectedDate: existingFiltersObject.selectedDate,
                selectedConfirm: existingFiltersObject.selectedConfirm,
                selectedSearch: existingFiltersObject.selectedSearch,
                selectedPayment: existingFiltersObject.selectedPayment,
                selectedFulfillment: existingFiltersObject.selectedFulfillment,
                selectedShipment: existingFiltersObject.selectedShipment,
                selectedStatusOrder: existingFiltersObject.selectedStatusOrder,
                selectedCourier: existingFiltersObject.selectedCourier,
                isCOD: existingFiltersObject.isCOD,
                isInsuranced: existingFiltersObject.isInsuranced,
                selectedOptionDate: existingFiltersObject.selectedOptionDate,
                selectedLocationCheck: existingFiltersObject.selectedLocationCheck,
                orderNumbers: existingFiltersObject.orderNumbers,
                airwayBills: existingFiltersObject.airwayBills,
              },
              () => {
                this.loadData();
              }
            );
          } catch (e) {
            this.loadData();
          }
        } else {
          this.loadData();
        }
      })
      .catch((err) => {
        this.loadData();
      });
  }

  componentDidUpdate() {
    setTimeout(() => {
      this._renderHeading();
      this.scrollTable();
    }, 0);
  }

  loadData() {
    const params = Object.assign({}, this.state.params, this.state.page);
    this.setState({
      isLoading: true,
      orderData: [],
    });

    ManageOrderRestService.getManageOrder(params)
      .pipe(
        tap((response) => {
          this.setState({
            orderData: response.data.list,
          });
        }),
        catchError((err) => {
          if (err?.response?.status === 401) this.props.history.push('/about');

          this.setState({
            failMessage: MessageParserService.parseSpesific(err),
            failToast: true,
          });
          throw err;
        }),
        finalize(() => {
          this.setState({
            isLoading: false,
          });
        })
      ).subscribe()
  }

  _renderExportAddressStatus(status) {
    switch (status) {
      case 'crossCheck':
        return 'Cross Check';

      case 'revised':
        return 'Revised';

      case 'match':
        return 'Match';
    }
  }

  _renderExportPayment(t, payment) {
    switch (payment) {
      case 1:
        return t('order.resourceItem.badge.payment.paid');
      case 2:
        return t('order.resourceItem.badge.payment.unpaid');
      case 3:
        return t('order.resourceItem.badge.payment.refunded');
      case 99:
        return t('order.resourceItem.badge.fulfillment.error');
    }
  }

  _renderExportFulfillment(t, fulfillment) {
    switch (fulfillment) {
      case 1:
        return t('order.resourceItem.badge.fulfillment.unfulfilled');
      case 2:
        return t('order.resourceItem.badge.fulfillment.fulfilled');
      case 3:
        return t('order.resourceItem.badge.fulfillment.cancelled');
      case 99:
        return t('order.resourceItem.badge.fulfillment.error');
    }
  }

  _renderExportShipment(t, shipment) {
    switch (shipment) {
      case 1:
        return t('order.resourceItem.badge.shipment.unprocessed');
      case 2:
        return t('order.resourceItem.badge.shipment.processing');
      case 3:
        return t('order.resourceItem.badge.shipment.ready');
      case 4:
        return t('order.resourceItem.badge.shipment.shipped');
      case 5:
        return t('order.resourceItem.badge.shipment.delivered');
      case 6:
        return t('order.resourceItem.badge.shipment.cancel');
      case 7:
        return t('order.resourceItem.badge.shipment.returned');
      case 8:
        return t('order.resourceItem.badge.shipment.problem');
      case 9:
        return t('order.resourceItem.badge.shipment.returning');
      case 99:
        return t('order.resourceItem.badge.fulfillment.error');
    }
  }

  async handleExport(t) {
    this.setState({ isLoading: true });

    const page = {
      take: 1000,
      skip: 0,
    };
    const params = Object.assign(this.state.params, page);

    this.handleService.handleLoadAllReq({
      obs: (params) => ManageOrderRestService.getManageOrder(params),
      qParams: params,
      clearOnDone: true,
      onDone: (res) => {
        const normalizeValue = res.records.map((item, i) => {
          return {
            'Created At': dayjs(item?.createdAt).format('DD/MM/YYYY'),
            'Order Number': item?.orderNumber,
            'Fullname': item?.customer?.fullName,
            'Item': this._renderItems(t, item?.item),
            'Delivery Method': `${item?.trackingInfo?.courier} - ${item?.trackingInfo?.service}`,
            'Payment Method': item?.isCOD ? 'COD' : 'Non COD',
            'Airwaybill': item?.trackingInfo?.airwaybill,
            'Location Check': this._renderExportAddressStatus(
              item?.addressStatus
            ),
            'Payment': this._renderExportPayment(t, item?.payment),
            'Fulfillment': this._renderExportFulfillment(t, item?.fulfillment),
            'Shipment': this._renderExportShipment(t, item?.shipment),
            'Delivery Type': item?.deliveryType
              ? t(`order.resourceItem.badge.deliveryType.${item?.deliveryType}`)
              : '',
            'Sender': item?.shopInfo?.name || '-',
            'Sender Phone': item?.shopInfo?.phone || '-',
            'Origin Address': item?.origin?.address1 || '-',
            'Origin District': item?.shipdeo_location?.origin_subdistrict_name || '',
            'Origin City': item?.shipdeo_location?.origin_city_name || '',
            'Origin Province': item?.shipdeo_location?.origin_province_name || '',
            'Origin Postal Code': item?.shipdeo_location?.origin_postal_code || '',
            'Origin Code': item?.shipdeo_location?.origin_subdistrict_code || '',
            'Receiver': item?.customer?.fullName || '-',
            'Receiver Phone': item?.customer?.phone || '-',
            'Destination Address': item?.customer?.address?.address1 || '-',
            'Destination District': item?.shipdeo_location?.destination_subdistrict_name || '',
            'Destination City': item?.shipdeo_location?.destination_city_name || '',
            'Destination Province': item?.shipdeo_location?.destination_province_name || '',
            'Destination Postal Code': item?.shipdeo_location?.destination_postal_code || '',
            'Destination Code': item?.shipdeo_location?.destination_subdistrict_code || '',
            'Shipping Charge': item?.transaction?.shippingCharge || '-',
            'COD Value': item?.transaction?.totalCOD || '-',
            'Insurance': item?.transaction?.isInsuranced === true ? 'Yes' : 'No',
            'Fee Insurance': item?.transaction?.feeInsurance || '-',
            'UniqueCode': item.uniqueCode,
            'Transaction Total': item.transaction.totalValue,
            'Number of Prints': item?.shippingLabelCounter || 0,
            'Notification': item?.notification,
          };
        });

        ExportExcelService.export(normalizeValue, 'manage-order');
        this.setState({ isLoading: false });
      },
    });
  }

  async handlePrintShippingLabel() {
    this.setState({
      printSLabelModal: true,
    });

    this.shippingLabel(this.state.selectedRows.map((s) => s.id));
  }

  handleThermalModeChange(value) {
    this.setState({ thermalMode: value });
    this.shippingLabel(this.state.selectedRows.map((s) => s.id));
  }

  async shippingLabel(param) {
    this.setState({
      isLoadingShipping: true,
    });

    const payload = {
      ordersId: param
    }

    await ManageOrderRestService.loadShippingLabel(payload)
      .then((response) => {
        this.setState({
          shippingLabelURL: response.data.url,
        });
      })
      .catch((err) => {
        this.setState({
          failMessage: MessageParserService.parseSpesific(err),
          failToast: true,
        });
      })
      .finally(async () => {
        this.setState({
          isLoadingShipping: false,
        });
      });
  }

  scrollTable() {
    const header = document.getElementById('table-heading');

    const body = document.getElementsByClassName(
      'Polaris-ResourceList'
    )[0] as any;

    header.onscroll = this.onScroll(header, body);
    body.onscroll = this.onScroll(header, body);
  }

  onScroll(header, body) {
    return function () {
      header.scrollLeft = body.scrollLeft;
    };
  }

  _renderHeading = () => {
    const headerComponent = document.getElementsByClassName(
      'Polaris-ResourceList__HeaderOuterWrapper'
    );

    const tableHeading = document.createElement('div');
    tableHeading.innerHTML += `
      <div
        id="table-heading"
        class="hide-scrollbar"
      >
        <div
          style="
            padding-left: 80px;
            padding-right: 50px;
            min-width: 70px;
            max-width: 70px;
            font-weight: bold;
          "
        >
          ${i18next.t('order.header.order')}
        </div>

        <div
          style="
            padding-left: 20px;
            padding-right: 20px;
            min-width: 200px;
            max-width: 200px;
          "
        >
          ${i18next.t('order.header.date')}
        </div>

        <div
          style="
            padding-left: 20px;
            padding-right: 20px;
            min-width: 200px;
            max-width: 200px;
          "
        >
          ${i18next.t('order.header.customer')}
        </div>

        <div
          style="
            padding-left: 20px;
            padding-right: 20px;
            min-width: 150px;
            max-width: 150px;
          "
        >
          ${i18next.t('order.header.delivery')}
        </div>

        <div
          style="
            padding-left: 20px;
            padding-right: 20px;
            max-width: 150px;
            min-width: 154px;
          "
        >
          ${i18next.t('order.header.paymentMethod')}
        </div>

        <div
          style="
            padding-left: 20px;
            padding-right: 20px;
            max-width: 150px;
            min-width: 154px;
          "
        >
          ${i18next.t('order.header.awb')}
        </div>

        <div
          style="
            padding-left: 20px;
            padding-right: 20px;
            min-width: 150px;
            max-width: 150px;
          "
        >
          ${i18next.t('order.header.locationCheck')}
        </div>

        <div
          style="
            padding-left: 20px;
            padding-right: 20px;
            min-width: 104px;
            max-width: 104px;
          "
        >
          ${i18next.t('order.header.payment')}
        </div>

        <div
          style="
            padding-left: 20px;
            padding-right: 20px;
            min-width: 115px;
            max-width: 115px;
          "
        >
          ${i18next.t('order.header.fulfillment')}
        </div>

        <div
          style="
            padding-left: 20px;
            padding-right: 20px;
            min-width: 147px;
            max-width: 147px;
          "
        >
          ${i18next.t('order.header.shipment')}
        </div>

        <div
          style="
            padding-left: 20px;
            padding-right: 20px;
            min-width: 147px;
            max-width: 147px;
          "
        >
          ${i18next.t('order.header.deliveryType')}
        </div>

        <div
          style="
            padding-left: 40px;
            padding-right: 20px;
            min-width: 147px;
            max-width: 147px;
          "
        >
          ${i18next.t('order.header.insurance')}
        </div>

        <div
          style="
            padding-left: 0;
            padding-right: 30px;
            min-width: 200px;
            max-width: 200px;
          "
        >
          ${i18next.t('order.header.numberOfPrints')}
        </div>

        <div
          style="
            padding-left: 30px;
            padding-right: 30px;
            min-width: 80px;
            max-width: 80px;
          "
        >
          ${i18next.t('order.header.items')}
        </div>

        <div
          style="
            padding-left: 25px;
            padding-right: 35px;
            min-width: 165px;
            max-width: 165px;
          "
        >
          ${i18next.t('order.header.total')}
        </div>

        <div
          style="
            padding-left: 0;
            padding-right: 30px;
            min-width: 200px;
            max-width: 200px;
          "
        >
          ${i18next.t('order.header.uniqueCode')}
        </div>

        <div
          style="
            padding-left: 0;
            padding-right: 30px;
            min-width: 230px;
            max-width: 230px;
          "
        >
          ${i18next.t('order.header.notification')}
        </div>
      </div>
      `;

    if (headerComponent.length > 0) {
      if (!document.getElementById('table-heading')) {
        headerComponent[0].appendChild(tableHeading);
      }
    }
  };

  handleSearchChange(value) {
    const params = this.state.params;
    params.search = value;

    this.setState(
      {
        params,
      },
      () => {
        this.loadData();
      }
    );
  }

  handleSearchRemove() {
    const params = this.state.params;

    params.search = '';

    this.setState(
      {
        selectedSearch: null,
        params,
      },
      () => {
        this.loadData();
      }
    );
  }

  saveFilterToEndpoint() {
    const filtersToPersist = {
      params: this.state.params,
      counterShipLabel: this.state.counterShipLabel,
      selectedCounterShipLabel: this.state.selectedCounterShipLabel,
      appliedFilters: this.state.appliedFilters,
      selectedDate: this.state.selectedDate,
      selectedConfirm: this.state.selectedConfirm,
      selectedSearch: this.state.selectedSearch,
      selectedPayment: this.state.selectedPayment,
      selectedFulfillment: this.state.selectedFulfillment,
      selectedShipment: this.state.selectedShipment,
      selectedStatusOrder: this.state.selectedStatusOrder,
      selectedCourier: this.state.selectedCourier,
      selectedOptionDate: this.state.selectedOptionDate,
      selectedLocationCheck: this.state.selectedLocationCheck,
      orderNumbers: this.state.orderNumbers,
      airwayBills: this.state.airwayBills,
    };

    ManageOrderRestService.setFilter(JSON.stringify(filtersToPersist))
      .then((response) => { })
      .catch((err) => {
        this.setState({
          failMessage: MessageParserService.parseSpesific(err),
          failToast: true,
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handlePaymentChange(t, value) {
    const params = this.state.params;

    const paymentCode = [];

    for (const payment of value) {
      switch (payment) {
        case `${t('order.filters.payment.status.paid')}`:
          paymentCode.push(1);
          break;
        case `${t('order.filters.payment.status.unpaid')}`:
          paymentCode.push(2);
          break;
        case `${t('order.filters.payment.status.refunded')}`:
          paymentCode.push(3);
          break;
        default:
          break;
      }
    }

    params.payment = paymentCode.join(',');

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedPayment',
    ]);

    this.setState(
      {
        selectedPayment: value,
        params,
      },
      () => {
        this.loadData();
      }
    );

    if (value.length) {
      const key = 'selectedPayment';
      appliedFiltersValue.push({
        key,
        label: this.disambiguatedLabel(key, value),
        onRemove: this.handlePaymentRemove,
      });
    }

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handlePaymentRemove() {
    const params = this.state.params;

    params.payment = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedPayment',
    ]);

    this.setState(
      {
        selectedPayment: null,
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleFulfillmentChange(t, value) {
    const params = this.state.params;

    const fulfillmentCode = [];

    for (const fulfillment of value) {
      switch (fulfillment) {
        case `${t('order.filters.fulfillment.status.unfulfilled')}`:
          fulfillmentCode.push(1);
          break;
        case `${t('order.filters.fulfillment.status.fulfilled')}`:
          fulfillmentCode.push(2);
          break;
        case `${t('order.filters.fulfillment.status.cancelled')}`:
          fulfillmentCode.push(3);
          break;
        case `error`:
          fulfillmentCode.push(99);
          break;
        default:
          break;
      }
    }

    params.fulfillment = fulfillmentCode.join(',');

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedFulfillment',
    ]);

    this.setState(
      {
        selectedFulfillment: value,
        params,
      },
      () => {
        this.loadData();
      }
    );

    if (value.length) {
      const key = 'selectedFulfillment';
      appliedFiltersValue.push({
        key,
        label: this.disambiguatedLabel(key, value),
        onRemove: this.handleFulfillmentRemove,
      });
    }

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleFulfillmentRemove() {
    const params = this.state.params;

    params.fulfillment = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedFulfillment',
    ]);

    this.setState(
      {
        selectedFulfillment: null,
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleShipmentChange(t, value) {
    const params = this.state.params;

    const shipmentCode = [];

    for (const shipment of value) {
      switch (shipment) {
        case `${t('order.filters.shipment.status.unprocessed')}`:
          shipmentCode.push(1);
          break;
        case `${t('order.filters.shipment.status.processing')}`:
          shipmentCode.push(2);
          break;
        case `${t('order.filters.shipment.status.readyToShip')}`:
          shipmentCode.push(3);
          break;
        case `${t('order.filters.shipment.status.shipped')}`:
          shipmentCode.push(4);
          break;
        case `${t('order.filters.shipment.status.delivered')}`:
          shipmentCode.push(5);
          break;
        case `${t('order.filters.shipment.status.cancel')}`:
          shipmentCode.push(6);
          break;
        case `${t('order.filters.shipment.status.return')}`:
          shipmentCode.push(7);
          break;
        case `${t('order.filters.shipment.status.pending')}`:
          shipmentCode.push(10);
          break;
        case `error`:
          shipmentCode.push(99);
          break;
        default:
          break;
      }
    }

    params.shipment = shipmentCode.join(',');

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedShipment',
    ]);

    this.setState(
      {
        selectedShipment: value,
        params,
      },
      () => {
        this.loadData();
      }
    );

    if (value.length) {
      const key = 'selectedShipment';
      appliedFiltersValue.push({
        key,
        label: this.disambiguatedLabel(key, value),
        onRemove: this.handleShipmentRemove,
      });
    }

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleShipmentRemove() {
    const params = this.state.params;

    params.shipment = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedShipment',
    ]);

    this.setState(
      {
        selectedShipment: null,
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleCashChange(value) {
    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedCash',
    ]);

    this.setState({
      selectedCash: value,
    });

    if (value.length) {
      const key = 'selectedCash';
      appliedFiltersValue.push({
        key,
        label: this.disambiguatedLabel(key, value),
        onRemove: this.handleCashRemove,
      });
    }

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleCashRemove() {
    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedCash',
    ]);

    this.setState(
      {
        selectedCash: null,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleStatusOrderChange(value) {
    const params = this.state.params;

    const statusOrder = [];

    for (const shipment of value) {
      switch (shipment) {
        case `Success`:
          statusOrder.push(shipment);
          break;
        case `error`:
          statusOrder.push(shipment);
          break;
        default:
          break;
      }
    }

    params.statusOrder = statusOrder.join(',');

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedStatusOrder',
    ]);

    this.setState(
      {
        selectedStatusOrder: value,
        params,
      },
      () => {
        this.loadData();
      }
    );

    if (value.length) {
      const key = 'selectedStatusOrder';
      appliedFiltersValue.push({
        key,
        label: this.disambiguatedLabel(key, value),
        onRemove: this.handleStatusOrderRemove,
      });
    }

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleStatusOrderRemove() {
    const params = this.state.params;

    params.statusOrder = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedStatusOrder',
    ]);

    this.setState(
      {
        selectedStatusOrder: null,
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleLocationCheckChange(value) {
    const params = this.state.params;
    const addressStatuses = [];

    for (const address of value) {
      switch (address) {
        case 'Match':
          addressStatuses.push('MATCH');
          break;
        case `Revised`:
          addressStatuses.push('REVISED');
          break;
        case 'Cross Check':
          addressStatuses.push('CROSS_CHECK');
      }
    }

    params.addressStatus = addressStatuses.join(',');

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'addressStatus',
    ]);

    this.setState(
      {
        selectedLocationCheck: value,
        params,
      },
      () => {
        this.loadData();
      }
    );

    if (value.length) {
      const key = 'addressStatus';
      appliedFiltersValue.push({
        key,
        label: this.disambiguatedLabel(key, value),
        onRemove: this.handleLocationCrossCheckRemove,
      });
    }

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleLocationCrossCheckRemove() {
    const params = this.state.params;

    params.addressStatus = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'addressStatus',
    ]);

    this.setState(
      {
        selectedLocationCheck: [],
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleConfirmChange(value) {
    const params = this.state.params;
    params.deliveryType = value[0].toLowerCase().replace(/\s/g, '');

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, ['key', 'confirm']);

    this.setState(
      {
        selectedConfirm: value,
        params,
      },
      () => {
        this.loadData();
      }
    );

    if (value.length) {
      const key = 'confirm';
      appliedFiltersValue.push({
        key,
        label: this.disambiguatedLabel(key, value),
        onRemove: this.handleConfirmRemove,
      });
    }

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleConfirmRemove() {
    const params = this.state.params;
    params.deliveryType = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, ['key', 'confirm']);

    this.setState(
      {
        selectedConfirm: null,
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleCourierChange(value) {
    const params = this.state.params;

    const courierCode = [];

    for (const courier of value) {
      switch (courier) {
        case 'SiCepat':
          courierCode.push('sicepat');
          break;
        case 'AnterAja':
          courierCode.push('anteraja');
          break;
        case 'JET Express':
          courierCode.push('jet');
          break;
        case 'Ninja Xpress':
          courierCode.push('ninja');
          break;
        case 'JDL Express Indonesia':
          courierCode.push('jx');
          break;
        case 'J&T Express':
          courierCode.push('jnt');
          break;
        case 'JNE':
          courierCode.push('jne');
          break;
        case 'SAP Express':
          courierCode.push('sap');
          break;
        default:
          break;
      }
    }

    params.courier = courierCode.join(',');

    this.setState(
      {
        selectedCourier: null,
        params,
      },
      () => {
        this.loadData();
      }
    );

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedCourier',
    ]);

    this.setState({
      selectedCourier: value,
    });

    if (value.length) {
      const key = 'selectedCourier';
      appliedFiltersValue.push({
        key,
        label: this.disambiguatedLabel(key, value),
        onRemove: this.handleCourierRemove,
      });
    }

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleCourierRemove() {
    const params = this.state.params;

    params.courier = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedCourier',
    ]);

    this.setState(
      {
        selectedCourier: null,
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handlePaymentMethodChange(value) {
    const params = this.state.params;
    params.isCOD = value.includes('Non COD') ? false : true;

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, ['key', 'isCOD']);

    this.setState(
      {
        isCOD: value,
        params,
      },
      () => {
        this.loadData();
      }
    );

    if (value.length) {
      const key = 'isCOD';
      appliedFiltersValue.push({
        key,
        label: this.disambiguatedLabel(key, value),
        onRemove: this.handlePaymentMethodRemove,
      });
    }

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handlePaymentMethodRemove() {
    const params = this.state.params;
    params.isCOD = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, ['key', 'isCOD']);

    this.setState(
      {
        isCOD: null,
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleInsuranceChange(value) {
    const params = this.state.params;
    params.isInsuranced = value.includes('With Insurance') ? true : false;

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'isInsuranced',
    ]);

    this.setState(
      {
        isInsuranced: value,
        params,
      },
      () => {
        this.loadData();
      }
    );

    if (value.length) {
      const key = 'isInsuranced';
      appliedFiltersValue.push({
        key,
        label: this.disambiguatedLabel(key, value),
        onRemove: this.handleInsuranceRemove,
      });
    }

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleInsuranceRemove() {
    const params = this.state.params;
    params.isInsuranced = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'isInsuranced',
    ]);

    this.setState(
      {
        isInsuranced: null,
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleDateChange(value) {
    const params = this.state.params;

    switch (value[0]) {
      case 'Today':
        params.startDate = dayjs().format('YYYY-MM-DD');
        params.endDate = dayjs().format('YYYY-MM-DD');
        break;
      case 'Last 7 days':
        params.startDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
        params.endDate = dayjs().format('YYYY-MM-DD');
        break;
      case 'Last 30 days':
        params.startDate = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
        params.endDate = dayjs().format('YYYY-MM-DD');
        break;
      case 'Last 90 days':
        params.startDate = dayjs().subtract(90, 'day').format('YYYY-MM-DD');
        params.endDate = dayjs().format('YYYY-MM-DD');
        break;
      case 'Last 12 months':
        params.startDate = dayjs().subtract(12, 'month').format('YYYY-MM-DD');
        params.endDate = dayjs().format('YYYY-MM-DD');
        break;
      default:
        this.setState({
          selectedOptionDate: value,
        });
        return;
    }

    this.setState(
      {
        selectedOptionDate: [],
      },
      () => this.loadData()
    );

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedDate',
    ]);

    this.setState({
      selectedOptionDate: value,
    });

    if (value.length) {
      const key = 'selectedDate';
      appliedFiltersValue.push({
        key,
        label: this.disambiguatedLabel(key, value),
        onRemove: this.handleClearDate,
      });
    }

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  async handleChangeStartDate(value) {
    const params = this.state.params;
    params.startDate = dayjs(value.start).format('YYYY-MM-DD');

    await this.setState(
      {
        params,
        selectedStartDate: value,
        startDate: dayjs(value.start).format('YYYY-MM-DD'),
      },
      () => {
        this.loadData();
      }
    );

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedDate',
    ]);

    this.setState({
      selectedOptionDate: ['Custom'],
    });

    const key = 'selectedDate';
    await appliedFiltersValue.push({
      key,
      label: this.disambiguatedLabel(key, ['Custom']),
      onRemove: this.handleClearDate,
    });

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  async handleChangeEndDate(value) {
    const params = this.state.params;
    params.endDate = dayjs(value.end).format('YYYY-MM-DD');

    await this.setState(
      {
        params,
        selectedEndDate: value,
        endDate: dayjs(value.end).format('YYYY-MM-DD'),
      },
      () => this.loadData()
    );

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedDate',
    ]);

    this.setState({
      selectedOptionDate: ['Custom'],
    });

    const key = 'selectedDate';
    await appliedFiltersValue.push({
      key,
      label: this.disambiguatedLabel(key, ['Custom']),
      onRemove: this.handleClearDate,
    });

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleClearDate() {
    const params = this.state.params;

    params.startDate = null;
    params.endDate = null;

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'selectedDate',
    ]);

    this.setState(
      {
        selectedOptionDate: [],
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleSelectedCounterShipLabelChange(value) {
    const params = this.state.params;
    params.exportType = value;

    this.setState(
      {
        params,
        selectedCounterShipLabel: value,
      },
      () => {
        if (this.state.counterShipLabel) {
          this.handleCounterShipLabelChange(this.state.counterShipLabel);
        }
      }
    );
  }

  handleCounterShipLabelChange(value) {
    this.setState({ counterShipLabel: value });
    clearTimeout(this.delay);
    this.delay = setTimeout(() => {
      if (this.state.selectedCounterShipLabel) {
        const params = this.state.params;
        params.exportCount = value;

        let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

        appliedFiltersValue = _.reject(appliedFiltersValue, [
          'key',
          'counterShippingLabel',
        ]);

        this.setState({ params }, () => {
          this.loadData();
        });

        if (value.length) {
          const key = 'counterShippingLabel';
          appliedFiltersValue.push({
            key,
            label: this.disambiguatedLabel(key, value),
            onRemove: this.handleCounterShipLabelRemove,
          });
        }

        this.setState(
          {
            appliedFilters: appliedFiltersValue,
          },
          () => this.saveFilterToEndpoint()
        );
      }
    }, 1000);
  }

  handleCounterShipLabelRemove() {
    const params = this.state.params;
    params.exportCount = '';
    params.exportType = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'counterShippingLabel',
    ]);

    this.setState(
      {
        counterShipLabel: '',
        selectedCounterShipLabel: '',
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleMultiOrderNumbersChange(value) {
    const params = this.state.params;
    params.orderNumbers = value.replace(/\n|\r\n|\r/g, ',').replace(/\s/g, '');

    this.setState({
      orderNumbers: value,
      params,
    });
  }

  handleMultiOrderNumbersClick() {
    if (this.state.orderNumbers) {
      let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

      appliedFiltersValue = _.reject(appliedFiltersValue, [
        'key',
        'orderNumbers',
      ]);

      if (this.state.orderNumbers.length) {
        const key = 'orderNumbers';
        appliedFiltersValue.push({
          key,
          label: this.disambiguatedLabel(key, this.state.orderNumbers),
          onRemove: this.handleMultiOrderNumbersRemove,
        });
      }

      this.setState(
        {
          appliedFilters: appliedFiltersValue,
        },
        () => {
          this.loadData();
          this.saveFilterToEndpoint();
        }
      );
    }
  }

  handleMultiOrderNumbersRemove() {
    const params = this.state.params;
    params.orderNumbers = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, [
      'key',
      'orderNumbers',
    ]);

    this.setState(
      {
        orderNumbers: '',
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleMultiAirwayBillsChange(value) {
    const params = this.state.params;
    params.airwayBills = value.replace(/\n|\r\n|\r/g, ',').replace(/\s/g, '');

    this.setState({
      airwayBills: value,
      params,
    });
  }

  handleMultiAirwayBillsClick() {
    if (this.state.airwayBills) {
      let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

      appliedFiltersValue = _.reject(appliedFiltersValue, [
        'key',
        'airwayBills',
      ]);

      if (this.state.airwayBills.length) {
        const key = 'airwayBills';
        appliedFiltersValue.push({
          key,
          label: this.disambiguatedLabel(key, this.state.airwayBills),
          onRemove: this.handleMultiAirwayBillsRemove,
        });
      }

      this.setState(
        {
          appliedFilters: appliedFiltersValue,
        },
        () => {
          this.loadData();
          this.saveFilterToEndpoint();
        }
      );
    }
  }

  handleMultiAirwayBillsRemove() {
    const params = this.state.params;
    params.airwayBills = '';

    let appliedFiltersValue = _.cloneDeep(this.state.appliedFilters);

    appliedFiltersValue = _.reject(appliedFiltersValue, ['key', 'airwayBills']);

    this.setState(
      {
        airwayBills: '',
        params,
      },
      () => {
        this.loadData();
      }
    );

    this.setState(
      {
        appliedFilters: appliedFiltersValue,
      },
      () => this.saveFilterToEndpoint()
    );
  }

  handleClearAll() {
    let params = this.state.params;

    params = {
      deliveryType: null,
      addressStatus: '',
      exportCount: '',
      exportType: '',
      startDate: '',
      endDate: '',
      search: '',
      payment: '',
      fulfillment: '',
      shipment: '',
      courier: '',
      isCOD: null,
      isInsuranced: null,
      statusOrder: '',
      orderNumbers: '',
      airwayBills: '',
    };

    this.setState(
      {
        appliedFilters: null,
        selectedConfirm: [],
        counterShipLabel: '',
        selectedCounterShipLabel: '',
        selectedOptionDate: [],
        selectedPayment: [],
        selectedFulfillment: [],
        selectedShipment: [],
        selectedStatusOrder: [],
        selectedCourier: [],
        isCOD: null,
        isInsuranced: null,
        selectedLocationCheck: [],
        orderNumbers: '',
        airwayBills: '',
        params,
      },
      () => {
        this.loadData();
        this.saveFilterToEndpoint();
      }
    );
  }

  handleDisabledDate() {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    date.toLocaleDateString();
    return date;
  }

  toggleShowSort() {
    this.setState((prev) => ({
      showSort: !prev.showSort,
    }));
  }

  disambiguatedLabel(key, value, start?, end?) {
    switch (key) {
      case 'selectedPayment':
        return value.map((val) => `${val}`).join(', ');
      case 'selectedShipment':
        return value.map((val) => `${val}`).join(', ');
      case 'selectedFulfillment':
        return value.map((val) => `${val}`).join(', ');
      case 'selectedCash':
        return value.map((val) => `${val}`).join(', ');
      case 'selectedCourier':
        return value.map((val) => `${val}`).join(', ');
      case 'selectedStatusOrder':
        return value.map((val) => `${val}`).join(', ');
      case 'addressStatus':
        return value.map((val) => `${val}`).join(', ');
      case 'selectedDate':
        return value.map((val) => {
          switch (val) {
            case 'Today':
              return `${dayjs().format('DD/MM/YYYY')} - ${dayjs().format(
                'DD/MM/YYYY'
              )}`;
            case 'Last 7 days':
              return `${dayjs()
                .subtract(7, 'day')
                .format('DD/MM/YYYY')} - ${dayjs().format('DD/MM/YYYY')}`;
            case 'Last 30 days':
              return `${dayjs()
                .subtract(30, 'day')
                .format('DD/MM/YYYY')} - ${dayjs().format('DD/MM/YYYY')}`;
            case 'Last 90 days':
              return `${dayjs()
                .subtract(90, 'day')
                .format('DD/MM/YYYY')} - ${dayjs().format('DD/MM/YYYY')}`;
            case 'Last 12 months':
              return `${dayjs()
                .subtract(12, 'month')
                .format('DD/MM/YYYY')} - ${dayjs().format('DD/MM/YYYY')}`;
            case 'Custom':
              return `${!_.isUndefined(this.state.startDate)
                ? dayjs(this.state.startDate).format('DD/MM/YYYY')
                : ''
                }${!_.isUndefined(this.state.endDate)
                  ? ` - ${dayjs(this.state.endDate).format('DD/MM/YYYY')}`
                  : ''
                }`;
            default:
              return val;
          }
        });
      case 'counterShippingLabel':
        switch (this.state.selectedCounterShipLabel) {
          case '$eq':
            return value;
          case '$gt':
            return `> ${value}`;
          case '$gte':
            return `≥ ${value}`;
        }
        break;
      default:
        return value;
    }
  }

  isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value === null;
    }
  }

  _renderAddressStatus(status) {
    switch (status) {
      case 'crossCheck':
        return <Badge status="critical">Cross Check</Badge>;

      case 'revised':
        return <Badge status="warning">Revised</Badge>;

      case 'match':
        return <Badge status="success">Match</Badge>;
    }
  }

  _renderPayment(t, payment) {
    switch (payment) {
      case 1:
        return (
          <Badge progress="complete" status="success">
            {t('order.resourceItem.badge.payment.paid')}
          </Badge>
        );
      case 2:
        return (
          <Badge progress="incomplete">
            {t('order.resourceItem.badge.payment.unpaid')}
          </Badge>
        );
      case 3:
        return (
          <Badge progress="incomplete" status="critical">
            {t('order.resourceItem.badge.payment.refunded')}
          </Badge>
        );
      case 99:
        return (
          <Badge status="critical">
            {t('order.resourceItem.badge.fulfillment.error')}
          </Badge>
        );
    }
  }

  _renderFulfillment(t, fulfillment) {
    switch (fulfillment) {
      case 1:
        return (
          <Badge progress="incomplete">
            {t('order.resourceItem.badge.fulfillment.unfulfilled')}
          </Badge>
        );
      case 2:
        return (
          <Badge progress="complete" status="success">
            {t('order.resourceItem.badge.fulfillment.fulfilled')}
          </Badge>
        );
      case 3:
        return (
          <Badge progress="incomplete" status="critical">
            {t('order.resourceItem.badge.fulfillment.cancelled')}
          </Badge>
        );
      case 99:
        return (
          <Badge status="critical">
            {t('order.resourceItem.badge.fulfillment.error')}
          </Badge>
        );
    }
  }

  _renderShipment(t, shipment) {
    switch (shipment) {
      case 1:
        return (
          <Badge progress="incomplete">
            {t('order.resourceItem.badge.shipment.unprocessed')}
          </Badge>
        );
      case 2:
        return (
          <Badge progress="partiallyComplete">
            {t('order.resourceItem.badge.shipment.processing')}
          </Badge>
        );
      case 3:
        return (
          <Badge progress="incomplete" status="info">
            {t('order.resourceItem.badge.shipment.ready')}
          </Badge>
        );
      case 4:
        return (
          <Badge progress="partiallyComplete" status="info">
            {t('order.resourceItem.badge.shipment.shipped')}
          </Badge>
        );
      case 5:
        return (
          <Badge progress="complete" status="success">
            {t('order.resourceItem.badge.shipment.delivered')}
          </Badge>
        );
      case 6:
        return (
          <Badge progress="incomplete" status="critical">
            {t('order.resourceItem.badge.shipment.cancel')}
          </Badge>
        );
      case 7:
        return (
          <Badge progress="complete" status="critical">
            {t('order.resourceItem.badge.shipment.returned')}
          </Badge>
        );
      case 8:
        return (
          <Badge progress="incomplete" status="critical">
            {t('order.resourceItem.badge.shipment.problem')}
          </Badge>
        );
      case 9:
        return (
          <Badge progress="partiallyComplete" status="attention">
            {t('order.resourceItem.badge.shipment.returning')}
          </Badge>
        );
      case 10:
        return (
          <Badge status="info">
            {t('order.resourceItem.badge.shipment.pending')}
          </Badge>
        );
      case 99:
        return (
          <Badge status="critical">
            {t('order.resourceItem.badge.fulfillment.error')}
          </Badge>
        );
    }
  }

  _renderDeliveryType(t, deliveryType) {
    switch (deliveryType) {
      case 'dropoff':
        return (
          <Badge status="warning">
            {t(`order.resourceItem.badge.deliveryType.${deliveryType}`)}
          </Badge>
        );
      case 'pickup':
        return (
          <Badge status="attention">
            {t(`order.resourceItem.badge.deliveryType.${deliveryType}`)}
          </Badge>
        );
    }
  }

  _renderInsuranced(t, insurance) {
    if (insurance) {
      return <Badge status="success">{t('order.header.insurance')}</Badge>;
    }
  }

  renderCash(cash) {
    switch (cash) {
      case 1:
        return <Badge status="info">COD</Badge>;
      case 2:
        return <Badge status="info">Non COD</Badge>;
    }
  }

  _renderItems(t, item) {
    if (item === 1) {
      return `${item} ${t('order.resourceItem.fill.items.singular')}`;
    } else {
      return `${item} ${t('order.resourceItem.fill.items.plural')}`;
    }
  }

  filters(t) {
    return [
      {
        key: 'selectedPayment',
        label: t('order.filters.payment.title'),
        filter: (
          <ChoiceList
            allowMultiple
            title={`${t('order.filters.title.status')}`}
            choices={[
              {
                label: `${t('order.filters.payment.status.paid')}`,
                value: `${t('order.filters.payment.status.paid')}`,
              },
              {
                label: `${t('order.filters.payment.status.unpaid')}`,
                value: `${t('order.filters.payment.status.unpaid')}`,
              },
              {
                label: `${t('order.filters.payment.status.refunded')}`,
                value: `${t('order.filters.payment.status.refunded')}`,
              },
            ]}
            selected={this.state.selectedPayment || []}
            onChange={(value, name) => {
              this.handlePaymentChange(t, value);
            }}
          />
        ),
        shortcut: true,
      },
      {
        key: 'selectedFulfillment',
        label: t('order.filters.fulfillment.title'),
        filter: (
          <ChoiceList
            allowMultiple
            title={`${t('order.filters.title.status')}`}
            choices={[
              {
                label: `${t('order.filters.fulfillment.status.fulfilled')}`,
                value: `${t('order.filters.fulfillment.status.fulfilled')}`,
              },
              {
                label: `${t('order.filters.fulfillment.status.unfulfilled')}`,
                value: `${t('order.filters.fulfillment.status.unfulfilled')}`,
              },
              {
                label: `${t('order.filters.fulfillment.status.cancelled')}`,
                value: `${t('order.filters.fulfillment.status.cancelled')}`,
              },
            ]}
            selected={this.state.selectedFulfillment || []}
            onChange={(value, name) => {
              this.handleFulfillmentChange(t, value);
            }}
          />
        ),
      },
      {
        key: 'selectedShipment',
        label: t('order.filters.shipment.title'),
        filter: (
          <ChoiceList
            allowMultiple
            title={`${t('order.filters.title.status')}`}
            choices={[
              {
                label: `${t('order.filters.shipment.status.unprocessed')}`,
                value: `${t('order.filters.shipment.status.unprocessed')}`,
              },
              {
                label: `${t('order.filters.shipment.status.processing')}`,
                value: `${t('order.filters.shipment.status.processing')}`,
              },
              {
                label: `${t('order.filters.shipment.status.readyToShip')}`,
                value: `${t('order.filters.shipment.status.readyToShip')}`,
              },
              {
                label: `${t('order.filters.shipment.status.shipped')}`,
                value: `${t('order.filters.shipment.status.shipped')}`,
              },
              {
                label: `${t('order.filters.shipment.status.delivered')}`,
                value: `${t('order.filters.shipment.status.delivered')}`,
              },
              {
                label: `${t('order.filters.shipment.status.cancel')}`,
                value: `${t('order.filters.shipment.status.cancel')}`,
              },
              {
                label: `${t('order.filters.shipment.status.return')}`,
                value: `${t('order.filters.shipment.status.return')}`,
              },
              {
                label: `${t('order.filters.shipment.status.cancelRequest')}`,
                value: `${t('order.filters.shipment.status.cancelRequest')}`,
              },
            ]}
            selected={this.state.selectedShipment || []}
            onChange={(value) => {
              this.handleShipmentChange(t, value);
            }}
          />
        ),
        shortcut: true,
      },
      {
        key: 'selectedStatusOrder',
        label: 'Order Status',
        filter: (
          <ChoiceList
            allowMultiple
            title=""
            choices={[
              {
                label: 'Success',
                value: 'Success',
              },
              {
                label: `ERROR!!`,
                value: `error`,
              },
            ]}
            selected={this.state.selectedStatusOrder || []}
            onChange={(value) => {
              this.handleStatusOrderChange(value);
            }}
          />
        ),
        shortcut: true,
      },
      {
        key: 'selectedCourier',
        label: t('order.filters.courier.title'),
        filter: (
          <ChoiceList
            allowMultiple
            title=""
            choices={[
              {
                label: 'SiCepat',
                value: 'SiCepat',
              },
              {
                label: 'AnterAja',
                value: 'AnterAja',
              },
              {
                label: 'JET Express',
                value: 'JET Express',
              },
              {
                label: 'Ninja Xpress',
                value: 'Ninja Xpress',
              },
              {
                label: 'JDL Express Indonesia',
                value: 'JDL Express Indonesia',
              },
              {
                label: 'J&T Express',
                value: 'J&T Express',
              },
              {
                label: 'JNE',
                value: 'JNE',
              },
              {
                label: 'SAP Express',
                value: 'SAP Express',
              },
            ]}
            selected={this.state.selectedCourier || []}
            onChange={(value) => {
              this.handleCourierChange(value);
            }}
          />
        ),
        shortcut: true,
      },
      {
        key: 'isCOD',
        label: 'Payment Method',
        filter: (
          <ChoiceList
            title=""
            choices={[
              {
                label: 'COD',
                value: 'COD',
              },
              {
                label: 'Non COD',
                value: 'Non COD',
              },
            ]}
            selected={this.state.isCOD || []}
            onChange={(value) => {
              this.handlePaymentMethodChange(value);
            }}
          />
        ),
      },
      {
        key: 'isInsuranced',
        label: 'Insurance',
        filter: (
          <ChoiceList
            title=""
            choices={[
              {
                label: 'Yes',
                value: 'With Insurance',
              },
              {
                label: 'No',
                value: 'Without Insurance',
              },
            ]}
            selected={this.state.isInsuranced || []}
            onChange={(value) => {
              this.handleInsuranceChange(value);
            }}
          />
        ),
      },
      {
        key: 'selectedDate',
        label: t('order.filters.date.title'),
        filter: (
          <ChoiceList
            title=""
            choices={[
              {
                label: 'Today',
                value: 'Today',
              },
              {
                label: 'Last 7 days',
                value: 'Last 7 days',
              },
              {
                label: 'Last 30 days',
                value: 'Last 30 days',
              },
              {
                label: 'Last 90 days',
                value: 'Last 90 days',
              },
              {
                label: 'Last 12 months',
                value: 'Last 12 months',
              },
              {
                label: 'Custom',
                value: 'Custom',
                renderChildren: (isSelected) => this.renderDate(isSelected),
              },
            ]}
            selected={this.state.selectedOptionDate || []}
            onChange={(value) => {
              this.handleDateChange(value);
            }}
          />
        ),
      },
      {
        key: 'addressStatus',
        label: 'Location Check',
        filter: (
          <ChoiceList
            allowMultiple
            title=""
            choices={[
              {
                label: 'Match',
                value: 'Match',
              },
              {
                label: 'Revised',
                value: 'Revised',
              },
              {
                label: 'Cross Check',
                value: 'Cross Check',
              },
            ]}
            selected={this.state.selectedLocationCheck || []}
            onChange={(value) => {
              this.handleLocationCheckChange(value);
            }}
          />
        ),
      },
      {
        key: 'confirm',
        label: 'Delivery Type',
        filter: (
          <ChoiceList
            title=""
            choices={[
              {
                label: 'Drop Off',
                value: 'Drop Off',
              },
              {
                label: 'Pickup',
                value: 'Pickup',
              },
            ]}
            selected={this.state.selectedConfirm || []}
            onChange={(value) => {
              this.handleConfirmChange(value);
            }}
          />
        ),
      },
      {
        key: 'counterShippingLabel',
        label: 'Print Shipping Label',
        filter: (
          <div className="row">
            <div className="col-md-4">
              <Select
                label=""
                placeholder="Select"
                options={[
                  {
                    label: '=',
                    value: '$eq',
                  },
                  {
                    label: '>',
                    value: '$gt',
                  },
                  {
                    label: '≥',
                    value: '$gte',
                  },
                ]}
                onChange={(value) =>
                  this.handleSelectedCounterShipLabelChange(value)
                }
                value={this.state.selectedCounterShipLabel}
              />
            </div>
            <div className="col-md-8">
              <TextField
                label=""
                autoComplete="off"
                type="number"
                value={this.state.counterShipLabel}
                onChange={(e) => this.handleCounterShipLabelChange(e)}
              />
            </div>
          </div>
        ),
      },
      {
        key: 'orderNumbers',
        label: 'Search Order Number (Multiple)',
        filter: (
          <>
            <TextField
              label="*Split Order Number with break lines (Enter)"
              value={this.state.orderNumbers}
              onChange={(e) => {
                this.handleMultiOrderNumbersChange(e);
              }}
              multiline={4}
              autoComplete="off"
            />
            <div className="mt-3">
              <ButtonGroup>
                <Button
                  plain
                  disabled={!this.state.orderNumbers}
                  onClick={() => this.handleMultiOrderNumbersRemove()}
                >
                  Clear
                </Button>
                <Button
                  plain
                  monochrome
                  disabled={this.state.orderNumbers?.length < 1}
                  onClick={() => this.handleMultiOrderNumbersClick()}
                >
                  Search
                </Button>
              </ButtonGroup>
            </div>
          </>
        ),
        hideClearButton: true,
      },
      {
        key: 'airwayBills',
        label: 'Search Airwaybill (Multiple)',
        filter: (
          <>
            <TextField
              label="*Split Airwaybill with break lines (Enter)"
              value={this.state.airwayBills}
              onChange={(e) => {
                this.handleMultiAirwayBillsChange(e);
              }}
              multiline={4}
              autoComplete="off"
            />
            <div className="mt-3">
              <ButtonGroup>
                <Button
                  plain
                  disabled={!this.state.airwayBills}
                  onClick={() => this.handleMultiAirwayBillsRemove()}
                >
                  Clear
                </Button>
                <Button
                  plain
                  monochrome
                  disabled={this.state.airwayBills?.length < 1}
                  onClick={() => this.handleMultiAirwayBillsClick()}
                >
                  Search
                </Button>
              </ButtonGroup>
            </div>
          </>
        ),
        hideClearButton: true,
      },
    ];
  }

  renderDate(isSelected) {
    if (isSelected) {
      return (
        <>
          <div className="mb-3">
            <TextField
              autoComplete='off'
              label={`Start`}
              value={this.state.startDate}
              onChange={(v) => {
                if (!v) {
                  this.setState({
                    selectedStartDate: null,
                  });
                }
                this.setState({ startDate: v });
              }}
              placeholder="YYYY-MM-DD"
            />
          </div>

          <div className="mb-3">
            <DatePicker
              month={this.state.monthStart}
              year={this.state.yearStart}
              onChange={(v) => this.handleChangeStartDate(v)}
              onMonthChange={(month, year) =>
                this.setState({
                  monthStart: month,
                  yearStart: year,
                })
              }
              selected={this.state.selectedStartDate}
            />
          </div>

          <div className="mb-3">
            <TextField
              autoComplete='off'
              label={`End`}
              value={this.state.endDate}
              onChange={(v) => {
                if (!v) {
                  this.setState({
                    selectedEndDate: null,
                  });
                }
                this.setState({ endDate: v });
              }}
              placeholder="YYYY-MM-DD"
            />
          </div>

          <div>
            <DatePicker
              month={this.state.monthEnd}
              year={this.state.yearEnd}
              disableDatesBefore={this.state.selectedStartDate.start}
              onChange={(v) => this.handleChangeEndDate(v)}
              onMonthChange={(month, year) =>
                this.setState({
                  monthEnd: month,
                  yearEnd: year,
                })
              }
              selected={this.state.selectedEndDate}
            />
          </div>
        </>
      );
    }
  }

  _renderFilter(t) {
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flex: 0,
            paddingRight: '10px',
          }}
        >
          <Tooltip dismissOnMouseOut content={t('order.tooltip.refresh')}>
            <Button
              onClick={() => {
                this.setState(
                  {
                    isLoading: true,
                  },
                  () => {
                    this.loadData();
                  }
                );
              }}
              icon={RefreshMinor}
            />
          </Tooltip>
        </div>

        <div
          style={{
            flex: 1,
            width: '75%',
          }}
        >
          <Filters
            queryValue={this.state.params.search}
            filters={this.filters(t)}
            appliedFilters={this.state.appliedFilters}
            onQueryChange={(value) => {
              this.handleSearchChange(value);
            }}
            onQueryClear={this.handleSearchRemove}
            onClearAll={this.handleClearAll}
          />
        </div>

        {/* <div
          style={{
            flex: 0,
            paddingLeft: '10px'
          }}
        >
          <Popover
            active={
              this.state.showSort
            }
            activator={
              <Tooltip
                dismissOnMouseOut
                content={
                  t('order.tooltip.sort')
                }
              >
                <Button
                  disabled
                  onClick={
                    this.toggleShowSort
                  }
                  icon={
                    SortMinor
                  }
                />
              </Tooltip>
            }
            fluidContent
            onClose={
              () => this.toggleShowSort()
            }
          >
            <div
              style={{
                padding: '15px',
              }}
            >
              <ChoiceList
                title="Option"
                choices={[
                  { label: 'ASC', value: 'ASC' },
                  { label: 'DESC', value: 'DESC' },
                ]}
                selected={
                  this.state.selectedSort || ['']
                }
                onChange={(value) =>
                  this.setState({
                    selectedSort: value,
                  })
                }
              />
            </div>
          </Popover>
        </div> */}
      </div>
    );
  }

  _renderItem(item) {
    return (
      <ResourceItem key={`${item._id}`} id={`${item._id}`} url={''}>
        <Translation>
          {(t) => (
            <table>
              <tbody>
                <tr>
                  <td
                    style={{
                      fontWeight: 'bold',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '70px',
                      maxWidth: '70px',
                    }}
                  >
                    <Tooltip
                      dismissOnMouseOut
                      content={t('order.tooltip.details')}
                    >
                      <Link to={`/detail-order/${item._id}`}>
                        <Links monochrome>{`#${item.orderNumber}`}</Links>
                      </Link>
                    </Tooltip>
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '200px',
                      maxWidth: '200px',
                    }}
                  >
                    {dayjs(item.createdAt).format('MMM D, YYYY [at] hh:mm A')}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '200px',
                      maxWidth: '200px',
                    }}
                  >
                    {`${item.customer.firstName} ${item.customer.lastName}`}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '150px',
                      maxWidth: '150px',
                    }}
                  >
                    {item.trackingInfo.courier} - {item.trackingInfo.service}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '150px',
                      maxWidth: '150px',
                    }}
                  >
                    {item.isCOD ? 'COD' : 'Non COD'}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '150px',
                      maxWidth: '150px',
                    }}
                  >
                    {item.trackingInfo.airwaybill === '-' ? (
                      '—'
                    ) : (
                      <Tooltip
                        dismissOnMouseOut
                        content={t('order.tooltip.track')}
                      >
                        <Links
                          monochrome
                          onClick={async () => {
                            await this.trackingAwb(
                              item.trackingInfo.courier,
                              item.trackingInfo.airwaybill
                            );
                          }}
                        >
                          {item.trackingInfo.airwaybill}
                        </Links>
                      </Tooltip>
                    )}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '150px',
                      maxWidth: '150px',
                    }}
                  >
                    {this._renderAddressStatus(item.addressStatus)}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '100px',
                      maxWidth: '104px',
                    }}
                  >
                    {this._renderPayment(t, item.payment)}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '120px',
                      maxWidth: '120px',
                    }}
                  >
                    {this._renderFulfillment(t, item.fulfillment)}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '160px',
                      maxWidth: '160px',
                    }}
                  >
                    {this._renderShipment(t, item.shipment)}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '160px',
                      maxWidth: '160px',
                    }}
                  >
                    {this._renderDeliveryType(t, item.deliveryType)}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '135px',
                      maxWidth: '135px',
                    }}
                  >
                    {this._renderInsuranced(t, item.transaction?.isInsuranced)}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '200px',
                      maxWidth: '200px',
                    }}
                  >
                    {item.shippingLabelCounter || 0}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '80px',
                      maxWidth: '80px',
                    }}
                  >
                    {this._renderItems(t, item.item)}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '140px',
                      maxWidth: '140px',
                    }}
                  >
                    {(item.transaction.totalValue || 0).toLocaleString('id', {
                      style: 'currency',
                      currency: 'IDR',
                    })}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '200px',
                      maxWidth: '200px',
                    }}
                  >
                    {item.uniqueCode}
                  </td>

                  <td
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      minWidth: '200px',
                      maxWidth: '200px',
                    }}
                  >
                    {item.notification === '' ||
                      item.notification === null ||
                      item.notification === undefined
                      ? '—'
                      : item.notification}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </Translation>
      </ResourceItem>
    );
  }

  async handleChangeStatusOrder(status) {
    this.setState({
      changeStatusModal: false,
      isLoading: true,
    });

    const getDate = _.has(this.state.selectedPickupDate, 'start')
      ? this.state.selectedPickupDate.start
      : this.state.selectedPickupDate;
    const getTime = this.state.timePickUp;
    const assignDateTime = dayjs(
      `${dayjs(getDate).format('YYYY-MM-DD')} ${getTime}`
    ).format();
    const deliveryTime = this.state.selectedReadyToShip[0] === 'Pickup' ? assignDateTime : null
    const deliveryType = this.state.selectedReadyToShip[0]
      .toLowerCase()
      .replace(/\s/g, '');

    if (status === 'cancel') {
      await this.cancelPickupOrder();
    } else if (status === 'ready-to-ship') {
      const { orderData, selectedRows } = this.state
      const selectedOrder = orderData.filter((order) => {
        return selectedRows.find((s) => s.id.includes(order._id))
      });
      const includedSiCepatBEST = selectedOrder.find(o => o.trackingInfo.courier.includes('sicepat') && o.trackingInfo.service.includes('BEST'))
      const selectedTime = dayjs(deliveryTime)
      const overTime = dayjs(deliveryTime).hour(18).minute(0)
      const moreThan6pm = dayjs(selectedTime).isAfter(overTime)
      const payload = {
        orders: selectedRows,
        deliveryTime,
        deliveryType,
      }

      if (includedSiCepatBEST && moreThan6pm) {
        this.setState({
          failMessage: 'Kamu hanya dapat memproses pengiriman menggunakan SiCepat BEST sebelum jam 18.00, silahkan gunakan layanan lain atau proses kembali di hari selanjutnya.',
          failToast: true,
          isLoading: false,
        })
      } else {
        await ManageOrderRestService.changeStatusOrder(status, payload)
          .then((response) => {
            this.setState({
              successMessage: 'Success change status',
              successToast: true,
            });
          })
          .catch((err) => {
            this.setState({
              failMessage: MessageParserService.parseSpesific(err),
              failToast: true,
            });
          })
          .finally(async () => {
            this.setState(
              {
                isLoading: false,
              },
              () => {
                this.loadData();
              }
            );
          });
      }
    } else {
      const payload = {
        orders: this.state.selectedRows,
        deliveryTime,
        deliveryType,
      }

      await ManageOrderRestService.changeStatusOrder(status, payload)
        .then((response) => {
          this.setState({
            successMessage: 'Success change status',
            successToast: true,
          });
        })
        .catch((err) => {
          this.setState({
            failMessage: MessageParserService.parseSpesific(err),
            failToast: true,
          });
        })
        .finally(async () => {
          this.setState(
            {
              isLoading: false,
            },
            () => {
              this.loadData();
            }
          );
        });
    }
  }

  async handleUpdateOrderInsurance(insuranceState: boolean) {
    this.setState({
      updateOrderInsuranceModal: false,
      isLoading: true,
    });

    const payload = {
      orderIds: this.state.selectedRows.map((s) => s.id),
      activateInsurance: insuranceState,
    }

    await ManageOrderRestService.updateInsurance(payload)
      .then((response) => {
        this.setState({
          successMessage: response.data.message,
          successToast: true,
        });
      })
      .catch((err) => {
        this.setState({
          failMessage: MessageParserService.parseSpesific(err),
          failToast: true,
        });
      })
      .finally(async () => {
        this.setState(
          {
            isLoading: false,
          },
          () => {
            this.loadData();
          }
        );
      });
  }

  async cancelPickupOrder() {
    const getDate = _.has(this.state.selectedPickupDate, 'start')
      ? this.state.selectedPickupDate.start
      : this.state.selectedPickupDate;
    const getTime = this.state.timePickUp;
    const assignDateTime = dayjs(
      `${dayjs(getDate).format('YYYY-MM-DD')} ${getTime}`
    ).format();
    const deliveryTime = this.state.selectedReadyToShip[0] === 'Pickup' ? assignDateTime : null
    const deliveryType = this.state.selectedReadyToShip[0]
      .toLowerCase()
      .replace(/\s/g, '');

    const payload = {
      orders: this.state.selectedRows,
      deliveryTime,
      deliveryType,
    }

    await ManageOrderRestService.cancelPickupRequest(payload)
      .then((res) => {
        if (res && res.data && res.data.error && res.data.error.length) {
          const orderCantCancel: string[] = res.data.error;
          const orderNumberString = orderCantCancel.join(',');
          this.setState({
            failMessage: `Nomor order ${orderNumberString} gagal cancel. Selain status Unprocess, Processing, Ready to ship tidak bisa dicancel.`,
            failToast: true,
          });
        } else {
          this.setState({
            successMessage: 'Success cancel pickup request',
            successToast: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          failMessage: MessageParserService.parseSpesific(err),
          failToast: true,
        });
      })
      .finally(async () => {
        await this.loadData();
        this.setState(
          {
            isLoading: false,
          },
          () => {
            this.loadData();
          }
        );
      });
  }

  async retryOrder() {
    const getDate = _.has(this.state.selectedPickupDate, 'start')
      ? this.state.selectedPickupDate.start
      : this.state.selectedPickupDate;
    const getTime = this.state.timePickUp;
    const assignDateTime = dayjs(
      `${dayjs(getDate).format('YYYY-MM-DD')} ${getTime}`
    ).format();
    const deliveryTime = this.state.selectedReadyToShip[0] === 'Pickup' ? assignDateTime : null
    const deliveryType = this.state.selectedReadyToShip[0]
      .toLowerCase()
      .replace(/\s/g, '');

    const payload = {
      orders: this.state.selectedRows,
      deliveryTime,
      deliveryType,
    }

    await ManageOrderRestService.retryOrder(payload)
      .then((res) => {
        this.setState({
          successMessage: 'Success retry order',
          successToast: true,
        });
      })
      .catch((err) => {
        this.setState({
          failMessage: MessageParserService.parseSpesific(err),
          failToast: true,
        });
      })
      .finally(async () => {
        await this.loadData();
        this.setState(
          {
            isLoading: false,
          },
          () => {
            this.loadData();
          }
        );
      });
  }

  async trackingAwb(courier: string, awb: string) {
    this.setState({
      isLoading: true,
    });

    const payload = {
      courier,
      airwaybill: awb,
    };

    await ManageOrderRestService.getTrackingInfo(payload)
      .then((response) => {
        const awbData = response.data;
        this.setState({
          trackingInfo: awbData,
          trackAwbModal: true,
        });
      })
      .catch((err) => {
        this.setState({
          failMessage: MessageParserService.parseSpesific(err),
          failToast: true,
        });
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  _renderAwb(data) {
    return (
      <Translation>
        {(t) => (
          <div className="modal-awb">
            <div className="header-awb">
              <div className="courier">
                {this.state.trackingInfo.summary.courier_name || '-'}
              </div>

              <div className="title">{t('order.trackingContent.waybill')}</div>

              <div className="airwaybill">
                #{this.state.trackingInfo.summary.tracking_number || '-'}
              </div>
            </div>

            <div>
              <table className="data-awb">
                <tr>
                  <th>{t('order.trackingContent.sent')}</th>
                  <th>{t('order.trackingContent.received')}</th>
                </tr>

                <tr>
                  <td>
                    {data.summary.ship_date === '' ||
                      data.summary.ship_date === null
                      ? '-'
                      : dayjs(data.summary.ship_date).format('DD-MM-YYYY')}
                  </td>

                  <td>
                    {data.summary.delivery_date === '' ||
                      data.summary.delivery_date === null
                      ? '-'
                      : dayjs(data.summary.delivery_date).format('YYYY-MM-DD')}
                  </td>
                </tr>

                <tr>
                  <th>{t('order.trackingContent.origin')}</th>
                  <th>{t('order.trackingContent.destination')}</th>
                </tr>

                <tr>
                  <td>{data.summary.origin || '-'}</td>
                  <td>{data.summary.destination || '-'}</td>
                </tr>

                <tr>
                  <th>{t('order.trackingContent.sender')}</th>
                  <th>{t('order.trackingContent.receiver')}</th>
                </tr>

                <tr>
                  <td>{data.summary.shipper_name || '-'}</td>
                  <td>{data.summary.receiver_name || '-'}</td>
                </tr>
              </table>
            </div>

            <div className="status-shipping">
              <div className="title2">{t('order.trackingContent.status')}</div>

              <div>
                <table className="outbond">
                  <tr>
                    <th colSpan={3}>{t('order.trackingContent.outbond')}</th>
                  </tr>
                  {this._renderOutbond(data)}
                </table>
              </div>

              <div>
                <table className="time-shipping">
                  <tr>
                    <th colSpan={3}>{t('order.trackingContent.time')}</th>
                  </tr>

                  <tr>
                    <td>
                      {data.delivery_status?.pod_date
                        ? dayjs(
                          data.delivery_status.pod_date,
                          'YYYY-MM-DD hh:mm'
                        ).format('DD-MM-YYYY hh:mm')
                        : '-'}
                    </td>

                    <td>{data.delivery_status?.pod_receiver || '-'}</td>

                    <td>{data.delivery_status?.status || '-'}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        )}
      </Translation>
    );
  }

  _renderOutbond(data) {
    const list = [];

    for (const outbond of data.outbounds) {
      list.push(
        <tr>
          <td>{outbond.outbound_date || '-'}</td>
          <td>{outbond.city_name === null ? '' : outbond.city_name}</td>
          <td>
            {outbond.outbound_description || '-'}
          </td>
        </tr>
      );
    }

    return list;
  }

  async handlePagination(type) {
    let currentPage = this.state.pageNumber;

    let skip = this.state.skipNumber;

    let paramsPage = {
      take: 0,
      skip: 0,
    };

    if (type === 'prev') {
      const page = this.state.page;

      --currentPage;
      paramsPage = {
        take: page.take,
        skip: page.take * --skip,
      };
    } else if (type === 'next') {
      const page = this.state.page;

      ++currentPage;
      paramsPage = {
        take: page.take,
        skip: page.take * ++skip,
      };
    }

    this.setState(
      {
        page: paramsPage,
        skipNumber: skip,
        pageNumber: currentPage,
      },
      () => {
        this.loadData();
      }
    );
  }

  renderPickUp(isSelected) {
    if (isSelected) {
      return (
        <>
          <div className="mb-3">
            <DatePicker
              month={this.state.month}
              year={this.state.year}
              onChange={(v) =>
                this.setState({
                  selectedPickupDate: v,
                })
              }
              onMonthChange={(month, year) =>
                this.setState({
                  month,
                  year,
                })
              }
              selected={this.state.selectedPickupDate}
              disableDatesBefore={this.handleDisabledDate()}
            />
          </div>

          <div className="form-group">
            <input
              type="time"
              defaultValue={this.state.timePickUp}
              className="form-control form-control-lg"
              value={this.state.timePickUp}
              onChange={(e) =>
                this.setState({
                  timePickUp: e.target.value,
                })
              }
            />
          </div>
        </>
      );
    }
  }

  handleSelectionChange(items) {
    const { orderData, selectedRows } = this.state;
    const orders = orderData.filter((order) => items.includes(order._id));
    const newItems: any[] = _.compact(orders.map((o) => {
      const order = {
        id: o._id,
        orderId: o.orderId,
        orderNumber: o.orderNumber,
        shipdeoStatus: o.shipdeoStatus,
        courier: o.trackingInfo?.courier || ''
      };

      return o.addressStatus !== 'crossCheck' && order;
    }))
    const checkedAll = items.length === orderData.length;
    const alreadyChecked = selectedRows.length === newItems.length;
    const newSelectedRows = checkedAll && alreadyChecked ? [] : newItems;

    this.setState({
      selectedRows: newSelectedRows,
      selectedReadyToShip: ['Pickup'],
    });

    // const checkFalse = [];

    // _.forEach(newItems, (data: any) => {
    //   if (data.shipdeoStatus === 1 || data.shipdeoStatus === 4 || data.shipdeoStatus === 8) {
    //     checkFalse.push(true);
    //   } else {
    //     checkFalse.push(false);
    //   }

    //   if (checkFalse.includes(true)) {
    //     this.setState({
    //       disabledPrintShippingLabel: true
    //     });
    //   } else {
    //     this.setState({
    //       disabledPrintShippingLabel: false
    //     });
    //   }
    // });
  }

  counterShippingLabel() {
    this.setState({ isLoadingShipping: true });
    const data = {
      ordersId: this.state.selectedRows.map((s) => s.id),
    };
    ManageOrderRestService.counterShippingLabel(data)
      .then((res) => {
        this.setState(
          {
            dataPDF: null,
            isLoadingShipping: false,
            printSLabelModal: false,
          },
          () => {
            this.loadData();
          }
        );
      })
      .catch((err) => {
        this.setState({
          failMessage: MessageParserService.parseSpesific(err),
          failToast: true,
          isLoadingShipping: false,
        });
      });
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <ReactFinalForm
            initialValues={this.state.orderData}
            onSubmit={(values) => {
              // setTimeout(() => {
              // }, 400);
            }}
          >
            {({ handleSubmit }) => (
              <>
                {this.state.isLoading && (
                  <div
                    style={{
                      zIndex: 1000,
                      display: 'block',
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'black',
                      opacity: 0.5,
                    }}
                  >
                    <div
                      style={{
                        paddingTop: '23%',
                        paddingLeft: '48.5%',
                      }}
                    >
                      <Spinner accessibilityLabel="Loading..." size="large" />
                    </div>
                  </div>
                )}

                <Frame>
                  {this.state.successToast ? (
                    <Toast
                      content={this.state.successMessage}
                      onDismiss={() => {
                        this.setState({
                          successToast: false,
                        });
                      }}
                      duration={10000}
                    />
                  ) : (
                    this.state.failToast && (
                      <Toast
                        error
                        content={this.state.failMessage}
                        onDismiss={() => {
                          this.setState({
                            failToast: false,
                          });
                        }}
                        duration={10000}
                      />
                    )
                  )}

                  <Layout>
                    <Layout.Section>
                      <div className='px-4 list-manage-order'>
                        <div
                          className="text-right mb-1"
                          style={{ paddingRight: '1.6rem' }}
                        >
                          <a
                            className="btn-export"
                            onClick={() => this.handleExport(t)}
                          >
                            Export
                          </a>
                        </div>
                        <ResourceList
                          selectable
                          idForItem={(item) => `${item._id}`}
                          resourceName={{
                            singular: `${t('order.resourceName.singular')}`,
                            plural: `${t('order.resourceName.plural')}`,
                          }}
                          items={this.state.orderData}
                          promotedBulkActions={[
                            {
                              content: `${t('order.bulk.shipping')}`,
                              disabled: this.state.selectedRows.some(v => v.shipment === 10),
                              onAction: this.handlePrintShippingLabel,
                              // disabled: this.state.disabledPrintShippingLabel
                            },
                          ]}
                          bulkActions={[
                            {
                              content: `${t(
                                'order.bulk.more.activateInsurance'
                              )}`,
                              disabled: this.state.selectedRows.some(v => v.shipment === 10),
                              onAction: async () => {
                                const title = t(
                                  'order.modal.bulkInsuranceUpdate.activate.title'
                                ).toString();
                                this.setState({
                                  updateOrderInsuranceModal: true,
                                  updateOrderInsuranceModalTitle: title,
                                  updateOrderInsuranceToState: true,
                                });
                              },
                            },
                            {
                              content: `${t(
                                'order.bulk.more.deactivateInsurance'
                              )}`,
                              disabled: this.state.selectedRows.some(v => v.shipment === 10),
                              onAction: async () => {
                                const title = t(
                                  'order.modal.bulkInsuranceUpdate.deactivate.title'
                                ).toString();
                                this.setState({
                                  updateOrderInsuranceModal: true,
                                  updateOrderInsuranceModalTitle: title,
                                  updateOrderInsuranceToState: false,
                                });
                              },
                            },
                            {
                              content: `${t('order.bulk.more.process')}`,
                              disabled: this.state.selectedRows.some(v => v.shipment === 10),
                              onAction: async () => {
                                const statusTitle: string = t(
                                  `order.filters.shipment.status.processing`
                                ).toString();
                                const title = t('order.changeStatus', {
                                  status: statusTitle,
                                }).toString();
                                this.setState({
                                  changeStatusModalType: 'processing',
                                  changeStatusModalTitle: title,
                                  changeStatusModal: true,
                                });
                              },
                              // await this.handleChangeStatusOrder(
                              //   'processing'
                              // )
                            },
                            {
                              content: `${t('order.bulk.more.confirm')}`,
                              disabled: this.state.selectedRows.some(v => v.shipment === 10),
                              onAction: async () => {
                                const statusTitle: string = t(
                                  `order.filters.shipment.status.${_.camelCase(
                                    'ready-to-ship'
                                  )}`
                                ).toString();
                                const title = t('order.changeStatus', {
                                  status: statusTitle,
                                }).toString();
                                // generate hours and minutes for pickup
                                const hours =
                                  new Date().getHours() < 10
                                    ? `0${new Date().getHours().toString()}`
                                    : new Date().getHours().toString();
                                const minutes =
                                  new Date().getMinutes() < 10
                                    ? `0${new Date().getMinutes().toString()}`
                                    : new Date().getMinutes().toString();

                                this.setState({
                                  changeStatusModalType: 'ready-to-ship',
                                  changeStatusModalTitle: title,
                                  changeStatusModal: true,
                                  timePickUp: `${hours}:${minutes}`,
                                });
                              },
                              // onAction: async () =>
                              //   await this.handleChangeStatusOrder(
                              //     'ready-to-ship'
                              //   )
                            },
                            {
                              content: `${t('order.bulk.more.cancel')}`,
                              disabled: this.state.selectedRows.some(v => v.shipment === 10),
                              onAction: async () => {
                                const statusTitle: string = t(
                                  `order.filters.shipment.status.${_.camelCase(
                                    'cancel'
                                  )}`
                                ).toString();
                                const title = t('order.changeStatus', {
                                  status: statusTitle,
                                }).toString();
                                this.setState({
                                  changeStatusModalType: 'cancel',
                                  changeStatusModalTitle: title,
                                  changeStatusModal: true,
                                });
                                // onAction: async () =>
                                //   this.cancelPickupOrder()
                              },
                            },
                            {
                              content: `Retry Order`,
                              disabled: this.state.selectedRows.some(v => v.shipment === 10),
                              onAction: async () => await this.retryOrder(),
                            },
                          ]}
                          renderItem={this._renderItem}
                          onSelectionChange={(items: string[]) =>
                            this.handleSelectionChange(items)
                          }
                          selectedItems={this.state.selectedRows.map((s) => s.id)}
                          filterControl={this._renderFilter(t)}
                        />

                        <div className="manage-order pagination-section">
                          <Pagination
                            label={this.state.pageNumber}
                            hasNext={
                              this.state.page.take > this.state.orderData.length
                                ? false
                                : true
                            }
                            hasPrevious={
                              this.state.pageNumber <= 1 ? false : true
                            }
                            onNext={async () => {
                              await this.handlePagination('next');
                            }}
                            onPrevious={async () => {
                              await this.handlePagination('prev');
                            }}
                          />
                        </div>

                        {this.state.trackAwbModal && this.state.trackingInfo && (
                          <Modal
                            instant
                            open={this.state.trackAwbModal}
                            onClose={() =>
                              this.setState({
                                trackAwbModal: false,
                              })
                            }
                            title={t('order.modal.tracking.title')}
                            secondaryActions={[
                              {
                                content: `${t('order.modal.tracking.button')}`,
                                onAction: () =>
                                  this.setState({
                                    trackAwbModal: false,
                                  }),
                              },
                            ]}
                          >
                            <Modal.Section>
                              <TextContainer>
                                {this._renderAwb(this.state.trackingInfo)}
                              </TextContainer>
                            </Modal.Section>
                          </Modal>
                        )}

                        <Modal
                          instant
                          loading={this.state.isLoadingShipping}
                          open={this.state.printSLabelModal}
                          onClose={() => this.counterShippingLabel()}
                          title={t('order.modal.shipping.title')}
                          large
                          noScroll
                        >
                          <iframe src={this.state.shippingLabelURL} frameBorder="0" style={{ width: '100%', height: '655px', padding: '2rem' }}></iframe>
                        </Modal>

                        {/**
                         * Modal Confirm change status
                         */}
                        <Modal
                          instant
                          open={this.state.changeStatusModal}
                          title={t('confirm')}
                          onClose={() => {
                            this.setState({ changeStatusModal: false });
                          }}
                          primaryAction={{
                            content: t('button.save').toString(),
                            onAction: () => {
                              this.handleChangeStatusOrder(
                                this.state.changeStatusModalType
                              );
                            },
                          }}
                          secondaryActions={[
                            {
                              content: t('button.cancel').toString(),
                              onAction: () => {
                                this.setState({
                                  changeStatusModal: false,
                                });
                              },
                            },
                          ]}
                        >
                          <Modal.Section>
                            {this.state.changeStatusModalType ===
                              'ready-to-ship' && (
                                <ChoiceList
                                  title={``}
                                  choices={[
                                    {
                                      label: t(
                                        'order.modal.confirm.readyToShip.dropOff'
                                      ),
                                      value: `${t(
                                        'order.modal.confirm.readyToShip.dropOff'
                                      )}`,
                                      disabled: this.state.selectedRows.some(v => ['gosend', 'grab'].includes(v.courier)),
                                    },
                                    {
                                      label: t(
                                        'order.modal.confirm.readyToShip.pickUp'
                                      ),
                                      value: `${t(
                                        'order.modal.confirm.readyToShip.pickUp'
                                      )}`,
                                      renderChildren: (isSelected) =>
                                        this.renderPickUp(isSelected),
                                    },
                                  ]}
                                  selected={this.state.selectedReadyToShip}
                                  onChange={(v) =>
                                    this.setState({
                                      selectedReadyToShip: v,
                                    })
                                  }
                                />
                              )}

                            {this.state.changeStatusModalType !==
                              'ready-to-ship' && (
                                <TextContainer>
                                  {this.state.changeStatusModalTitle}
                                </TextContainer>
                              )}
                          </Modal.Section>
                        </Modal>

                        {/**
                         * Modal Confirm change status
                         */}
                        <Modal
                          instant
                          open={this.state.updateOrderInsuranceModal}
                          title={t('confirm')}
                          onClose={() => {
                            this.setState({ updateOrderInsuranceModal: false });
                          }}
                          primaryAction={{
                            content: t('button.save').toString(),
                            onAction: () => {
                              this.handleUpdateOrderInsurance(
                                this.state.updateOrderInsuranceToState
                              );
                            },
                          }}
                          secondaryActions={[
                            {
                              content: t('button.cancel').toString(),
                              onAction: () => {
                                this.setState({
                                  updateOrderInsuranceModal: false,
                                });
                              },
                            },
                          ]}
                        >
                          <Modal.Section>
                            <TextContainer>
                              {this.state.updateOrderInsuranceModalTitle}
                            </TextContainer>
                          </Modal.Section>
                        </Modal>
                      </div>
                    </Layout.Section>
                  </Layout>
                </Frame>
              </>
            )}
          </ReactFinalForm>
        )}
      </Translation>
    );
  }
}

export default withRouter(ManageOrderFormComponent);
