import { ServerApiHttpService } from '../../libraries/server/server-api-http.service';
import { environment } from '../../environments/environment';

declare interface IPostSubscriptionPlan {
  category: 'monthly' | 'annual' | 'custom',
  shop: string;
}

export class AboutUsRestService extends ServerApiHttpService {
  private static serverApiHttpService = new ServerApiHttpService(
    environment.server.mainApiBaseUrl + '/v1/main/subscription'
  );

  protected static xApiToken = "441394cf241b563d5308fb04c90da42d";

  static async postSubscriptionPlan({ category, shop }: IPostSubscriptionPlan) {
    return this.serverApiHttpService
      .post('', { category, shop, period: category }, { headers: { 'x-api-key': this.xApiToken } })
      .toPromise();
  }

  static async getSubscriptionPlanIsActiveFlag(params: string) {
    return this.serverApiHttpService.get(`/${params}`, { headers: { 'x-api-key': this.xApiToken } }).toPromise();
  }
}
