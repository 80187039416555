import React from "react";
import { NavLink } from "react-router-dom";
import Help from './../../../assets/img/about/about-help.png';
import Download from './../../../assets/img/about/about-download.png';
import ArrowRightBlue from './../../../assets/img/about/arrow-right-blue.svg';

export class AsideAbout extends React.Component<any, any> {
    render() {
        return (
            <div className={`row ${this.props.isShowPlan && ("sticky-aside")}`}>
                <div className="col-12 call-us-content">
                    <div className="call-us-section row">
                        <div className="col-12 col-lg-5 align-self-end">
                            <img
                                src={Help}
                                alt="Banner Help"
                                className="img-help img-fluid"
                            />
                        </div>

                        <div className="content-help align-self-center p-2 col-12 col-lg-7">
                            <span className="title">Butuh bantuan?</span>

                            <p className="description mb-1">
                                Hubungi Help Center kami
                            </p>

                            <NavLink
                                to={{
                                    pathname:
                                        'https://help.clodeo.com/id/collections/3595132-artikel-tutorial-shipdeo',
                                }}
                                target="_blank"
                            >
                                <button className="cta-help">
                                    <div className="d-flex align-items-center">
                                        <span className="mr-1">Hubungi Kami</span>{' '}
                                        <img src={ArrowRightBlue} alt="Arrow Right" />
                                    </div>
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="col-12 px-3">
                    <img
                        src={Download}
                        alt="Banner Download"
                        className="w-100 pt-3 img-download"
                    // useMap="#utm-download"
                    />
                </div>

                {/* <map name="utm-download">
                        <area
                          shape="rect"
                          coords="76,360,232,404"
                          alt="Google Play"
                          href="https://play.google.com/store/apps/details?id=com.shipdeo"
                          target="_blank"
                        />

                        <area
                          shape="rect"
                          coords="76,408,232,452"
                          alt="App Store"
                          href="https://apps.apple.com/id/app/shipdeo-mobile/id1611450887"
                          target="_blank"
                        />
                      </map> */}
            </div>
        )
    }
}