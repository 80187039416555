import { ServerApiHttpService } from '../../libraries/server/server-api-http.service';
import { environment } from '../../environments/environment';

export class ShippingRateRestService extends ServerApiHttpService {
  private static serverApiHttpService = new ServerApiHttpService(
    environment.server.mainApiBaseUrl + '/v1/custom-shipping-rate'
  );

  public static async getAllCustomShippingRate(params) {
    return this.serverApiHttpService
      .get('', { params })
      .toPromise();
  }

  public static async getCustomShippingRate(id) {
    return this.serverApiHttpService
      .get(`/${id}`)
      .toPromise()
  }

  public static async addCustomShippingRate(data) {
    return this.serverApiHttpService
      .post('', data)
      .toPromise();
  }

  public static async updateCustomShippingRate(id, data) {
    return this.serverApiHttpService
      .put(`/${id}`, data)
      .toPromise();
  }

  public static async deleteCustomShippingRate(id) {
    return this.serverApiHttpService
      .delete(`/${id}`)
      .toPromise();
  }

  public static async toggleInactiveShippingRate(id, active) {
    return this.serverApiHttpService.post(`${id}/active/${active}`)
      .toPromise();
  }

  public static async getService(params) {
    return this.serverApiHttpService
      .get('list-service', { params })
      .toPromise();
  }
}
