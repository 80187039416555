import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';

class ExportExcelService {
  constructor() { }

  public static async export(csvData, fileName, px = 140) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const wscols = [];
    for (const v of csvData) wscols.push({ wpx: px });

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    const timeNow = moment().format('dMMYYYY_HHmmss');

    await FileSaver.saveAs(data, fileName + '-' + timeNow + fileExtension);
  }
}

export default ExportExcelService;