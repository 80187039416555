// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._14aWjVa87-tIpLnYB_z5n {\n  display: flex;\n  justify-content: left;\n  border-bottom: 1px solid #DDDDDD;\n}\n\n._3pcdlZPgvypCIuvqKnzqXM {\n  width: 150px;\n  padding-top: calc(64px * var(--global-scale));\n  padding-bottom: calc(24px * var(--global-scale));\n  font-size: calc(20px * var(--global-scale));\n  line-height: 23px;\n  text-align: center;\n  text-decoration: none;\n  color: #003A52;\n  line-height: 40px;\n}\n\n._3pcdlZPgvypCIuvqKnzqXM:hover {\n  text-decoration: none;\n  color: #008060;\n}\n\n._36OSjLvDmKZxzw9xBdpXsm {\n  border-bottom: 2px solid #008060;\n  font-weight: 600;\n  text-decoration: none;\n  color: #003A52;\n  line-height: 40px;\n}", ""]);
// Exports
exports.locals = {
	"navbar": "_14aWjVa87-tIpLnYB_z5n",
	"navbarItem": "_3pcdlZPgvypCIuvqKnzqXM",
	"navbarItemActive": "_36OSjLvDmKZxzw9xBdpXsm"
};
module.exports = exports;
