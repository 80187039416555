import { ServerApiHttpService } from '../../libraries/server/server-api-http.service';
import { environment } from '../../environments/environment';

export class HomePageRestService extends ServerApiHttpService {
  private static serverApiHttpService = new ServerApiHttpService(environment.server.mainApiBaseUrl + '/v1/main/shop');

  public static async getLanguage() {
    return this.serverApiHttpService.get('language').toPromise();
  }
}
