import React from 'react';
import { AppProvider } from '@shopify/polaris';
import { environment } from './environments/environment';
import { AppRouter } from './app-router';
import { Provider as AppBridgeProvider } from '@shopify/app-bridge-react';
import { BrowserRouter } from 'react-router-dom';
import translations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';
import { ServerApiHttpService } from './libraries/server/server-api-http.service';
import { AppContextProvider } from './store/app-context';

export class App extends React.Component<any, any> {
  render() {
    const params = new URLSearchParams(window.location.search);
    const shopOrigin = params.get('shop') || window.location.host;
    const host = params.get('host') || btoa(`${shopOrigin}/admin`);
    const config = {
      apiKey: environment.shopify.apiKey,
      // shopOrigin: shopOrigin, // is Deprecated
      forceRedirect: true,
      host: host
    };

    console.log(config);
    if (params.has('token')) {
      ServerApiHttpService.setBearerToken(params.get('token'));
    }

    return (
      <BrowserRouter>
        <AppBridgeProvider config={config}>
          <AppContextProvider>
            <AppProvider i18n={translations}>
              <AppRouter />
            </AppProvider>
          </AppContextProvider>
        </AppBridgeProvider>
      </BrowserRouter>
    );
  }
}
