import React from 'react';
import './shipping.scss';

export class ShippingGuide extends React.Component<any, any> {
  private listGuidePoint = [
    '1. Login ke portal shipdeo (<a href="https://portal.shipdeo.com" target="_blank">https://portal.shipdeo.com</a>)',
    '2. Pastikan akun kamu sudah di level bisnis',
    '3. Buka menu Pengaturan > Integrasi > Tambahkan integrasi',
    '4. Tambahkan aplikasi Shopify kamu',
    '5. Request aktivasi aplikasi kamu ke customer support via Intercom (<a href="https://portal.shipdeo.com" target="_blank">https://portal.shipdeo.com</a>) atau via Whatsapp (<a href="https://wa.me/+6287897759000" target="_blank">087897759000</a>)',
    '6. Jika sudah aktif maka informasi client id dan client secret akan muncul pada aplikasi anda',
    '7. Selanjutnya tinggal copy dan paste informasi tersebut ke form aktivasi shipping',
    '8. Jika sudah aktif maka anda dapat langsung menggunakan shipdeo',
  ];

  render() {
    return (
      <div className="shipping-guide">
        <h2 className="pb-1">Cara mendapatkan credential Shipdeo :</h2>
        {this.listGuidePoint.map((val, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: val }}></p>
        ))}
      </div>
    );
  }
}
