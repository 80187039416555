import React from 'react';
import { Layout, Page, Button, PageActions } from '@shopify/polaris';
import ManageOrderFormComponent from './manage-order-form.component';
import { Translation } from 'react-i18next';

export class ManageOrderPage extends React.Component<any, any> {
  render() {
    return (
      <>
        <head>
          <title>Manage Order</title>
        </head>
        <Translation>
          {(t) => (
            <div className="Polaris-Page__Content p-0">
              <div className="manage-order-page">
                <ManageOrderFormComponent />
              </div>
            </div>
          )}
        </Translation>
      </>
    );
  }
}
