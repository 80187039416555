import React from 'react';
import {
  Layout,
  Page,
  Button,
  PageActions
} from '@shopify/polaris';
import DetailOrderFormComponent from './detail-order-form.component';
import { Translation } from 'react-i18next';

export class DetailOrderPage extends React.Component<any, any> {
  render() {
    return (
      <>
        <head>
          <title>Detail Order</title>
        </head>
        <Translation>
          {
            t => (
              <Page fullWidth>
                <DetailOrderFormComponent />
              </Page>
            )
          }
        </Translation>
      </>
    );
  }
}