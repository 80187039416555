import { ServerApiHttpService } from '../../libraries/server/server-api-http.service';
import { environment } from '../../environments/environment';

export class ManageOrderRestService extends ServerApiHttpService {
  private static orderApiHttpService = new ServerApiHttpService(
    environment.server.mainApiBaseUrl + '/v1/main/order'
  );

  private static filterApiHttpService = new ServerApiHttpService(
    environment.server.mainApiBaseUrl + '/v1/main/filter-order'
  );

  private static shippingLabelApiHttpService = new ServerApiHttpService(
    environment.server.mainApiBaseUrl + '/v2/main/order/shipping-label'
  );

  public static getManageOrder(payload?) {
    return this.orderApiHttpService.post('list-orders', null, { params: payload });
  }

  public static async changeStatusOrder(status, payload?) {
    return this.orderApiHttpService
      .post(`to-${status}`, payload)
      .toPromise();
  }

  public static async counterShippingLabel(ordersId) {
    return this.orderApiHttpService
      .post('shipping-label/counter', ordersId)
      .toPromise();
  }

  public static async cancelPickupRequest(payload) {
    return this.orderApiHttpService
      .post(`cancel-pickup`, payload)
      .toPromise();
  }

  public static async getTrackingInfo(payload) {
    return this.orderApiHttpService
      .post(`tracking-info`, payload)
      .toPromise();
  }

  public static async getShippingLabel(payload) {
    return this.orderApiHttpService
      .post(`shipping-label`, payload)
      .toPromise();
  }

  public static async retryOrder(payload) {
    return this.orderApiHttpService
      .post(`retry-order`, payload)
      .toPromise();
  }

  public static async updateInsurance(payload) {
    return this.orderApiHttpService
      .patch(`update-insurance`, payload)
      .toPromise();
  }

  public static async getFilter() {
    return this.filterApiHttpService
    .get(``)
    .toPromise();
  }

  public static async setFilter(payload?) {
    return this.filterApiHttpService
    .post(``, { data: payload })
    .toPromise();
  }

  public static async loadShippingLabel(payload?) {
    return this.shippingLabelApiHttpService
    .post(``, payload)
    .toPromise();
  }
}
