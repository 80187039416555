import React from 'react';
import MainFooterLayout from './footer/main-footer.layout';
import { MainNavbarLayout } from './navbar/main-navbar.layout';
import { Card } from '@shopify/polaris';
import './main-layout.scss';

export class MainLayout extends React.Component<any, any> {
  render() {
    return (
      <>
        <div className="navbar-section">
          {!location.pathname.match('/detail-order/') &&
            !location.pathname.match('/shipping-rate/create') &&
            !location.pathname.match('/shipping-rate/update') ? (
            <div>
              <Card>
                <>
                  <MainNavbarLayout />

                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    {this.props.children}
                  </div>
                </>
              </Card>
            </div>
          ) : (
            <>{this.props.children}</>
          )}
        </div>

        <div
          style={{
            bottom: 0,
            zIndex: 100,
            position: 'fixed',
            width: '100%',
          }}
        >
          <MainFooterLayout />
        </div>
      </>
    );
  }
}
