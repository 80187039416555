import { ServerApiHttpService } from '../../libraries/server/server-api-http.service';
import { environment } from '../../environments/environment';

export class ShippingRestService extends ServerApiHttpService {
  private static serverApiHttpService = new ServerApiHttpService(environment.server.mainApiBaseUrl + '/v1/main/shipping');

  public static async getShipping() {
    return this.serverApiHttpService.get('', {
      withCredentials: true,
    }).toPromise();
  }
}
