import { ServerApiHttpService } from '../../libraries/server/server-api-http.service';
import { environment } from '../../environments/environment';

export class DetailOrderRestService extends ServerApiHttpService {
  private static serverApiHttpService = new ServerApiHttpService(
    environment.server.mainApiBaseUrl + '/v1'
  );

  public static async getDetailOrder(orderId?) {
    return this.serverApiHttpService
      .get(`/main/order/${orderId}`)
      .toPromise();
  }

  public static async updateCustomerAddress(orderId, data) {
    return this.serverApiHttpService
      .put(`/main/order/${orderId}`, data)
      .toPromise();
  }

  public static async updateOrigin(id, payload) {
    return this.serverApiHttpService
      .patch(`/main/order/${id}/origin`, payload)
      .toPromise();
  }

  public static async trackingInfo(data) {
    return this.serverApiHttpService
      .post(`/main/order/tracking-info`, data)
      .toPromise();
  }

  public static async calculatePricing(orderId, data) {
    return this.serverApiHttpService
      .post(`/pricing/calculate/${orderId}`, data)
      .toPromise();
  }

  public static async getLocation() {
    return this.serverApiHttpService
      .get(`/shopify/locations`)
      .toPromise();
  }
}
