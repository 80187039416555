export const environment = {
  production: false,
  shopify: {
    apiKey: 'dc88ce0c41fff3b89056882e16a407cd',
    apiVersion: '2020-10',
    host: "https://sf-plugin-main-api-development.shipdeo.app/shopify/app"
  },
  server: {
    mainApiBaseUrl:
      'https://sf-plugin-main-api-development.shipdeo.app',
  },
};
