import { ServerApiHttpService } from '../../libraries/server/server-api-http.service';
import { environment } from '../../environments/environment';

export class LoginRestService extends ServerApiHttpService {
  private static serverApiHttpService = new ServerApiHttpService(environment.server.mainApiBaseUrl + '/v1/main/shop-config');
  private static serverCheckConnection = new ServerApiHttpService(environment.server.mainApiBaseUrl + '/v1/main/shop');

  public static async getConfig() {
    return this.serverApiHttpService.get('').toPromise();
  }

  public static async saveConfig(data) {
    return this.serverApiHttpService.post('save', data).toPromise();
  }

  public static async checkConnection() {
    return this.serverCheckConnection.get('connection-check').toPromise();
  }
}
