import React from 'react';
import {
  Layout,
  Page
} from '@shopify/polaris';
import { Translation } from 'react-i18next';
import ConfigFormComponent from './config-form.component';

export class ConfigPage extends React.Component<any, any> {
  render() {
    return (
      <>
      <head>
        <title>Configuration</title>
      </head>
      <Translation>
        {
          t => (
            <Page
            fullWidth
            >
              <div className="config-page">
                <ConfigFormComponent />
              </div>
            </Page>
          )
        }
      </Translation>
        </>
    );
  }
}
