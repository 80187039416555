import { Button, Form, FormLayout, TextField } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { Translation } from 'react-i18next';

interface IDetailOrderUpdateCustomerProps {
  data: {
    customer: {
      phone: string;
    }
  }
  onSubmit: (value: string) => void;
  onCancel: () => void;
}

export function DetailOrderUpdateCustomer(props: IDetailOrderUpdateCustomerProps) {
  const { data, onSubmit, onCancel } = props;
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  function onUpdatePhoneNumber(value: string) {
    setPhoneNumber(!value.length ? value : value.match(/\d+/g).join(''));
  }

  useEffect(() => {
    setPhoneNumber(data?.customer?.phone);
  }, [data]);

  return (
    <Translation>
      {t => (
        <>
          <Form onSubmit={() => onSubmit(phoneNumber)}>
            <FormLayout>
              <TextField
                autoComplete='off'
                value={phoneNumber}
                onChange={onUpdatePhoneNumber}
                label={`${t('order.detail.modal.form.phone')}`}
                type="text"
                maxLength={13}
              />

              <div className="d-flex justify-content-end">
                <div className="mr-3">
                  <Button onClick={onCancel}>Cancel</Button>
                </div>
                <Button disabled={phoneNumber.length < 9} primary submit>Update</Button>
              </div>
            </FormLayout>
          </Form>
        </>
      )}
    </Translation>
  )
}