// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1llC4-1oToIpBr_beF6Suv {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 40px;\n  background-color: white;\n  bottom: 0px;\n}", ""]);
// Exports
exports.locals = {
	"footer": "_1llC4-1oToIpBr_beF6Suv"
};
module.exports = exports;
