import React from 'react';
import { HomePage } from './features/home/home.page';
import { LoginPage } from './features/login/login.page';
import { ConfigPage } from './features/config/config.page';
import { AuthenticationPage } from './features/shipping/shipping.page';
import { ManageOrderPage } from './features/manage-order/manage-order.page';
import { DetailOrderPage } from './features/detail-order/detail-order.page';
import { useRoutePropagation } from '@shopify/app-bridge-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MainLayout } from './layouts/main/main.layout';
import { ToastContainer } from 'react-toastify';
import googleAnalyticService from './libraries/analytics/google-analytics.service';
import { ShippingRateCreateComponent } from './features/shipping-rate/create/shipping-rate-create.component';
import { ShippingRateUpdateComponent } from './features/shipping-rate/update/shipping-rate-update.component';
// import ConfigurationComponent from './features/config';
// import ProductConfigListComponent from './features/product-config';
// import DetailOrderComponent from './features/detail-order';
import 'react-toastify/dist/ReactToastify.css';
import { AboutPage } from './features/about';

export function AppRouter(props) {
  useRoutePropagation(props.location);

  return (
    <Switch>
      <MainLayout>
        <Route exact path="/">
          <Redirect to="/manage-order" />
        </Route>
        <Route path={'/home'} component={HomePage} />
        <Route path={'/login'} component={LoginPage} />
        <Route path={'/config'} component={ConfigPage} />
        <Route path={'/authentication'} component={AuthenticationPage} />
        <Route
          path={'/shipping-rate/create'}
          component={ShippingRateCreateComponent}
        />
        <Route
          path={'/shipping-rate/update/:id'}
          component={ShippingRateUpdateComponent}
        />
        <Route path={'/manage-order'} component={ManageOrderPage} />
        <Route path={'/detail-order/:id'} component={DetailOrderPage} />
        <Route path={'/about'} component={AboutPage} />

        {/* Toast Notification */}
        <ToastContainer />
      </MainLayout>
    </Switch>
  );
}
