import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Form,
  FormLayout,
  Frame,
  Spinner,
  TextField,
  Toast,
} from '@shopify/polaris';
import { DetailOrderUpdateAddressRestService } from './detail-order-update-address-rest.service';
import { Translation } from 'react-i18next';
import './detail-order-update-address.scss';
import { MessageParserService } from '../../../libraries/common/message-parser.service';

function DetailOrderUpdateAddressFormComponent(props) {
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [address1, setAddress1] = useState<string>('');
  const [address2, setAddress2] = useState<string>('');
  const [addressCode, setAddressCode] = useState<string>('');
  const [loadingAddress2, setLoadingAddress2] = useState<boolean>(false);
  const [optionsAddress2, setOptionsAddress2] = useState<any>([]);
  const [selectedAddress2, setSelectedAddress2] = useState<any>([]);
  const [city, setCity] = useState<string>('');
  const [disabledUpdate, setDisabledUpdate] = useState<boolean>(true);
  const [province, setProvince] = useState<string>('');
  const [dataZip, setDataZip] = useState<any>([]);
  const [zip, setZip] = useState<string>('');
  const [optionsZip, setOptionsZip] = useState<any>(dataZip);
  const [loadingZipComponent, setLoadingZipComponent] = useState<boolean>(false);
  const [selectedZip, setSelectedZip] = useState<any>([]);
  const [failMessage, setFailMessage] = useState<any>();
  const [failToast, setFailToast] = useState<boolean>(false);

  const updateAddress = (value) => {
    setAddress1(value);
    setDisabledUpdate(false);
  };

  const updateSelectionAddress2 = useCallback((selected) => {
    const splitSelected = selected[0].split(' - ');
    const getDistrict = splitSelected[0];
    const selectedValue = selected.map((selectedItem) => {
      const matchedOption = optionsAddress2.find((option) => {
        return option.label.match(selectedItem);
      });
      return matchedOption;
    });

    const getCityCode = selectedValue[0].code.substr(0, 5);

    setSelectedAddress2(selected);
    setAddress2(getDistrict);
    setCity(selectedValue[0].city);
    setProvince(selectedValue[0].province);
    setAddressCode(selectedValue[0].code);
    setLoadingZipComponent(true);
    setDisabledUpdate(false);

    DetailOrderUpdateAddressRestService.getPostalCode({ name: getDistrict, cityCode: getCityCode })
      .then((code) => {
        const mappedZip = code.map(c => ({ ...c, label: c.postalCode, value: c.postalCode }));

        setDataZip(mappedZip);
        setOptionsZip(mappedZip);
        setZip(mappedZip[0].postalCode);
        setSelectedZip(mappedZip[0].postalCode);
        setLoadingZipComponent(false);
      })
      .catch(err => {
        setFailMessage(MessageParserService.parseSpesific(err));
        setFailToast(true);
      });
  }, [optionsAddress2]);

  const updateZip = useCallback((value) => {
    setZip(value);

    if (!value) {
      setOptionsZip(dataZip);
      return;
    }

    const filterRegex = new RegExp(value, 'i');
    const resultOptions = dataZip.filter((option) => option.label.match(filterRegex));

    setOptionsZip(resultOptions);
  }, [dataZip]);

  const updateSelectionZip = useCallback((selected) => {
    const selectedValue = selected.map((selectedItem) => {
      const matchedOption = optionsZip.find((option) => {
        return option.value.match(selectedItem);
      });
      return matchedOption;
    });

    setSelectedZip(selected);
    setZip(selectedValue[0].value);
    setDisabledUpdate(false);
  }, [optionsZip]);

  const textFieldAddress2 = (t) => (
    <Autocomplete.TextField
      autoComplete='off'
      onChange={(v) => setAddress2(v)}
      label={t('order.detail.modal.form.district')}
      value={address2}
      placeholder={t('order.detail.modal.form.district')}
    />
  );

  const textFieldZip = (t) => (
    <Autocomplete.TextField
      autoComplete='off'
      onChange={updateZip}
      label={t('order.detail.modal.form.zip')}
      type="number"
      value={zip}
      placeholder={t('order.detail.modal.form.zip')}
    />
  );

  const handleSubmit = (event) => {
    const assignObj = {
      address1,
      address2,
      addressCode,
      city,
      province,
      zip,
      country: 'ID'
    };

    return props.handleSubmit(assignObj);
  };

  useEffect(() => {
    const getCityCode = props.address.addressCode.substr(0, 5);
    setAddress1(props.address.address1);
    setAddress2(props.address.address2);
    setSelectedAddress2(`${props.address.address2.toUpperCase()} - ${props.address.city.toUpperCase()}`);
    setCity(props.address.city);
    setAddressCode(props.address.addressCode);

    setProvince(props.address.province);
    setZip(props.address.zip);
    setSelectedZip(props.address.zip);
    setLoadingModal(true);

    DetailOrderUpdateAddressRestService.getLocation({ name: props.address.address2, type: 'subdistrict' })
      .then((location) => {
        const mappedLocation = location.map(loc => ({ ...loc, label: `${loc.subdistrict} - ${loc.city}`, value: `${loc.subdistrict} - ${loc.city}` }));
        setOptionsAddress2(mappedLocation);
        DetailOrderUpdateAddressRestService.getPostalCode({ name: props.address.address2, cityCode: getCityCode })
          .then((code) => {
            const mappedZip = code.map(c => ({ ...c, label: c.postalCode, value: c.postalCode }));

            setDataZip(mappedZip);
            setLoadingModal(false);
          })
          .catch(err => {
            setFailMessage(MessageParserService.parseSpesific(err));
            setFailToast(true);
          })
          .finally(() => setLoadingModal(false));
      })
      .catch(err => {
        setFailMessage(MessageParserService.parseSpesific(err));
        setFailToast(true);
      })
      .finally(() => setLoadingModal(false));
  }, []);

  useEffect(() => {
    if (address2 === props.address.address2) {
      return;
    }

    const delayBounceFn = setTimeout(() => {
      if (address2 && address2.length && address2.length > 2) {
        setLoadingAddress2(true);

        DetailOrderUpdateAddressRestService.getLocation({ name: address2, type: 'subdistrict' })
          .then((location) => {
            const mappedLocation = location.map(loc => ({ ...loc, label: `${loc.subdistrict} - ${loc.city}`, value: `${loc.subdistrict} - ${loc.city}` }));

            setOptionsAddress2(mappedLocation);
            setLoadingAddress2(false);
          })
          .catch(err => {
            setFailMessage(MessageParserService.parseSpesific(err));
            setFailToast(true);
          })
          .finally(() => setLoadingModal(false));
      }
    }, 1000);

    return () => clearTimeout(delayBounceFn);
  }, [address2]);

  return (
    <Translation>
      {t => (
        <>
          {loadingModal && (
            <>
              <div
                style={{
                  zIndex: 1000,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  top: 0,
                  left: 0,
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'black',
                  opacity: 0.5,
                  borderBottomLeftRadius: '8px',
                  borderBottomRightRadius: '8px'
                }}
              >
                <Spinner accessibilityLabel="Loading..." size="large" />
              </div>
            </>
          )}
          <Form onSubmit={handleSubmit}>
            <FormLayout>
              <TextField
                autoComplete='off'
                value={address1}
                onChange={updateAddress}
                label={`${t('order.detail.modal.form.address')}`}
                type="text"
                multiline={3}
              />

              <div className="row">
                <div className="col-md-6">
                  <Autocomplete
                    options={optionsAddress2}
                    selected={selectedAddress2}
                    loading={loadingAddress2}
                    onSelect={updateSelectionAddress2}
                    textField={textFieldAddress2(t)}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    autoComplete='off'
                    label={t('order.detail.modal.form.province')}
                    value={province}
                    ariaExpanded={false}
                    placeholder={`${t('order.detail.modal.form.province')}`}
                    readOnly
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <TextField
                    autoComplete='off'
                    label={t('order.detail.modal.form.city')}
                    value={city}
                    ariaExpanded={false}
                    placeholder={`${t('order.detail.modal.form.city')}`}
                    readOnly
                  />
                </div>
                <div className="col-md-6">
                  {loadingZipComponent && (
                    <>
                      <div
                        style={{
                          zIndex: 1000,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          top: 0,
                          left: 0,
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'black',
                          opacity: 0.5,
                        }}
                      >
                        <Spinner accessibilityLabel="Loading..." size="large" />
                      </div>
                    </>
                  )}
                  <Autocomplete
                    options={optionsZip}
                    selected={selectedZip}
                    onSelect={updateSelectionZip}
                    textField={textFieldZip(t)}
                  />
                </div>
              </div>

              <div className="text-right">
                <Button disabled={disabledUpdate} submit>Update</Button>
              </div>
            </FormLayout>
          </Form>

          {failToast && (
            <Frame>
              <Toast
                error
                content={failMessage}
                onDismiss={() => setFailToast(false)}
                duration={10000}
              />
            </Frame>
          )}
        </>
      )}
    </Translation>
  );
}

export default DetailOrderUpdateAddressFormComponent;
