import { onCLS, onFCP, onFID, onLCP, onTTFB } from 'web-vitals';

const handleMetric = (metric) => {
  console.log(metric);
};

onCLS(handleMetric);
onFID(handleMetric);
onLCP(handleMetric);
onFCP(handleMetric);
onTTFB(handleMetric);
