import React from "react";
import { AboutUsCards } from "./cards/about-us-card";
import "./../styles.scss"
import MonthlySubscription from './../../../assets/img/about/subscription/monthly-subscription.svg';
import AnnualSubscription from './../../../assets/img/about/subscription/annual-subscription.svg';
import CustomSubscription from './../../../assets/img/about/subscription/custom-subscription.svg';
import { withRouter } from 'react-router-dom';
import { AboutUsRestService } from '../about-us-rest.service';
import { Frame, Toast } from "@shopify/polaris";
import { MessageParserService } from "./../../../libraries/common/message-parser.service";

declare interface IResponsePostSubscription {
    errors: any;
    success: boolean;
    data: IResponsePostSubscriptionData
}

declare interface IResponsePostSubscriptionData {
    redirectUrl: string;
}

class SubscriptionContent extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            showToast: false,
            isLoading: [
                false,
                false,
                false
            ]
        };
    }


    private subscriptionCardsData = [
        {
            subscriptionBadgeType: "monthly",
            subscriptionIcon: MonthlySubscription,
            subscriptionTitle: "Monthly Subscription Plan",
            subscriptionButtonText: "Berlangganan",
            subscriptionPaid: 12.82,
            subscriptionAvailableFeatures: [
                "Biaya Plan: $12.82 / Bulan",
                "Limit Transaksi : 400 Order Pickup / Bulan",
                "Biaya Over Limit Transaksi Dalam 1 Bulan : $ 0.03 / Order",
                "Fitur Cek Ongkir",
                "Fitur Create Order",
                "Fitur Konfigurasi (Setup Diskon Ongkir/Biaya Insurance)",
                "Fitur Manage Order (Pickup/Print Label Shipping/Dll)"
            ]
        },
        {
            subscriptionBadgeType: "annual",
            subscriptionIcon: AnnualSubscription,
            subscriptionTitle: "Annual Subscription Plan",
            subscriptionButtonText: "Berlangganan",
            subscriptionPaid: 128.2,
            additionalCrossOutPaid: 153.84,
            subscriptionAvailableFeatures: [
                "Biaya Plan: <span class='subscription-cross-out'>$153.84</span> <br /> $128.2/Thn",
                "Biaya Over Limit Transaksi Dalam 1 Bulan : $ 0.03 / Order",
                "Fitur Cek Ongkir",
                "Fitur Create Order",
                "Fitur Konfigurasi (Setup Diskon Ongkir/Biaya Insurance)",
                "Fitur Manage Order (Pickup/ Print label shipping/dll)",
            ]
        },
        {
            subscriptionBadgeType: "custom",
            subscriptionIcon: CustomSubscription,
            subscriptionTitle: "Custom Subscription Plan",
            subscriptionButtonText: "Kontak Sales",
            subscriptionPaid: 0,
            subscriptionAvailableFeatures: [
                "Biaya Plan per periode ( bulanan/tahunan )",
                "Minimal transaksi 1000 order per bulan untuk mengaktifkan plan ini",
                "Limit transaksi per bulan",
                "Biaya over limit transaksi per bulan",
                "Fitur Cek ongkir",
                "Fitur Create Order",
                "Fitur Konfigurasi ( Setup diskon ongkir/biaya insurance )",
                "Fitur Manage Order ( Pickup/ Print label shipping/dll)"
            ]
        }
    ]

    private handleSubscriptionBtnClick(subscriptionPlanType, loadingKey) {
        const { appContext } = this.props;
        if (appContext && appContext.hostOrigin) {
            const domain = appContext?.hostOrigin.replace(/^https:\/\//, '');
            const isLoadings = this.state.isLoading;
            isLoadings[loadingKey] = true;
            this.setState({ isLoading: isLoadings })
            AboutUsRestService.postSubscriptionPlan({ category: subscriptionPlanType, shop: domain })
                .then((response: IResponsePostSubscription) => {
                    if (response?.data) {
                        window.open(response?.data?.redirectUrl);
                    }
                })
                .catch(err => {
                    this.setState({
                        showToast: true,
                        toastValue: {
                            content: MessageParserService.parseSpesific(err),
                            error: true
                        },
                    });
                })
                .finally(() => {
                    // must be loading remove in here if there is loading or notifications
                    isLoadings[loadingKey] = false;
                    this.setState({ isLoading: isLoadings })
                })
            // AxiosHandle url passing by Params in UrlLink
            // this.props.history.push("");
        }
    }


    closeToast() {
        this.setState({ showToast: false });
    }

    render() {
        return (
            <>
                <div className="subscription-content">
                    <h2 className="text-center ">Shopify shipdeo subcription type</h2>
                    <div className="row">
                        {this.subscriptionCardsData.map((value, key) => (
                            <div className="col-12 col-lg-4 mb-2" key={key}>
                                <AboutUsCards
                                    subscriptionButtonText={value.subscriptionButtonText}
                                    subscriptionBadgeType={value.subscriptionBadgeType}
                                    subscriptionIcon={value.subscriptionIcon}
                                    subscriptionTitle={value.subscriptionTitle}
                                    subscriptionAvailableFeatures={value.subscriptionAvailableFeatures}
                                    subscriptionPaid={value.subscriptionPaid}
                                    additionalCrossOutPaid={value.additionalCrossOutPaid}
                                    subscriptionOnSubscribeClick={(subscriptionPlayType) => this.handleSubscriptionBtnClick(subscriptionPlayType, key)}
                                    loading={this.state.isLoading[key]}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                {this.state.showToast && (
                    <div className="polaris-show-toast">
                        {/* TODO: Create base toast */}
                        <Frame>
                            <Toast
                                content={this.state.toastValue.content}
                                duration={this.state.toastValue.duration}
                                onDismiss={() => {
                                    this.closeToast();
                                }}
                                error={this.state.toastValue.error}
                            />
                        </Frame>
                    </div>
                )}
            </>
        )
    }
}

export default withRouter(SubscriptionContent)