import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Explanation from '../../assets/img/about/about-explanation.png';
import Banner from '../../assets/img/about/about-banner.png';

import ArrowRightWhite from '../../assets/img/about/arrow-right-white.svg';
import { Button, Icon, Layout, Page } from '@shopify/polaris';
import { Translation } from 'react-i18next';
import './styles.scss';
import { AsideAbout } from './components/aside-about';
import SubscriptionContent from './components/subscription-content';
import { useAppBridge } from "@shopify/app-bridge-react"; // Use the renamed Context
import { AppContext } from '../../store/app-context';


export const AboutPage = () => {
  const appBridgeContext = useAppBridge();
  const { state } = useContext(AppContext);
  const [isShowPlan, setIsShowPlan] = useState(true);

  useEffect(() => {
    if (state.subscriptionData) {
      const { status, payment } = state.subscriptionData;
      if (status === "active" && payment === 'paid') {
        setIsShowPlan(false);
      }
    }
  }, [state])

  return (
    <>
      <head>
        <title>About Shipdeo</title>
      </head>

      <Translation>
        {(t) => (
          <Page fullWidth>
            <Layout>
              <Layout.Section>
                <div className="row px-4 py-3">
                  <div className="col-8 col-md-9 col-lg-9">
                    {/* <img
                        src={Explanation}
                        alt="About Shipdeo"
                        className="w-100 img-explanation"
                      /> */}

                    <div className="content-explanation py-3 px-3 py-lg-5 px-lg-5">
                      <h1 className="title">
                        Apa itu <span className="text-primary-about">Shipdeo</span>?
                      </h1>

                      <p className="description my-2">
                        Dengan menggunakan Shipdeo, memudahkan pemesanan
                        pengiriman paket dengan detail pengiriman yang mudah
                        dimasukkan. Anda dapat memilih dari berbagai mitra
                        kurir dan layanan pengiriman, serta melacak paket
                        secara real-time. Shipdeo juga menawarkan integrasi
                        dengan e-commerce dan fitur manajemen pengiriman yang
                        efisien.
                      </p>

                      <NavLink
                        to={{
                          pathname: 'https://apps.shopify.com/shipdeo-dev',
                          search:
                            'search_id=d19e9e4a-ffd9-4dc3-8c8d-a9e75054b44e&surface_detail=shipdeo&surface_inter_position=1&surface_intra_position=4&surface_type=search',
                        }}
                        target="_blank"
                      >
                        <button className="cta-about">
                          <div className="d-flex align-items-center">
                            <span className="mr-1">Lanjut ke Shipdeo</span>{' '}
                            <img src={ArrowRightWhite} alt="Arrow Right" />
                          </div>
                        </button>
                      </NavLink>
                    </div>
                    {isShowPlan && (
                      <div className="subscription-section mb-4">
                        <SubscriptionContent appContext={appBridgeContext} />
                      </div>
                    )}

                    <div className="about-us-banner-section">
                      <img
                        src={Banner}
                        alt="Banner About"
                        className="w-100 pt-3 img-banner"
                      />
                    </div>
                  </div>

                  <div className="col-4 col-md-3 col-lg-3">
                    <AsideAbout isShowPlan={isShowPlan}/>
                  </div>
                </div>
              </Layout.Section>
            </Layout>
          </Page>
        )}
      </Translation>
    </>
  );
}
