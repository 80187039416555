import React from 'react';
import {
  Button,
  Card,
  Checkbox,
  ChoiceList,
  Form,
  FormLayout,
  Frame,
  Icon,
  InlineError,
  Layout,
  RadioButton,
  Spinner,
  Stack,
  TextField
} from '@shopify/polaris';
import _ from 'lodash';
import { Translation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Field, Form as ReactFinalForm } from 'react-final-form';
import { toast } from 'react-toastify';
import listAddress from '../../../assets/data-dummy/alamat-shopify.json';
import { SearchMinor } from '@shopify/polaris-icons';
import { ShippingRateRestService } from '../shipping-rate-rest.service';
import { MessageParserService } from '../../../libraries/common/message-parser.service';
import './shipping-rate-form.component.scss';

// karena ada initial state yang sifatnya dynamic, jadinya config state ini ga begitu di pake
export class ShippingRateFormState {
  // calculatePrice: boolean = false;
  calculateWeight: boolean = false;
  choiceAndOr: string = '';
  conditionPrice: boolean = false;
  conditionWeight: boolean = false;
  discountType: boolean = false
  percentageValue: any;
  setErrorConditionPriceStart: boolean = false;
  setErrorConditionWeightStart: boolean = false;
  setErrorDiscountType: boolean = false;
  setErrorDiscountTypeValue: boolean = false;
  setErrorProvince: boolean = false;
  setErrorService: boolean = false;
  setErrorZoneName: boolean = false;
  setCondition: boolean = false;
  selectedProvince2: any = {};
  // selectedSearchProvince2: any = {};
  selectedProvince: any = [];
  // selectedService: any = [];
  selectedDiscountType: any = [];
  // selectedConditionType: any = [];
  shippingRateData: any = {};
  // selectedListAddress: any;
}

class ShippingRateFormComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      shippingRateData: {},
      toastConfig: {
        position: 'top-right',
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      },
      selectAllProvince: false
    };
  }

  componentWillMount() {
    listAddress.map(province => province.data.map((city: any) => {
      delete city.isChecked;
      return city;
    }));

    this.setState({
      calculateWeight: false,
      // calculatePrice: false,
      choiceAndOr: 'and',
      setErrorConditionPriceStart: false,
      setErrorConditionWeightStart: false,
      setErrorDiscountType: false,
      setErrorDiscountTypeValue: false,
      setErrorProvince: false,
      setErrorService: false,
      setErrorZoneName: false,
      allListAddress: listAddress,
      listAddress,
      listService: [],
      // selectedService: [],
      selectedDiscountType: [],
      selectedProvince2: {},
      // selectedSearchProvince2: {},
      selectedProvince: [],
      // selectedConditionType: [],
    });
    // _.forEach(listAddress, (province, id) => {
    //   _.forEach(province.data, (city, idx) => {
    //     this.setState({
    //       [`selectedCity${city.cityCode}`]: [],
    //     });
    //   });
    // });
  }

  componentDidMount() {

    this.setState({ isLoading: true });

    ShippingRateRestService.getService(null)
      .then(responseService => {
        if (responseService.success) {
          const listService = responseService.data.map(service => {
            return service.services.map(s => ({ courierCode: service.courierCode, service: s }));
          });

          this.setState({ listService: _.flattenDeep(listService) });

          if (this.props.formType === 'update') {
            ShippingRateRestService.getCustomShippingRate(location.pathname.split('/')[3])
              .then(response => {
                // checked province
                const listAddressSelected = this.state.listAddress.map(prov => {
                  return {
                    ...prov,
                    data: prov.data.map((c: any) => {
                      const findIdx = response.data.selectedArea.findIndex(v => v.cityCode === c.cityCode);
                      if (findIdx !== -1) {
                        c.isChecked = response.data.selectedArea[findIdx].isChecked;
                      }
                      return c;
                    })
                  }
                });

                // checked service
                const listServiceSelected = _.flattenDeep(listService).map((s: any, i: number) => {
                  const findIdx = response.data.serviceActivated.findIndex(v => v.service === s.service && v.courierCode === s.courierCode);
                  if (findIdx !== -1) {
                    s.isChecked = response.data.serviceActivated[findIdx].isChecked;
                  }
                  return s;
                });

                // start patch to form
                this.setState({
                  listAddress: listAddressSelected,
                  listService: listServiceSelected,
                  discountType: response.data.typeDiscountRate.type ? true : false,
                  selectedDiscountType: [response.data.typeDiscountRate.type],
                  choiceAndOr: response.data.conditionDiscount.type || 'and',
                  // calculatePrice: response.data.conditionDiscount.basedOnPrice.countRemainder,
                  calculateWeight: response.data.conditionDiscount.basedOnWeight.countRemainder,
                  setCondition: ((response.data.conditionDiscount.basedOnWeight.startValue || response.data.conditionDiscount.basedOnWeight.endValue) || (response.data.conditionDiscount.basedOnPrice.startValue || response.data.conditionDiscount.basedOnPrice.endValue)) ? true : false,
                  conditionWeight: response.data.conditionDiscount.basedOnWeight.startValue || response.data.conditionDiscount.basedOnWeight.endValue ? true : false,
                  conditionPrice: response.data.conditionDiscount.basedOnPrice.startValue || response.data.conditionDiscount.basedOnPrice.endValue ? true : false,
                  shippingRateData: {
                    zoneName: response.data.zoneName,
                    conditionDiscount: {
                      basedOnWeight: {
                        startValue: response.data.conditionDiscount?.basedOnWeight?.startValue?.toString() || '',
                        endValue: response.data.conditionDiscount?.basedOnWeight?.endValue?.toString() || ''
                      },
                      basedOnPrice: {
                        startValue: response.data.conditionDiscount?.basedOnPrice?.startValue?.toString() || '',
                        endValue: response.data.conditionDiscount?.basedOnPrice?.endValue?.toString() || ''
                      }
                    },
                    typeDiscountRate: {
                      value: response.data.typeDiscountRate?.value?.toString(),
                      maxDiscountAmount: response.data.typeDiscountRate.type === 'percentage' ? response.data.typeDiscountRate?.maxDiscountAmount?.toString() : ''
                    }
                  }
                })
              });
          }
        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  componentWillUnmount() {
    this.state.listAddress.map(province => province.data.map(city => {
      delete city.isChecked;
      return city;
    }));
  }

  handleSubmit(e): any {
    // initial variable
    const selectedArea = [];
    for (let iProvince = 0; iProvince < this.state.allListAddress.length; iProvince++) {
      const filterCity = this.state.allListAddress[iProvince].data.filter(city => _.has(city, 'isChecked') && city.isChecked);
      if (filterCity && filterCity.length) {
        selectedArea.push(filterCity);
      }
    }
    const serviceActivated = this.state.listService.filter(service => _.has(service, 'isChecked') && service.isChecked);
    // end

    // start handle trigger required
    if (!e?.zoneName) {
      this.setState({ setErrorZoneName: true });
    }

    if (!_.flattenDeep(selectedArea).length) {
      this.setState({ setErrorProvince: true });
    }

    if (!serviceActivated.length) {
      this.setState({ setErrorService: true });
    }

    if (this.state.discountType && !this.state.selectedDiscountType[0]) {
      this.setState({ setErrorDiscountType: true });
    }

    if (this.state.discountType && this.state.selectedDiscountType[0] && !e?.typeDiscountRate?.value) {
      this.setState({ setErrorDiscountTypeValue: true });
    }

    if (this.state.setCondition && this.state.conditionWeight && !e?.conditionDiscount?.basedOnWeight?.startValue) {
      this.setState({ setErrorConditionWeightStart: true });
    }

    if (this.state.setCondition && this.state.conditionPrice && !e?.conditionDiscount?.basedOnPrice?.startValue) {
      this.setState({ setErrorConditionPriceStart: true });
    }
    // end handle trigger required

    setTimeout(() => {
      const listError = [this.state.setErrorZoneName, this.state.setErrorProvince, this.state.setErrorService, this.state.setErrorDiscountType, this.state.setErrorDiscountTypeValue, this.state.setErrorConditionWeightStart, this.state.setErrorConditionPriceStart];

      if (listError.includes(true)) {
        return toast.error(<span>Silahkan periksa kembali field yang masih kosong</span>, this.state.toastConfig);
      } else {
        // normalize value
        const normalizeValue = {
          zoneName: e.zoneName,
          selectedArea: _.flattenDeep(selectedArea),
          serviceActivated,
          typeDiscountRate: {
            type: this.state.discountType && this.state.selectedDiscountType[0] ? this.state.selectedDiscountType[0] : null,
            value: this.state.discountType && this.state.selectedDiscountType[0] ? e.typeDiscountRate.value : '0',
            maxDiscountAmount: this.state.discountType && this.state.selectedDiscountType[0] === 'percentage' ? e.typeDiscountRate.maxDiscountAmount : '0'
          },
          conditionDiscount: {
            type: this.state.conditionWeight && this.state.conditionPrice ? this.state.choiceAndOr : null,
            basedOnWeight: {
              startValue: this.state.setCondition && this.state.conditionWeight ? e.conditionDiscount.basedOnWeight.startValue : null,
              endValue: this.state.setCondition && this.state.conditionWeight ? e.conditionDiscount.basedOnWeight.endValue : null,
              countRemainder: this.state.setCondition && this.state.conditionWeight ? this.state.calculateWeight : false
            },
            basedOnPrice: {
              startValue: this.state.setCondition && this.state.conditionPrice ? e.conditionDiscount.basedOnPrice.startValue : null,
              endValue: this.state.setCondition && this.state.conditionPrice ? e.conditionDiscount.basedOnPrice.endValue : null,
              // countRemainder: this.state.setCondition && this.state.conditionPrice && this.state.calculatePrice ? this.state.calculatePrice : false
            }
          }
        }

        // handle when basedOnWeight.startValue is higher than basedOnWeight.endValue
        if (normalizeValue.conditionDiscount.basedOnWeight.endValue) {
          if (parseInt(normalizeValue.conditionDiscount.basedOnWeight.startValue) > parseInt(normalizeValue.conditionDiscount.basedOnWeight.endValue)) {
            return toast.error(<span>Berat maksimal tidak bisa lebih kecil dari berat minimal barang</span>, this.state.toastConfig);
          }

          if (parseInt(normalizeValue.conditionDiscount.basedOnWeight.startValue) === parseInt(normalizeValue.conditionDiscount.basedOnWeight.endValue)) {
            return toast.error(<span>Kondisi berat barang minimal dan maksimal tidak boleh 0</span>, this.state.toastConfig);
          }
        }

        // handle when basedOnPrice.startValue is higher than basedOnPrice.endValue
        if (normalizeValue.conditionDiscount.basedOnPrice.endValue) {
          if (parseInt(normalizeValue.conditionDiscount.basedOnPrice.startValue) > parseInt(normalizeValue.conditionDiscount.basedOnPrice.endValue)) {
            return toast.error(<span>Harga maksimal tidak bisa lebih kecil dari harga minimal barang</span>, this.state.toastConfig);
          }
        }

        // start create
        this.setState({ isLoading: true });
        if (this.props.formType === 'create') {
          ShippingRateRestService.addCustomShippingRate(normalizeValue)
            .then(res => {
              if (res.success) {
                // delete property isChecked on listAddress
                this.state.listAddress.map(province => province.data.map(city => {
                  delete city.isChecked;
                  return city;
                }));
                this.setState({
                  isLoading: false,
                  listAddress: [],
                  selectedProvince2: {}
                });

                toast.success(<span>Custom shipping rate berhasil di buat</span>, this.state.toastConfig);

                // this.props.history.push('/v2/config');
                this.props.history.push('/config');
              }
            })
            .catch(err => {
              if (err.response.status === 400) {
                toast.error(<span>{err.response.data.errors[0].detail.response.error}</span>, this.state.toastConfig);
              } else {
                toast.error(<span>{MessageParserService.parseSpesific(err)}</span>, this.state.toastConfig);
              }

              this.setState({ isLoading: false });
            });
        }
        // end

        // start update
        if (this.props.formType === 'update') {
          ShippingRateRestService.updateCustomShippingRate(location.pathname.split('/')[3], normalizeValue)
            .then(res => {
              if (res.success) {
                // delete property isChecked on listAddress
                this.state.listAddress.map(province => province.data.map(city => {
                  delete city.isChecked;
                  return city;
                }));

                this.setState({
                  isLoading: false,
                  listAddress: [],
                  selectedProvince2: {},
                });

                toast.success(<span>Custom shipping rate berhasil di ubah</span>, this.state.toastConfig);

                this.props.history.push('/config');
              }
            })
            .catch(err => {
              if (err.response.status === 400) {
                toast.error(<span>{err.response.data.errors[0].detail.response.error}</span>, this.state.toastConfig);
              } else {
                toast.error(<span>{MessageParserService.parseSpesific(err)}</span>, this.state.toastConfig);
              }

              this.setState({ isLoading: false });
            });
        }
        // end
      }
    }, 0);

    // // start handle province / city
    // if (!_.flattenDeep(selectedArea).length) {
    //   return this.setState({
    //     showToast: true,
    //     toastValue: {
    //       duration: 3000,
    //       content: 'Silahkan pilih provinsi / kota',
    //       error: true
    //     }
    //   });
    // }
    // // end handle province / city

    // // start handle service
    // if (!serviceActivated.length) {
    //   return this.setState({
    //     showToast: true,
    //     toastValue: {
    //       duration: 3000,
    //       content: 'Silahkan pilih service',
    //       error: true
    //     }
    //   });
    // }
    // // end handle service

    // // start handle discounttype
    // if (this.state.discountType && !this.state.selectedDiscountType[0]) {
    //   return this.setState({
    //     showToast: true,
    //     toastValue: {
    //       duration: 3000,
    //       content: 'Silahkan pilih tipe diskon',
    //       error: true
    //     }
    //   });
    // }
    // // end handle discounttype
  }

  renderFixDiscount(isSelected) {
    if (isSelected) {
      return (
        <div className="row">
          <div className="col-md-6 col-12">
            <Field name="typeDiscountRate.value" render={({ meta, input }) => (
              <>
                <TextField
                  {...input as any}
                  type="number"
                  placeholder="Value"
                  onChange={e => {
                    input.onChange(e);
                    const shippingRateData = this.state.shippingRateData;
                    shippingRateData.typeDiscountRate = {
                      ...shippingRateData.typeDiscountRate,
                      value: e,
                    };
                    if (isSelected && e.length === 1) {
                      this.setState({ setErrorDiscountTypeValue: false });
                    } else if (isSelected && e.length === 0) {
                      this.setState({ setErrorDiscountTypeValue: true });
                    }
                    setTimeout(() => {
                      this.setState({ shippingRateData });
                    }, 0);
                  }}
                />
                <div className="mt-3">
                  {this.state.setErrorDiscountTypeValue && (
                    <InlineError message="Required" fieldID="typeDiscountRate.value" />
                  )}
                </div>
              </>
            )} />
          </div>
        </div>
      )
    }
  }

  renderFixedAmount(isSelected) {
    if (isSelected) {
      return (
        <div className="row">
          <div className="col-md-6 col-12">
            <Field name="typeDiscountRate.value" render={({ input, meta }) => (
              <>
                <TextField
                  {...input as any}
                  type="number"
                  placeholder="Value"
                  onChange={e => {
                    input.onChange(e);
                    const shippingRateData = this.state.shippingRateData;
                    shippingRateData.typeDiscountRate = {
                      ...shippingRateData.typeDiscountRate,
                      value: e,
                    };
                    if (isSelected && e.length === 1) {
                      this.setState({ setErrorDiscountTypeValue: false });
                    } else if (isSelected && e.length === 0) {
                      this.setState({ setErrorDiscountTypeValue: true });
                    }
                    setTimeout(() => {
                      this.setState({ shippingRateData });
                    }, 0);
                  }}
                />
                <div className="mt-3">
                  {this.state.setErrorDiscountTypeValue && (
                    <InlineError message="Required" fieldID="typeDiscountRate.value" />
                  )}
                </div>
              </>
            )} />
          </div>
        </div>
      )
    }
  }

  renderPercentage(isSelected) {
    if (isSelected) {
      return (
        <div className="row">
          <div className="col-md-5 col-12">
            <Field name="typeDiscountRate.value" render={({ input, meta }) => (
              <>
                <TextField
                  {...input as any}
                  type="number"
                  placeholder="Value"
                  max={100}
                  suffix="%"
                  onChange={e => {
                    input.onChange(e);
                    const shippingRateData = this.state.shippingRateData;
                    shippingRateData.typeDiscountRate = {
                      value: e
                    };
                    if (isSelected && e.length === 1) {
                      this.setState({ setErrorDiscountTypeValue: false });
                    } else if (isSelected && e.length === 0) {
                      this.setState({ setErrorDiscountTypeValue: true });
                    }
                    setTimeout(() => {
                      this.setState({ shippingRateData });
                    }, 0);
                  }}
                />
                <div className="mt-3">
                  {this.state.setErrorDiscountTypeValue && (
                    <InlineError message="Required" fieldID="typeDiscountRate.value" />
                  )}
                </div>
              </>
            )} />
          </div>

          <div className="col-md-1 d-flex justify-content-end align-items-center">
            Max
          </div>

          <div className="col-md-6 col-12">
            <Field name="typeDiscountRate.maxDiscountAmount" render={({ input, meta }) => (
              <TextField
                {...input as any}
                type="number"
                placeholder="No Limit"
                prefix="IDR"
                onChange={e => {
                  input.onChange(e);
                  const shippingRateData = this.state.shippingRateData;
                  shippingRateData.typeDiscountRate = {
                    ...shippingRateData.typeDiscountRate,
                    maxDiscountAmount: e
                  };
                  setTimeout(() => {
                    this.setState({ shippingRateData });
                  }, 0);
                }}
              />
            )} />
          </div>
        </div>
      )
    }
  }

  removeCollapseShow(getProvince) {
    for (let i = 0; i < getProvince.length; i++) {
      const findClassShow = document.getElementById(`collapse${getProvince[i].provinceCode}`);
      const getClassCollapsed = document.getElementsByClassName('w-75')[i];
      if (findClassShow?.classList?.contains('show')) {
        findClassShow?.classList?.remove('show');
      }

      if (!getClassCollapsed?.classList?.contains('collapsed')) {
        getClassCollapsed?.classList.add('collapsed');
      }
    }
  }

  handleChangeSearch(input, value) {
    input.onChange(value);

    const getProvince = listAddress.filter(province => province.provinceName.toLowerCase().includes(value.toLowerCase()));
    if (getProvince.length) {
      this.removeCollapseShow(getProvince);
      this.setState({ listAddress: getProvince });
    } else {
      const filtered = listAddress.map(province => {
        return {
          ...province,
          data: province.data.filter(city => {
            return city.cityName.toLowerCase().includes(value.toLowerCase());
          })
        }
      });
      const filterEmptyCity = filtered.filter(province => province.data.length);
      this.removeCollapseShow(filterEmptyCity);
      this.setState({ listAddress: filterEmptyCity });
    }
  }

  handleSelectedProvince(value, province, provinceIndex) {
    for (let index = 0; index < province.data.length; index++) {
      this.handleSelectedCity(value, province.data[index], provinceIndex, index);
    }
    // if((_.keys(_.get(this.state.selectedProvince2, province.provinceName) || {})).length !== province.data.length) {
    //     this.setState({
    //       selectedProvince2: {
    //         ...this.state.selectedProvince2,
    //         [_.camelCase(province.provinceName)]: _.chain(province.data)
    //         .keyBy('cityName')
    //         .value()
    //       },
    //     });
    //   // this.state.selectedProvince2[_.camelCase(province.provinceName)] = province.data;
    // } else {
    //   this.setState({
    //     selectedProvince2: {
    //       ...this.state.selectedProvince2,
    //       [_.camelCase(province.provinceName)]: {}
    //     },
    //   });
    // }
    // const selectedProvince = this.state.selectedProvince;
    // if (e) {
    //   selectedProvince.push(province);
    //   // check all on city / subdistrict
    //   selectedProvince.map(province => {
    //     province.data.map(city => {
    //       this.setState({ [city.cityCode]: e });
    //     });
    //   });
    //   this.setState({
    //     [province.provinceCode]: e,
    //     selectedProvince
    //   });
    // } else {
    //   const index = selectedProvince.indexOf(province);
    //   if (index !== -1) {
    //     // uncheck all on city / subdistrict
    //     selectedProvince.map(province => {
    //       province.data.map(city => {
    //         this.setState({ [city.cityCode]: e });
    //       });
    //     });
    //     selectedProvince.splice(index, 1);
    //     this.setState({
    //       [province.provinceCode]: e,
    //       selectedProvince
    //     });
    //   }
    // }
  }

  handleSelectedCity(value, city, provinceIndex, cityIndex) {
    city.isChecked = value;
    this.state.listAddress[provinceIndex].data[cityIndex].isChecked = value;
    this.state.selectAllProvince

    // used for check error required
    const selectedArea = [];
    for (let iProvince = 0; iProvince < this.state.listAddress.length; iProvince++) {
      const filterCity = this.state.listAddress[iProvince].data.filter(city => _.has(city, 'isChecked') && city.isChecked);
      if (filterCity && filterCity.length) {
        selectedArea.push(filterCity);
      }
    }

    if (_.flattenDeep(selectedArea).length) {
      this.setState({ setErrorProvince: false });
    } else {
      this.setState({ setErrorProvince: true });
    }
    // end

    const listAddress = this.state.listAddress
    const checkedArea = (selectedArea?.length === listAddress?.length)
      ? selectedArea.map((s, i) => (s.length === listAddress[i].data?.length)
        ? true
        : false)
      : false

    this.setState({
      selectAllProvince: typeof checkedArea === 'boolean' ? checkedArea : checkedArea.includes(false) ? false : true,
      selectedProvince2: {
        ...this.state.selectedProvince2,
        [_.camelCase(this.state.listAddress[provinceIndex].provinceName)]: {
          ...(this.state.selectedProvince2[_.camelCase(this.state.listAddress[provinceIndex].provinceName)] || {}),
          [_.camelCase(city.cityName)]: city
        }
      }
    });
    // setTimeout(() => {
    //   console.log(this.state)
    // }, 500);
  }

  checkParent(province?) {
    const checkedProvinces = _.filter(province.data, ['isChecked', true]);
    return { isChecked: checkedProvinces?.length === province.data?.length, checkedLength: checkedProvinces?.length };
  }

  handleChangeService(value, service, idService) {
    service.isChecked = value;
    this.state.listService[idService].isChecked = value;
    // handle trigger error
    const filterChecked = this.state.listService.filter(e => e.isChecked);
    if (filterChecked.length) {
      this.setState({ setErrorService: false });
    } else {
      this.setState({ setErrorService: true });
    }
    // end

    this.setState({ listService: this.state.listService });
  }

  formatMoney(money) {
    const formatter = new Intl.NumberFormat('id', {
      style: 'currency',
      currency: 'IDR'
    });

    return formatter.format(money);
  }

  // required(value) {
  //   return value ? undefined : 'Required';
  // }

  handleSelectAllProvince(value) {
    this.state.listAddress.map((province, provinceIndex) => province.data.map((city, cityIndex) => this.state.listAddress[provinceIndex].data[cityIndex].isChecked = value))

    this.setState({
      selectAllProvince: value
    })
  }

  _renderSelectedCity() {
    const selectedArea = [];
    for (let iProvince = 0; iProvince < this.state.listAddress.length; iProvince++) {
      const filterCity = this.state.listAddress[iProvince].data.filter(city => _.has(city, 'isChecked') && city.isChecked);
      if (filterCity && filterCity.length) {
        selectedArea.push(filterCity);
      }
    }

    return _.flattenDeep(selectedArea);
  }

  _renderSelectedService() {
    const serviceActivated = this.state.listService.filter(service => _.has(service, 'isChecked') && service.isChecked);
    return serviceActivated;
  }

  _renderDiscount() {
    let discount;
    const shippingRateData = this.state.shippingRateData;
    if (this.state.discountType) {
      switch (this.state.selectedDiscountType[0]) {
        case 'percentage':
          discount = `diskon ${shippingRateData?.typeDiscountRate?.value || 0}% ${shippingRateData?.typeDiscountRate?.maxDiscountAmount ? `max ${this.formatMoney(shippingRateData?.typeDiscountRate?.maxDiscountAmount)}` : ''}`;
          break;
        case 'fixDiscount':
          discount = `diskon ${this.formatMoney(shippingRateData?.typeDiscountRate?.value || 0)}`;
          break;
        case 'fixedAmount':
          discount = `ongkos kirim menjadi ${shippingRateData?.typeDiscountRate?.value ? this.formatMoney(shippingRateData?.typeDiscountRate?.value) : this.formatMoney(0)}`;
          break;
      }
    }

    return discount;
  }

  _renderCondition() {
    let condition;
    const shippingRateData = this.state.shippingRateData;
    if (this.state.setCondition) {
      condition = ' dalam kondisi';
      if (this.state.conditionWeight) {
        condition = condition + ` berat ${shippingRateData?.conditionDiscount?.basedOnWeight?.startValue && !shippingRateData?.conditionDiscount?.basedOnWeight?.endValue ? 'lebih dari sama dengan' : ''} ${shippingRateData?.conditionDiscount?.basedOnWeight?.startValue || 0} Kg ${shippingRateData?.conditionDiscount?.basedOnWeight?.endValue ? `- ${shippingRateData?.conditionDiscount?.basedOnWeight?.endValue} Kg` : ''}`;
      }

      if (this.state.conditionWeight && this.state.conditionPrice) {
        if (this.state.choiceAndOr === 'and') {
          condition = condition + ` dan`;
        } else {
          condition = condition + ` atau`;
        }
      }

      if (this.state.conditionPrice) {
        condition = condition + ` harga ${shippingRateData?.conditionDiscount?.basedOnPrice?.startValue && !shippingRateData?.conditionDiscount?.basedOnPrice?.endValue ? '≥' : ''}${shippingRateData?.conditionDiscount?.basedOnPrice?.startValue ? this.formatMoney(shippingRateData?.conditionDiscount?.basedOnPrice?.startValue) : this.formatMoney(0)} ${shippingRateData?.conditionDiscount?.basedOnPrice?.endValue ? `- ${this.formatMoney(shippingRateData?.conditionDiscount?.basedOnPrice?.endValue)}` : ''}`
      }
    }

    return condition;
  }

  // renderConditionWeight(isSelected, t) {
  //   if (isSelected) {
  //     return (
  //       <>
  //         <div className="row">
  //           <div className="col-md-4 col-12">
  //             <Field name="conditionWeightValue" component={FinalFormPolarisAdapter.textField} type="number" max={100} suffix="Kg" />
  //           </div>

  //           <div className="col-md-1 d-flex justify-content-end align-items-center">
  //             To
  //           </div>

  //           <div className="col-md-4 col-12">
  //             <Field name="conditionWeightToValue" component={FinalFormPolarisAdapter.textField} type="number" suffix="Kg" />
  //           </div>
  //           <div className="col-md-3 col-12">
  //             <Field name="conditionWeightCalculatePrice" render={({ input, meta }) => (
  //               <Checkbox
  //                 label={t('config.shippingRate.form.setCondition.calculatePrice')}
  //                 checked={this.state.calculateWeight}
  //                 onChange={e => this.setState({ calculateWeight: e })}
  //               />
  //             )} />
  //           </div>
  //         </div>

  //         <div className="text-center mt-5">
  //           And/Or
  //         </div>
  //       </>
  //     )
  //   }
  // }

  // renderConditionPrice(isSelected, t) {
  //   if (isSelected) {
  //     return (
  //       <>
  //         <div className="row">
  //           <div className="col-md-4 col-12">
  //             <Field name="percentageValue" component={FinalFormPolarisAdapter.textField} type="number" placeholder="0.00" max={100} prefix="IDR" />
  //           </div>

  //           <div className="col-md-1 d-flex justify-content-end align-items-center">
  //             To
  //           </div>

  //           <div className="col-md-4 col-12">
  //             <Field name="priceValue" component={FinalFormPolarisAdapter.textField} type="number" placeholder="No Limit" prefix="IDR" />
  //           </div>
  //           <div className="col-md-3 col-12">
  //             <Field name="conditionPriceCalculatePrice" render={({ input, meta }) => (
  //               <Checkbox
  //                 label={t('config.shippingRate.form.setCondition.calculatePrice')}
  //                 checked={this.state.calculatePrice}
  //                 onChange={e => this.setState({ calculatePrice: e })}
  //               />
  //             )} />
  //           </div>
  //         </div>
  //       </>
  //     )
  //   }
  // }

  render() {
    return (
      <>
        <head>
          <title>{this.props.formType === 'update' ? 'Edit Shipping - ' : 'Create New Shipping'} {this.props.formType === 'update' && this.state?.shippingRateData?.zoneName}</title>
        </head>
        <Translation>
          {
            t => (
              <ReactFinalForm
                initialValues={this.state.shippingRateData}
                subscription={{ submitting: true }} /* attribute ini sebenarnya di pake biar inputnya ga lag, tapi kendalanya kalau ada validasi yang input textnya masih kosong, susah buat ngehandlenya (seperti nampilin show message pake toast untuk nama zona) */
                onSubmit={e => this.handleSubmit(e)}
              >
                {({
                  handleSubmit
                }) => (
                  <>
                    {this.state.isLoading && (
                      <div
                        style={{
                          zIndex: 1000,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          top: 0,
                          left: 0,
                          position: 'fixed',
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'black',
                          opacity: 0.5,
                        }}
                      >
                        <Spinner accessibilityLabel="Loading..." size="large" />
                      </div>
                    )}

                    <Frame>
                      <Layout>
                        <Layout.Section>
                          <Card sectioned>
                            <Form onSubmit={handleSubmit}>
                              <FormLayout>
                                <Field name="zoneName" render={({ input, meta }) => (
                                  <>
                                    <TextField
                                      label={t('config.shippingRate.form.zoneName')}
                                      {...input as any}
                                      onChange={(value) => {
                                        input.onChange(value);
                                        if (value.length === 1) {
                                          this.setState({ setErrorZoneName: false });
                                        } else if (value.length === 0) {
                                          this.setState({ setErrorZoneName: true });
                                        }
                                      }}
                                    />
                                    <div className="mt-3">
                                      {this.state.setErrorZoneName && (
                                        <InlineError message="required" fieldID="zoneName" />
                                      )}
                                    </div>
                                  </>
                                )} />
                                <hr className="margin-hr" />
                                <Field name="search" render={({ input, meta }) => (
                                  <TextField
                                    {...input as any}
                                    prefix={<Icon source={SearchMinor} color="base" />}
                                    autoComplete="off"
                                    onChange={(value) => this.handleChangeSearch(input, value)}
                                  />
                                )} />
                                <hr className="margin-hr" />
                                <Field name="selectedArea" render={({ input, meta }) => (
                                  <>
                                    <div className="mb-2">
                                      <Checkbox
                                        label="ALL PROVINCE"
                                        checked={this.state.selectAllProvince}
                                        onChange={e => this.handleSelectAllProvince(e)}
                                      />

                                      {/* <div className="mb-0 w-75 text-right collapsed" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target={`#collapse${province.provinceCode}`} aria-expanded="true" aria-controls={`collapse${province.provinceCode}`}>
                                        <span className="mr-3">{this.checkParent(province)?.checkedLength} of {province.data.length} Kabupaten</span>
                                        <img src="../../assets/img/arrow.svg" className="arrow" alt="arrow" />
                                      </div> */}
                                    </div>

                                    {this.state.listAddress && this.state.listAddress.map((province, provinceIndex) => (
                                      <div className="accordion" id="accordion" key={`pI` + provinceIndex}>
                                        <div className="card">
                                          <div className="card-header" id={`heading${province.provinceCode}`}>
                                            <div className="d-flex justify-content-between align-items-center">
                                              <div className='mt-2'>
                                                <Checkbox
                                                  label={province.provinceName}
                                                  checked={this.checkParent(province)?.isChecked}
                                                  onChange={e => this.handleSelectedProvince(e, province, provinceIndex)}
                                                />
                                              </div>
                                              <div className="mb-0 w-75 text-right collapsed" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target={`#collapse${province.provinceCode}`} aria-expanded="true" aria-controls={`collapse${province.provinceCode}`}>
                                                <span className="mr-3">{this.checkParent(province)?.checkedLength} of {province.data.length} Kabupaten</span>
                                                <img src="../../assets/img/arrow.svg" className="arrow" alt="arrow" />
                                              </div>
                                            </div>
                                          </div>

                                          <div id={`collapse${province.provinceCode}`} className="collapse" aria-labelledby={`heading${province.provinceCode}`}>
                                            <div className="card-body">
                                              {province.data && province.data.map((city, cityIndex) => (
                                                <div className="ml-4" key={cityIndex}>
                                                  <Checkbox
                                                    label={city.cityName}
                                                    checked={city?.isChecked}
                                                    onChange={e => this.handleSelectedCity(e, city, provinceIndex, cityIndex)}
                                                  />
                                                  {/* <ChoiceList
                                                  allowMultiple
                                                  title=""
                                                  choices={[{ ...city, label: city.cityName, value: city as any }]}
                                                  selected={this.state[`selectedCity${city.cityCode}`]}
                                                  onChange={e => this.setState({ [`selectedCity${city.cityCode}`]: e })}
                                                /> */}
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    <div className="mt-3">
                                      {this.state.setErrorProvince && (
                                        <InlineError message="Required" fieldID="selectedArea" />
                                      )}
                                    </div>
                                    {/* {listAddress.map((province, id) => (
                                    <>
                                      <div>
                                        <Checkbox
                                          key={id}
                                          label={province.provinceName}
                                          checked={this.state[_.camelCase(province.provinceName)]}
                                          onChange={e => this.setState({
                                            [_.camelCase(province.provinceName)]: e
                                          })}
                                        />
                                      </div>

                                      {this.state[_.camelCase(province.provinceName)] && (
                                        <>
                                          <div className="ml-3">
                                            {
                                              province.data.map((city, idx) => (
                                                <div>
                                                  <Checkbox
                                                    key={idx}
                                                    label={city.cityName}
                                                    checked={this.state[city.cityName]}
                                                    onChange={e => this.setState({
                                                      [city.cityName]: e
                                                    })}
                                                  />
                                                </div>
                                              ))
                                            }
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ))} */}
                                  </>
                                )} />
                                <Field name="serviceActivated" render={({ input, meta }) => (
                                  <>
                                    <h3 style={{ fontWeight: 600, fontSize: '16px' }} className="mb-3">{t('config.shippingRate.form.service')}</h3>
                                    <div className="ml-4">
                                      {this.state.listService && this.state.listService.map((service, idService) => (
                                        <div>
                                          <Checkbox
                                            label={`${service.courierCode.toUpperCase()} - ${service.service}`}
                                            checked={service?.isChecked}
                                            onChange={e => this.handleChangeService(e, service, idService)}
                                          />
                                        </div>
                                      ))}
                                      {/* <ChoiceList
                                      error={!this.state.setService && meta.error}
                                      allowMultiple
                                      {...input}
                                      choices={this.state.listService && this.state.listService.map(service => ({ ...service, label: `${service.courierCode.toUpperCase()} - ${service.service}`, value: service }))}
                                      title=""
                                      selected={this.state.selectedService}
                                      onChange={e => this.handleChangeService(e)}
                                    /> */}
                                    </div>
                                  </>
                                )} />
                                {this.state.setErrorService && (
                                  <InlineError message="Required" fieldID="serviceActivated" />
                                )}
                                <hr className="margin-hr" />
                                <Field name="typeDiscountRate.isActive" render={({ input, meta }) => (
                                  <Checkbox
                                    {...input}
                                    label={t('config.shippingRate.form.discountType.title')}
                                    checked={this.state.discountType}
                                    onChange={e => {
                                      if (e) {
                                        this.setState({ discountType: e });
                                      } else {
                                        this.setState({
                                          discountType: e,
                                          setCondition: e,
                                          conditionWeight: e,
                                          conditionPrice: e,
                                          setErrorDiscountType: e,
                                          setErrorDiscountTypeValue: e
                                        });
                                      }
                                    }}
                                  />
                                )} />
                                {this.state.discountType && (
                                  <div className="ml-4">
                                    <Field name="typeDiscountRate.type" render={({ input, meta }) => (
                                      <ChoiceList
                                        {...input}
                                        title=""
                                        choices={[
                                          {
                                            label: t('config.shippingRate.form.discountType.percentage'),
                                            value: 'percentage',
                                            renderChildren: isSelected => this.renderPercentage(isSelected)
                                          }, {
                                            label: t('config.shippingRate.form.discountType.fixDiscount'),
                                            value: 'fixDiscount',
                                            renderChildren: isSelected => this.renderFixDiscount(isSelected)
                                          }, {
                                            label: t('config.shippingRate.form.discountType.fixedAmount'),
                                            value: 'fixedAmount',
                                            renderChildren: isSelected => this.renderFixedAmount(isSelected)
                                          }
                                        ]}
                                        selected={this.state.selectedDiscountType}
                                        onChange={e => this.setState({
                                          selectedDiscountType: e,
                                          setErrorDiscountType: false,
                                          setErrorDiscountTypeValue: false
                                        })}
                                      />
                                    )} />

                                    {this.state.setErrorDiscountType && (
                                      <InlineError message="Required" fieldID="typeDiscountRate.type" />
                                    )}
                                  </div>
                                )}
                                <hr className="margin-hr" />
                                <Field name="setCondition" render={({ input, meta }) => (
                                  <Checkbox
                                    {...input}
                                    disabled={!this.state.discountType}
                                    label={t('config.shippingRate.form.setCondition.title')}
                                    checked={this.state.setCondition}
                                    onChange={e => {
                                      if (e) {
                                        this.setState({ setCondition: e });
                                      } else {
                                        this.setState({
                                          setCondition: e,
                                          setErrorConditionWeightStart: false,
                                          setErrorConditionPriceStart: false,
                                        });
                                      }
                                    }}
                                  />
                                )} />
                                {this.state.setCondition && (
                                  <div className="ml-4">
                                    <Field name="conditionWeight" render={({ input, meta }) => (
                                      <Checkbox
                                        label={t('config.shippingRate.form.setCondition.weight')}
                                        checked={this.state.conditionWeight}
                                        onChange={e => {
                                          if (e) {
                                            this.setState({ conditionWeight: e });
                                          } else {
                                            this.setState({
                                              conditionWeight: e,
                                              setErrorConditionWeightStart: false
                                            });
                                          }
                                        }}
                                      />
                                    )} />
                                    <br />

                                    {this.state.conditionWeight && (
                                      <div className="row mb-3">
                                        <div className="col-md-4 col-12 ml-4">
                                          <Field name="conditionDiscount.basedOnWeight.startValue" render={({ input, meta }) => (
                                            <>
                                              <TextField
                                                autoComplete='off'
                                                {...input}
                                                label=""
                                                type="number"
                                                suffix="Kg"
                                                min={0}
                                                onChange={e => {
                                                  input.onChange(e);
                                                  const shippingRateData = this.state.shippingRateData;
                                                  shippingRateData.conditionDiscount = {
                                                    ...shippingRateData.conditionDiscount,
                                                    basedOnWeight: {
                                                      ...shippingRateData.conditionDiscount?.basedOnWeight,
                                                      startValue: e
                                                    }
                                                  };
                                                  if (this.state.conditionWeight && e.length === 1) {
                                                    this.setState({ setErrorConditionWeightStart: false });
                                                  } else if (this.state.conditionWeight && e.length === 0) {
                                                    this.setState({ setErrorConditionWeightStart: true });
                                                  }
                                                  setTimeout(() => {
                                                    this.setState({ shippingRateData });
                                                  }, 0);
                                                }}
                                              />
                                              <div className="mt-3">
                                                {this.state.setErrorConditionWeightStart && (
                                                  <InlineError message="Required" fieldID="conditionDiscount.basedOnWeight.startValue" />
                                                )}
                                              </div>
                                            </>
                                          )} />
                                        </div>

                                        <div className="col d-flex justify-content-start align-items-center">
                                          To
                                        </div>

                                        <div className="col-md-4 col-12">
                                          <Field name="conditionDiscount.basedOnWeight.endValue" render={({ input, meta }) => (
                                            <TextField
                                              autoComplete='off'
                                              {...input}
                                              label=""
                                              type="number"
                                              suffix="Kg"
                                              min={0}
                                              error={this.state.conditionWeight && meta.error && meta.touched && meta.error}
                                              onChange={e => {
                                                input.onChange(e);
                                                const shippingRateData = this.state.shippingRateData;
                                                shippingRateData.conditionDiscount = {
                                                  ...shippingRateData.conditionDiscount,
                                                  basedOnWeight: {
                                                    ...shippingRateData.conditionDiscount?.basedOnWeight,
                                                    endValue: e
                                                  }
                                                };
                                                setTimeout(() => {
                                                  this.setState({ shippingRateData });
                                                }, 0);
                                              }}
                                            />
                                          )} />
                                        </div>
                                        <div className="col-md-3 col-12">
                                          <Field name="conditionDiscount.basedOnWeight.countRemainder" render={({ input, meta }) => (
                                            <Checkbox
                                              {...input}
                                              label={t('config.shippingRate.form.setCondition.calculatePrice')}
                                              checked={this.state.calculateWeight}
                                              onChange={e => this.setState({ calculateWeight: e })}
                                            />
                                          )} />
                                        </div>
                                      </div>
                                    )}

                                    {(this.state.conditionPrice && this.state.conditionWeight) && (
                                      <div className="text-center">
                                        <Field name="conditionDiscount.type" render={({ input, meta }) => (
                                          <Stack>
                                            <RadioButton
                                              {...input}
                                              label="And"
                                              checked={this.state.choiceAndOr === 'and'}
                                              id="and"
                                              onChange={(checked, newValue) => {
                                                this.setState({ choiceAndOr: newValue });
                                              }}
                                            />
                                            <RadioButton
                                              {...input}
                                              label="Or"
                                              checked={this.state.choiceAndOr === 'or'}
                                              id="or"
                                              onChange={(checked, newValue) => {
                                                this.setState({ choiceAndOr: newValue });
                                              }}
                                            />
                                          </Stack>
                                        )} />
                                      </div>
                                    )}

                                    <Checkbox
                                      label={t('config.shippingRate.form.setCondition.price')}
                                      checked={this.state.conditionPrice}
                                      onChange={e => {
                                        if (e) {
                                          this.setState({ conditionPrice: e });
                                        } else {
                                          this.setState({
                                            conditionPrice: e,
                                            setErrorConditionPriceStart: false
                                          });
                                        }
                                      }}
                                    />

                                    {this.state.conditionPrice && (
                                      <div className="row">
                                        <div className="col-md-4 col-12 ml-4">
                                          <Field name="conditionDiscount.basedOnPrice.startValue" render={({ input, meta }) => (
                                            <>
                                              <TextField
                                                autoComplete='off'
                                                {...input}
                                                label=""
                                                type="number"
                                                placeholder="0.00"
                                                prefix="IDR"
                                                onChange={e => {
                                                  input.onChange(e);
                                                  const shippingRateData = this.state.shippingRateData;
                                                  shippingRateData.conditionDiscount = {
                                                    ...shippingRateData.conditionDiscount,
                                                    basedOnPrice: {
                                                      ...shippingRateData.conditionDiscount?.basedOnPrice,
                                                      startValue: e
                                                    }
                                                  };
                                                  if (this.state.conditionPrice && e.length === 1) {
                                                    this.setState({ setErrorConditionPriceStart: false });
                                                  } else if (this.state.conditionPrice && e.length === 0) {
                                                    this.setState({ setErrorConditionPriceStart: true });
                                                  }
                                                  setTimeout(() => {
                                                    this.setState({ shippingRateData });
                                                  }, 0);
                                                }}
                                              />
                                              <div className="mt-3">
                                                {this.state.setErrorConditionPriceStart && (
                                                  <InlineError message="Required" fieldID="conditionDiscount.basedOnPrice.startValue" />
                                                )}
                                              </div>
                                            </>
                                          )} />
                                        </div>

                                        <div className="col d-flex justify-content-start align-items-center" style={{ width: '20px' }}>
                                          To
                                        </div>

                                        <div className="col-md-4 col-12">
                                          <Field name="conditionDiscount.basedOnPrice.endValue" render={({ input, meta }) => (
                                            <TextField
                                              {...input}
                                              autoComplete='off'
                                              label=""
                                              type="number"
                                              placeholder="No Limit"
                                              prefix="IDR"
                                              onChange={e => {
                                                input.onChange(e);
                                                const shippingRateData = this.state.shippingRateData;
                                                shippingRateData.conditionDiscount = {
                                                  ...shippingRateData.conditionDiscount,
                                                  basedOnPrice: {
                                                    ...shippingRateData.conditionDiscount?.basedOnPrice,
                                                    endValue: e
                                                  }
                                                };
                                                setTimeout(() => {
                                                  this.setState({ shippingRateData });
                                                }, 0);
                                              }}
                                            />
                                          )} />
                                        </div>
                                        <div className="col-md-3 col-12">
                                          {/* <Field name="conditionDiscount.basedOnPrice.countRemainder" render={({ input, meta }) => (
                                              <Checkbox
                                                {...input}
                                                label={t('config.shippingRate.form.setCondition.calculatePrice')}
                                                checked={this.state.calculatePrice}
                                                onChange={e => this.setState({ calculatePrice: e })}
                                              />
                                            )} /> */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  // <ChoiceList
                                  //   allowMultiple
                                  //   title=""
                                  //   choices={[
                                  //     {
                                  //       label: t('config.shippingRate.form.setCondition.weight'),
                                  //       value: 'weight',
                                  //       renderChildren: isSelected => this.renderConditionWeight(isSelected, t)
                                  //     }, {
                                  //       label: t('config.shippingRate.form.setCondition.price'),
                                  //       value: 'price',
                                  //       renderChildren: isSelected => this.renderConditionPrice(isSelected, t)
                                  //     },
                                  //   ]}
                                  //   selected={this.state.selectedConditionType}
                                  //   onChange={e => this.setState({ selectedConditionType: e })}
                                  // />
                                )}
                                <hr className="margin-hr" />
                                <h3 style={{ fontWeight: 600, fontSize: '16px' }}>Summary</h3>
                                <div>
                                  {/* summary city */}
                                  {this._renderSelectedCity() && this._renderSelectedCity().length ? (
                                    this._renderSelectedCity().length < 4 ? (
                                      <>
                                        Untuk daerah {this._renderSelectedCity().map((city, i) => (
                                          <>{city.cityName}{i === this._renderSelectedCity().length - 1 ? ' ' : ', '}</>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        Untuk {this._renderSelectedCity().length} Kabupaten telah dipilih&nbsp;
                                      </>
                                    )
                                  ) : ''}
                                  {/* summary service */}
                                  {this._renderSelectedService() && this._renderSelectedService().length ? (
                                    <>
                                      menggunakan kurir {this._renderSelectedService().map((service, i) => (
                                        <>{service.courierCode.toUpperCase()} - {service.service}{i === this._renderSelectedService().length - 1 ? ' ' : ', '}</>
                                      ))}
                                    </>
                                  ) : ''}
                                  {/* summary discount */}
                                  {this._renderDiscount()}
                                  {/* summary condition */}
                                  {this._renderCondition()}
                                </div>
                                <div className="d-flex justify-content-end">
                                  <div className="mr-3">
                                    <Button onClick={() => this.props.history.push('/config')}>Cancel</Button>
                                  </div>
                                  <Button primary submit>{this.props.formType === 'create' ? 'Create' : 'Save'}</Button>
                                </div>
                              </FormLayout>
                            </Form>
                            {/* <div className="row">
                                <div className="col">
                                  <div className="form-group">
                                    <label>Zone Name</label>
                                    <Field name="zoneName" label="Zone Name" component={FinalFormPolarisAdapter.textField} />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className="form-group">
                                    <Field name="search" component={FinalFormPolarisAdapter.textFieldSearch} />
                                  </div>
                                </div>
                              </div> */}
                          </Card>
                        </Layout.Section>
                      </Layout>
                    </Frame>
                  </>
                )}
              </ReactFinalForm>
            )
          }
        </Translation>
      </>
    );
  }
}

export default withRouter(ShippingRateFormComponent)
