import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Icon,
  ResourceItem,
  ResourceList,
  TextStyle,
} from '@shopify/polaris';
import { LocationMajor } from '@shopify/polaris-icons';
import { MessageParserService } from 'apps/main-web/src/libraries/common/message-parser.service';
import { DetailOrderRestService } from '../detail-order-rest.service';
import { UpdateOriginProps } from './index.d';
import './styles.scss';

const UpdateOriginComponent = (props: UpdateOriginProps) => {
  const { handleSubmit } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locationData, setLocationData] = useState<any>([]);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastValue, setToastValue] = useState<any>();

  function loadLocation() {
    setIsLoading(true);

    DetailOrderRestService.getLocation()
      .then((response) => {
        if (response?.success) {
          setLocationData(response?.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    loadLocation();
  }, []);

  return (
    <>
      <Card>
        <ResourceList
          loading={isLoading}
          resourceName={{ singular: 'location', plural: 'locations' }}
          items={locationData}
          renderItem={(data: any) => {
            const media = (
              <div className="LKNDJ _2C4Q4">
                <Icon source={LocationMajor} color="base" />
              </div>
            );

            return (
              <ResourceItem
                id={data?.id}
                onClick={() => handleSubmit(data)}
                media={media}
              >
                <h3>
                  <TextStyle variation="strong">{data?.name}</TextStyle>
                </h3>
                <div>{`${data?.address1}, ${data?.address2}, ${data?.city}, ${data?.localized_province_name} ${data?.zip}, ${data?.localized_country_name}`}</div>
              </ResourceItem>
            );
          }}
        />
      </Card>
    </>
  );
};

export default UpdateOriginComponent;
