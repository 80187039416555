/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { ActionList, Button, AlphaCard, Checkbox, Frame, Icon, Modal, Pagination, Popover, RadioButton, Spinner, HorizontalStack, TextField, Toast, Label } from '@shopify/polaris';
import { Translation } from 'react-i18next';
import { ShopConfigurationPayload } from './config.model';
import { BaseToastProps } from '../../layouts/main/toast/base-toast';
import { ConfigRestService } from './config-rest.service';
import listAddress from '../../assets/data-dummy/alamat-shopify.json';
import { ShippingRateRestService } from '../shipping-rate/shipping-rate-rest.service';
import _ from 'lodash';
import './config.scss';
import { Link, withRouter } from 'react-router-dom';
import { SearchMinor } from '@shopify/polaris-icons';
import { MessageParserService } from '../../libraries/common/message-parser.service';
import { DebounceInput } from 'react-debounce-input';

// karena ada initial state yang sifatnya dynamic, jadinya config state ini ga begitu di pake
export class ConfigFormState {
  default = false;
  isLoading = false;
  isInsuranced = false;
  showCaptionCod = false;
  isUniqueCode = false;
  popoverDefaultService = false;
  popoverDefaultDistrict = false;
  popOverAction = false;
  uniqueCodeStart = 0;
  uniqueCodeEnd = 0;
  uniqueCodeType = 'increase';
  currentUniqueCode = 0;
  selectedDefaultDistrict = [];
  selectedDefaultService = [];
  popoverActiveManage = [];
  strongValidationAddress = true;
  showToast = false;
  toastValue: BaseToastProps = {
    content: '',
    duration: 2000
  };
  disabledButton = false;
  disabledService = false;
  listCity = [];
  listShippingRate = [];
  listService: { courierCode: string, service: string }[] = [];
  listServiceSelected: { courierCode: string, service: string }[] = [];

}
class ConfigFormComponent extends React.Component<any, any> {

  constructor(props) {
    super(props);

    this.state = {
      selectedProvince2: {},
      pageNumber: 1,
      skipNumber: 0,
      listService: [],
      paramsShippingRate: {
        take: 100,
        skip: 0
      },
      showSku: false,
      toastValue: {},
      popoverActiveManage: []
    };
  }

  componentDidMount() {
    this.loadCity();
    this.loadConfig();
    this.loadTime();
  }

  loadCity() {
    const listCity = [];
    for (let index = 0; index < listAddress.length; index++) {
      listCity.push(listAddress[index].data);
    }

    this.setState({
      listAddress,
      allListAddress: listAddress,
      allListCity: _.flatMapDeep(listCity),
      listCity: _.flattenDeep(listCity),
      listSearchCity: _.flattenDeep(listCity)
    });
  }

  loadConfig() {
    this.setState({ isLoading: true });

    ConfigRestService.getConfig()
      .then(res => {
        if (!(res.clientId && res.clientSecret)) {
          this.setState({ disabledButton: true });
        }
        this.setState({
          isLoading: false,
          isInsuranced: res.isInsuranced || false,
          autoPickup: res.serviceAutoPickup || {},
          isMustInsuranced: res.isMustInsuranced || false,
          isUniqueCode: res.isUniqueCode || false,
          strongValidationAddress: typeof res.strongValidationAddress === 'boolean' ? res.strongValidationAddress : true,
          uniqueCodeStart: res.uniqueCode ? res.uniqueCode.start : 0,
          uniqueCodeEnd: res.uniqueCode ? res.uniqueCode.end : 0,
          uniqueCodeType: res.uniqueCode ? res.uniqueCode.type : 'increment',
          currentUniqueCode: res.currentUniqueCode,
          showCaptionCod: res.showCaptionCod,
          showSku: res.showSku || false,
          listServiceSelected: res.serviceActivated ? res.serviceActivated : [],
          // listService: this.generateListServiceCourier(res.listService),
          disabledService: res.serviceActivated && _.isArray(res.serviceActivated) ? true : false,
        });

        this.loadShippingRate();
      })
      .catch(err => {
        this.setState({
          disabledButton: true,
          isLoading: false
        });
        if (err?.response?.status === 401) this.props.history.push('/authentication');
      })
  }

  loadTime() {
    const timeData = [];

    for (let i = 0; i < 24; i++) {
      const hour = String(i).padStart(2, '0');

      timeData.push({
        label: `${hour}:00`,
        value: `${hour}:00`,
      });

      timeData.push({
        label: `${hour}:30`,
        value: `${hour}:30`,
      })
    }

    this.setState({ timeData })
  }

  loadShippingRate() {
    // this.setState({ isLoading: true });

    const params = Object.assign({}, this.state.paramsShippingRate);

    ShippingRateRestService.getAllCustomShippingRate(params)
      .then(res => {
        if (res.success) {
          const listShippingRate = res.data.list.map((data, id) => {
            if (data.isDefault) {
              this.setState({ default: data.isActive });
            }

            return {
              ...data,
              serviceActivated: data.serviceActivated.map(s => {
                return {
                  ...s,
                  isChecked: true
                }
              })
            }
            // this.setState({
            //   [`selectedDefaultDistrict${id}`]: [],
            //   [`selectedDefaultService${id}`]: []
            // });
          });

          this.setState({
            listShippingRate,
            // isLoading: false
          });

          this.loadService();
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  loadService() {
    // this.setState({ isLoading: true });
    ShippingRateRestService.getService(null)
      .then(res => {
        if (res.success) {
          const listService = res.data.map(service => {
            return service.services.map(s => ({ courierCode: service.courierCode, service: s }));
          });

          this.setState({ listService: _.flattenDeep(listService) });
        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  renderDefaultService(data, id: number) {
    return (
      <Link to="#" onClick={() => {
        setTimeout(() => {
          const listService = this.state.listService.map((v, i) => {
            if (v.isChecked) {
              delete v.isChecked;
            }
            return v;
          });

          listService.map((v, i) => {
            const findIdx = data.serviceActivated.findIndex(e => e.courierCode === v.courierCode && e.service === v.service);
            if (findIdx !== -1) {
              v.isChecked = data.serviceActivated[findIdx].isChecked
            }
            return v;
          });

          listService.sort((a, b) => Number(_.has(b, 'isChecked')) - Number(_.has(a, 'isChecked')));

          this.setState({
            listService,
            [`popoverDefaultService${id}`]: !this.state[`popoverDefaultService${id}`]
          });
        }, 0);
      }}>{data?.serviceActivated?.length} Service</Link>
    );
  }

  renderAction(id: number) {
    return (
      <span className="item-action" onClick={() => this.setState({ [`popoverAction${id}`]: !this.state[`popoverAction${id}`] })}>...</span>
    );
  }

  renderDefaultDistrict(data, id: number) {
    return (
      <Link to="#" onClick={() => {
        setTimeout(() => {
          const listCity = this.state.listCity.map((v, i) => {
            if (v.isChecked) {
              delete v.isChecked;
            }
            return v;
          });

          listCity.map((v, i) => {
            const findIdx = data.selectedArea.findIndex(e => e.cityCode === v.cityCode);
            if (findIdx !== -1) {
              v.isChecked = data.selectedArea[findIdx].isChecked
            }

            return v;
          });

          listCity.sort((a, b) => Number(_.has(b, 'isChecked')) - Number(_.has(a, 'isChecked')));

          this.setState({
            listCity,
            [`popoverDefaultDistrict${id}`]: !this.state[`popoverDefaultDistrict${id}`]
          }, () => console.log(this.state.popoverDefaultDistrict));

        }, 0);
      }}>{data?.selectedArea?.length} Kabupaten</Link>
    );
  }

  handleChangeDefault(e, id) {
    this.setState({
      default: e,
      isLoading: true
    });

    ShippingRateRestService.toggleInactiveShippingRate(id, e)
      .then(res => {
        this.setState({
          isLoading: false,
          showToast: true,
          toastValue: {
            content: e ? 'Berhasil mengaktifkan default shipping rate' : 'Berhasil menonaktifkan default shipping rate'
          }
        }, () => {
          this.loadShippingRate();
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          showToast: true,
          toastValue: {
            content: MessageParserService.parseSpesific(err),
            error: true
          }
        })
      });
  }

  async handlePagination(type) {
    let currentPage = this.state.pageNumber;

    let skip = this.state.skipNumber;

    let paramsPage = {
      take: 0,
      skip: 0,
    };

    if (type === 'prev') {
      const page = this.state.paramsShippingRate;

      --currentPage;
      paramsPage = {
        take: page.take,
        skip: page.take * --skip,
      };
    } else if (type === 'next') {
      const page = this.state.paramsShippingRate;

      ++currentPage;
      paramsPage = {
        take: page.take,
        skip: page.take * ++skip,
      };
    }

    this.setState(
      {
        paramsShippingRate: paramsPage,
        skipNumber: skip,
        pageNumber: currentPage,
      },
      () => {
        this.loadShippingRate();
      }
    );
  }

  hasNextCheck() {
    let hasNext = true;
    const lengthShippingRate = this.state.listShippingRate && this.state.listShippingRate?.length;
    if (this.state.paramsShippingRate.take > lengthShippingRate) {
      hasNext = false;
    }
    return hasNext;
  }

  handleChangeSelectedCity(value, idCity) {
    const listCity = this.state.listCity;
    listCity[idCity].isChecked = value;

    this.setState({
      listCity
    });
  }

  handleUpdateCity(data, id) {
    const selectedArea = this.state.allListCity.filter(s => _.has(s, 'isChecked') && s.isChecked);
    const normalizeValue = {
      zoneName: data.zoneName,
      selectedArea: selectedArea,
      serviceActivated: data.serviceActivated,
      typeDiscountRate: data.typeDiscountRate,
      conditionDiscount: data.conditionDiscount
    }

    this.setState({ isLoading: true });
    ShippingRateRestService.updateCustomShippingRate(data._id, normalizeValue)
      .then(res => {
        this.setState({
          isLoading: false,
          showToast: true,
          isShowMoreCity: false,
          toastValue: {
            content: 'Kecamatan Berhasil di ubah'
          },
          [`popoverDefaultDistrict${id}`]: false
        }, () => {
          this.loadConfig();
        });
      })
      .catch(err => {
        if (err.response.status === 400) {
          this.setState({
            isLoading: false,
            showToast: true,
            toastValue: {
              content: err.response.data.errors[0].detail.response.error,
              error: true
            }
          })
        } else {
          this.setState({
            isLoading: false,
            showToast: true,
            toastValue: {
              content: MessageParserService.parseSpesific(err),
              error: true
            }
          });
        }
      });
  }

  handleChangeSelectedService(value, idService) {
    const listService = this.state.listService;
    listService[idService].isChecked = value;

    this.setState({
      listService
    });
  }

  handleUpdateService(data, id) {
    const selectedService = this.state.listService.filter(s => _.has(s, 'isChecked') && s.isChecked);
    const normalizeValue = {
      zoneName: data.zoneName,
      selectedArea: data.selectedArea,
      serviceActivated: selectedService,
      typeDiscountRate: data.typeDiscountRate,
      conditionDiscount: data.conditionDiscount
    }

    this.setState({ isLoading: true });
    ShippingRateRestService.updateCustomShippingRate(data._id, normalizeValue)
      .then(res => {
        this.setState({
          isLoading: false,
          showToast: true,
          toastValue: {
            content: 'Service Berhasil di ubah'
          },
          [`popoverDefaultService${id}`]: false
        }, () => {
          this.loadConfig();
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          showToast: true,
          toastValue: {
            content: MessageParserService.parseSpesific(err),
            error: true
          }
        });
      });
  }

  handleUpdate(id) {
    this.props.history.push(`/shipping-rate/update/${id}`);
  }

  handleShowMoreListCity(data, id) {
    this.setState({
      isShowMoreCity: true,
      selectedData: data,
      idData: id
    });
  }

  handleCloseMoreListCity() {
    const listCity = this.state.listCity.map(city => {
      delete city.isChecked;
      return city;
    });

    this.setState({
      isShowMoreCity: false,
      listCity,
      listAddress: this.state.allListAddress
    });
  }

  removeCollapseShow(getProvince) {
    for (let i = 0; i < getProvince.length; i++) {
      const findClassShow = document.getElementById(`collapse${getProvince[i].provinceCode}`);
      const getClassCollapsed = document.getElementsByClassName('w-50')[i];
      if (findClassShow?.classList?.contains('show')) {
        findClassShow?.classList?.remove('show');
      }

      if (!getClassCollapsed?.classList?.contains('collapsed')) {
        getClassCollapsed?.classList.add('collapsed');
      }
    }
  }

  handleChangeSearchCity(input, value) {
    input.onChange(value);

    const getProvince = listAddress.filter(province => province.provinceName.toLowerCase().includes(value.toLowerCase()));
    if (getProvince.length) {
      this.removeCollapseShow(getProvince);
      this.setState({ listAddress: getProvince });
    } else {
      const filtered = listAddress.map(province => {
        return {
          ...province,
          data: province.data.filter(city => {
            return city.cityName.toLowerCase().includes(value.toLowerCase());
          })
        }
      });
      const filterEmptyCity = filtered.filter(province => province.data.length);
      this.removeCollapseShow(filterEmptyCity);
      this.setState({ listAddress: filterEmptyCity });
    }
  }

  handleConfirmDelete(data) {
    this.setState({
      isShowDeleteModal: true,
      selectedData: data
    });
  }

  handleDelete(id) {
    this.setState({
      isLoading: true
    });

    ShippingRateRestService.deleteCustomShippingRate(id)
      .then(res => {
        this.setState({
          isShowDeleteModal: false,
          selectedData: null,
          showToast: true,
          toastValue: {
            content: 'Data berhasil di hapus'
          }
        }, () => this.loadConfig());
      });
  }

  onSubmit(property?, propertyValue?, t?) {
    this.setState({ isLoading: true });
    const toastValue = this.state.toastValue || {};
    let value = this.state;
    if (property !== undefined && propertyValue !== undefined) {
      value = { ...value, [property]: propertyValue };
      if (value['isInsuranced'] === false && value['isMustInsuranced'] === true) value = { ...value, isMustInsuranced: false };
    }

    if (value.isUniqueCode && (value.uniqueCodeEnd <= value.uniqueCodeStart) && (property === 'uniqueCodeEnd' || property === 'uniqueCodeStart')) {
      toastValue.duration = 5000;
      toastValue.content = t('config.toast.error.uniqueCode');
      toastValue.error = true;
      this.setState({ isLoading: false, toastValue, showToast: true });
    } 
    // else if (!value.autoPickup.time) {
    //   toastValue.duration = 5000;
    //   toastValue.content = 'Please select time pickup!';
    //   toastValue.error = true;
    //   this.setState({ isLoading: false, toastValue, showToast: true });
    // } 
    else {
      const payload: ShopConfigurationPayload = {
        strongValidationAddress: value.strongValidationAddress,
        isInsuranced: value.isInsuranced,
        serviceAutoPickup: {
          ...value.autoPickup
        },
        isMustInsuranced: value.isMustInsuranced,
        isUniqueCode: value.isUniqueCode,
        uniqueCode: value.isUniqueCode ? {
          start: value.uniqueCodeStart,
          end: value.uniqueCodeEnd,
          type: value.uniqueCodeType,
        } : null,
        showCaptionCod: value.showCaptionCod,
        showSku: value.showSku,
        serviceActivated: value.disabledService ? value.listServiceSelected : null,
      };

      ConfigRestService.saveConfig(payload)
        .then(res => {
          toastValue.error = false;
          toastValue.content = t('config.toast.success.submitConfig');
          this.setState({ toastValue, showToast: true, isLoading: true }, () => {
            this.loadConfig();
          });
        })
        .catch(err => {
          toastValue.content = t('config.toast.error.submitConfig');
          toastValue.error = true;
          this.setState({ toastValue, showToast: true });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  checkParent(province) {
    const checkedProvinces = _.filter(province.data, ['isChecked', true]);
    return { isChecked: checkedProvinces?.length === province.data.length, checkedLength: checkedProvinces?.length };
  }

  handleSelectedProvince(value, province, provinceIndex) {
    for (let index = 0; index < province.data.length; index++) {
      this.handleSelectedCity(value, province.data[index], provinceIndex, index);
    }
  }

  handleSelectedCity(value, city, provinceIndex, cityIndex) {
    city.isChecked = value;
    const localState = this.state;
    // FIX This Problem caused of mutated state assigned thats something
    localState.listAddress[provinceIndex].data[cityIndex].isChecked = value;

    this.setState({
      selectedProvince2: {
        ...localState.selectedProvince2,
        [_.camelCase(localState.listAddress[provinceIndex].provinceName)]: {
          ...(localState.selectedProvince2[_.camelCase(localState.listAddress[provinceIndex].provinceName)] || {}),
          [_.camelCase(city.cityName)]: city
        }
      }
    });
  }

  closeToast() {
    this.setState({ showToast: false });
  }

  popOverService(data, id, t) {
    return (
      <Popover
        activator={this.renderDefaultService(data, id)}
        active={this.state[`popoverDefaultService${id}`]}
        onClose={() => {
          const listService = this.state.listService.map(v => {
            delete v.isChecked;
            return v;
          });

          this.setState({
            listService,
            [`popoverDefaultService${id}`]: !this.state[`popoverDefaultService${id}`],
          });
        }}
      >
        <Popover.Pane>
          <Popover.Section>
            {this.state.listService.map((service, idService) => (
              <div>
                <Checkbox
                  label={`${service.courierCode} - ${service.service}`}
                  checked={service?.isChecked}
                  onChange={value => this.handleChangeSelectedService(value, idService)}
                />

              </div>
            )
            )}

            <div className="mt-3">
              <Button outline onClick={() => this.handleUpdateService(data, id)}>
                {t('config.save').toString()}
              </Button>
            </div>
            {/* <ChoiceList
            allowMultiple
            title=""
            choices={[
              {
                label: 'JNE',
                value: 'JNE'
              }, {
                label: 'Sicepat',
                value: 'Sicepat'
              }, {
                label: 'Anteraja',
                value: 'Anteraja'
              }, {
                label: 'J&T',
                value: 'J&T'
              }
            ]}
            selected={this.state[`selectedDefaultService${id}`]}
            onChange={e => this.setState({ [`selectedDefaultService${id}`]: e })}
          /> */}
          </Popover.Section>
        </Popover.Pane>
      </Popover>
    )
  }
  popOverDistrict(data, id, t) {
    return (
      <Popover
        activator={this.renderDefaultDistrict(data, id)}
        active={this.state[`popoverDefaultDistrict${id}`]}
        onClose={() => {
          if (!this.state.isShowMoreCity) {
            const listCity = this.state.listCity.map(v => {
              delete v.isChecked;
              return v;
            });

            this.setState({
              listCity,
              [`popoverDefaultDistrict${id}`]: !this.state[`popoverDefaultDistrict${id}`]
            });
          } else {
            this.setState({ [`popoverDefaultDistrict${id}`]: !this.state[`popoverDefaultDistrict${id}`] });
          }

        }}
      >
        <Popover.Pane>
          <Popover.Section>
            {this.state.listCity.map((city, idCity) => (
              idCity < 5 && (
                <div>
                  <Checkbox
                    label={city.cityName}
                    checked={city?.isChecked}
                    onChange={value => this.handleChangeSelectedCity(value, idCity)}
                  />
                </div>
              )
            ))}
            <Link to="#" onClick={() => this.handleShowMoreListCity(data, id)}>
              <small>Show More</small>
            </Link>
            <div className="mt-3">
              <Button outline onClick={() => this.handleUpdateCity(data, id)}>
                {t('config.save').toString()}
              </Button>
            </div>
          </Popover.Section>
        </Popover.Pane>
      </Popover>
    )
  }

  popOverManage(id) {
    return (
      <p className="shipping-rate-manage cursor-pointer" onClick={() => this.togglePopOverManage(id)}>Manage</p>
    )
  }

  togglePopOverManage(id) {
    const stateValue = this.state;
    stateValue.popoverActiveManage[id] = !this.state?.popoverActiveManage[id];
    this.setState({ ...stateValue });
  }

  render() {
    const formatter = new Intl.NumberFormat('id', {
      style: 'currency',
      currency: 'IDR'
    });
    return (
      <Translation>
        {
          t => (
            <>
              {this.state.isLoading && (
                <div
                  style={{
                    zIndex: 1000,
                    display: 'block',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'black',
                    opacity: 0.5
                  }}
                >
                  <div
                    style={{
                      paddingTop: '23%',
                      paddingLeft: '48.5%'
                    }}
                  >
                    <Spinner
                      accessibilityLabel="Loading..."
                      size="large"
                    />
                  </div>
                </div>
              )}

              <div className="form-config">
                <div id="location-mapping" className="border-bottom">
                  <h2>Location Check</h2>
                  <div className="pl-4 pt-2">
                    <HorizontalStack gap="4">
                      <RadioButton
                        name="strongValidationAddress"
                        id="strong"
                        checked={this.state.strongValidationAddress}
                        label="Strong"
                        onChange={(e, value) => {
                          this.setState({ strongValidationAddress: true });
                          this.onSubmit('strongValidationAddress', true, t);
                        }}
                      />
                      <RadioButton
                        name="strongValidationAddress"
                        id="weak"
                        checked={!this.state.strongValidationAddress}
                        label="Weak"
                        onChange={(e, value) => {
                          this.setState({ strongValidationAddress: false });
                          this.onSubmit('strongValidationAddress', false, t);
                        }}
                      />
                    </HorizontalStack>
                  </div>
                  <p>Dipergunakan untuk memvalidasi alamat ketika checkout, diberikan 2 pilihan validasi ketika buyer checkout.
                    &nbsp;<Link to={{ pathname: "https://help.clodeo.com/id/collections/3602940-shipdeo" }} rel="nofollow" target='_blank'>Pelajari lebih lanjut tentang Location check.</Link>
                  </p>
                </div>

                {/* it will not shown in production, so i comment it first, when you need just uncomment it! */}
                {/* <div id="auto-pickup" className="border-bottom">
                    <div className="label-checkbox">
                      <Checkbox
                        label={t('config.autoPickup')}
                        checked={this.state.isAutoPickup}
                        onChange={e => {
                          this.setState({ isAutoPickup: e });
                        }}
                      />
                    </div>
                    <p>Auto request pickup saat order kamu sudah dibayar dan memiliki alamat yang tepat, kamu hanya perlu request pickup untuk order yang perlu penangan manual saja.
                      &nbsp;<Link to="#autoPickup">Pelajari lebih lanjut tentang Auto pickup.</Link>
                    </p>
                    {this.state.isAutoPickup && (
                      <div className="pickup-time-section pt-3">
                        <TextField
                          label="Pickup Time"
                          type='time'
                          value={this.state.pickupTime || '00:00'}
                          onChange={(value) => this.setState({ pickupTime: value })}
                          autoComplete="off"
                        />
                      </div>
                    )}
                  </div> */}

                <div id="uniqueCode" className='border-bottom'>
                  <div className="label-checkbox">
                    <Checkbox
                      label={t('config.uniqCode.title')}
                      checked={this.state.isUniqueCode}
                      onChange={e => {
                        this.setState({ isUniqueCode: e });
                        this.onSubmit('isUniqueCode', e, t);
                      }}
                    />
                  </div>
                  <p>Menambah atau mengurangi pada ongkos kirim, agar memudahkan penandaan pada sebuah transaksi.
                    &nbsp;<Link to={{ pathname: "https://help.clodeo.com/id/collections/3602940-shipdeo" }} rel="nofollow" target='_blank'>Pelajari lebih lanjut tentang Unique code.</Link>
                  </p>
                  <div className="unique-code-section">
                    {this.state.isUniqueCode && (
                      <div id="uniqueCodeDetail" className="pt-3">
                        <div className="pb-1">
                          {/* {t('config.uniqCode.current')}: {this.state.currentUniqueCode} */}
                          <h2>Unique code</h2>
                        </div>
                        <HorizontalStack gap="4">
                          <RadioButton
                            name="uniqueCodeType"
                            id="increment"
                            checked={this.state.uniqueCodeType === 'increment'}
                            label={t('config.uniqCode.addition')}
                            onChange={(e, value) => {
                              this.setState({ uniqueCodeType: value });
                              this.onSubmit('uniqueCodeType', value, t);
                            }}
                          />
                          <RadioButton
                            name="uniqueCodeType"
                            id="decrement"
                            checked={this.state.uniqueCodeType === 'decrement'}
                            label={t('config.uniqCode.subtraction')}
                            onChange={(e, value) => {
                              this.setState({ uniqueCodeType: value });
                              this.onSubmit('uniqueCodeType', value, t);
                            }}
                          />
                        </HorizontalStack>

                        <HorizontalStack gap="4">
                          <div>
                            <Label id="minimum">Minimum</Label>
                            <DebounceInput
                              type='number'
                              className="debounce-input-custom-shopify"
                              value={this.state?.uniqueCodeStart?.toString() || 0}
                              debounceTimeout={600}
                              onChange={e => {
                                this.setState({ uniqueCodeStart: e.target.value });
                                if (e.target.value) this.onSubmit('uniqueCodeStart', e.target.value, t);
                              }}
                            />
                          </div>
                          <div>
                            <Label id="minimum">Maximum</Label>
                            <DebounceInput
                              type='number'
                              className="debounce-input-custom-shopify"
                              value={this.state?.uniqueCodeEnd?.toString() || 0}
                              debounceTimeout={600}
                              onChange={e => {
                                this.setState({ uniqueCodeEnd: e.target.value });
                                this.onSubmit('uniqueCodeEnd', e.target.value, t);
                              }}
                            />
                          </div>
                        </HorizontalStack>
                      </div>
                    )}
                  </div>
                </div>

                <div id="showCod" className="border-bottom">
                  <div className="label-checkbox">
                    <Checkbox
                      label={t('config.showCod')}
                      checked={this.state.showCaptionCod}
                      onChange={e => {
                        this.setState({ showCaptionCod: e });
                        this.onSubmit('showCaptionCod', e, t);
                      }}
                    />
                  </div>
                  <p>Permudah pembeli kamu untuk membedakan kurir dan layanan mana saja yang mendukung metode pembayaran COD.
                    &nbsp;<Link to={{ pathname: "https://help.clodeo.com/id/collections/3602940-shipdeo" }} rel="nofollow" target='_blank'>Pelajari lebih lanjut tentang Show COD caption.</Link>
                  </p>
                </div>

                <div id="showSku" className="border-bottom">
                  <div className="label-checkbox">
                    <Checkbox
                      label={t('config.showSku')}
                      checked={this.state.showSku}
                      onChange={e => {
                        this.setState({ showSku: e });
                        this.onSubmit('showSku', e, t);
                      }}
                    />
                  </div>
                  <p>Gunakan juga kode SKU produk kamu pada saat cetak shipping label, SKU hanya muncul pada shipping label untuk order yang dibuat setelah fitur ini aktif.
                    &nbsp;<Link to={{ pathname: "https://help.clodeo.com/id/collections/3602940-shipdeo" }} rel="nofollow" target='_blank'>Pelajari lebih lanjut tentang Show SKU.</Link>
                  </p>
                </div>

                <div id="insurance" className="border-bottom">
                  <div className="label-checkbox">
                    <Checkbox
                      label={t('config.insurance')}
                      checked={this.state.isInsuranced}
                      onChange={e => {
                        if (!e) {
                          this.setState({ isMustInsuranced: false });
                        }
                        this.setState({ isInsuranced: e });
                        this.onSubmit('isInsuranced', e, t);
                      }}
                    />
                  </div>
                  <p>Amankan pengiriman produk kamu dengan opsi menggunakan asuransi, sehingga saat terjadi masalah kamu bisa claim sesuai harga produk kamu.
                    &nbsp;<Link to={{ pathname: "https://help.clodeo.com/id/collections/3602940-shipdeo" }} rel="nofollow" target='_blank'>Pelajari lebih lanjut tentang Insurance.</Link>
                  </p>
                  <div className="is-must-insurance-section">
                    {this.state.isInsuranced && (
                      <div id="mustInsurance" className="pt-3">
                        <Checkbox
                          label={t('config.mustInsurance')}
                          checked={this.state.isMustInsuranced}
                          onChange={e => {
                            this.setState({ isMustInsuranced: e });
                            this.onSubmit('isMustInsuranced', e);
                          }}
                          disabled={!this.state.isInsuranced}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div id="manage-shipping-and-discount" className="py-5">
                  <h2 className="title">Manage shipping area and discount</h2>
                  <p className="description">Aktifkan pengiriman menggunakan shipdeo, kamu juga bisa atur diskon pengiriman berdasarkan area pengiriman dan juga kondisi lainnya.
                    &nbsp;<Link to={{ pathname: "https://help.clodeo.com/id/collections/3602940-shipdeo" }} rel="nofollow" target='_blank'>Pelajari lebih lanjut tentang Service.</Link>
                  </p>
                  <div className="border-top pt-3 mt-3">
                    {this.state.listShippingRate && this.state.listShippingRate.map((data, id) => (
                      <>
                        {data.isDefault && (
                          <h3 className="shipping-label">Default shipping area</h3>
                        )}
                        {!data.isDefault && id <= 1 && (
                          <h3 className="shipping-label">Custom shipping area and discount</h3>
                        )}
                        <div className="d-flex box-shipping-area">
                          <div className="default-shipping-area border-right">
                            {data.isDefault ? (
                              <Checkbox
                                label={'Default'}
                                checked={data.isActive}
                                onChange={e => this.handleChangeDefault(e, data._id)}
                                disabled={this.state.listShippingRate?.length === 1}
                              />
                            ) : <div className="label">{data.zoneName}</div>}
                          </div>
                          <div className="shipping-area-rates pl-4">
                            <div className="mr-2">
                              <h3>Rates for</h3>
                              <p className="d-flex">{data.isDefault ? "All District" : this.popOverDistrict(data, id, t)}
                                <span><img src="../../assets/img/arrow-right.png" alt="arrow for showing total services" /></span>
                                {this.popOverService(data, id, t)}
                              </p>
                            </div>
                          </div>
                          {!data.isDefault && (
                            <div className="shipping-area-rates shipping-discount pl-4 border-left">
                              <div className="row">
                                <div className="col-3">
                                  <b>Discount</b>
                                </div>
                                <div className="col-9">
                                  {data.typeDiscountRate.type === 'fixedAmount' && `: Fixed Amount ${formatter.format(data.typeDiscountRate.value)}`}
                                  {data.typeDiscountRate.type === 'fixDiscount' && `: Fix Discount ${formatter.format(data.typeDiscountRate.value)}`}
                                  {data.typeDiscountRate.type === 'percentage' && `: Discount ${data.typeDiscountRate.value}% ${_.has(data.typeDiscountRate, 'maxDiscountAmount') && data.typeDiscountRate?.maxDiscountAmount ? `Max ${formatter.format(data.typeDiscountRate.maxDiscountAmount)}` : ''}`}
                                  {!data?.typeDiscountRate?.type && ': -'}
                                </div>
                              </div>
                              <div className="row pt-2">
                                <div className="col-3">
                                  <b>Condition</b>
                                </div>
                                <div className="col-9">
                                  :
                                  {
                                    data?.conditionDiscount?.basedOnPrice?.startValue && (
                                      <>&nbsp;&ge; {formatter.format(data?.conditionDiscount?.basedOnPrice?.startValue)}</>
                                    )
                                  }
                                  {data?.conditionDiscount?.basedOnPrice?.startValue && data?.conditionDiscount?.basedOnPrice?.endValue && (
                                    <>&nbsp; and</>
                                  )}
                                  {
                                    data?.conditionDiscount?.basedOnPrice?.endValue && (
                                      <>&nbsp;&le; {formatter.format(data?.conditionDiscount?.basedOnPrice?.endValue)}</>
                                    )
                                  }

                                  {data?.conditionDiscount?.basedOnPrice?.startValue && data?.conditionDiscount?.basedOnWeight.startValue && (
                                    <br />
                                  )}

                                  {data?.conditionDiscount?.basedOnWeight.startValue && data?.conditionDiscount?.basedOnWeight.endValue && (
                                    <>&nbsp;
                                      {data?.conditionDiscount?.basedOnWeight.startValue && `${data?.conditionDiscount?.basedOnWeight.startValue} Kg`}
                                      - {data?.conditionDiscount?.basedOnWeight.endValue && `${data?.conditionDiscount?.basedOnWeight.endValue} Kg`}
                                    </>
                                  )}

                                  {data?.conditionDiscount?.basedOnWeight?.startValue && !data?.conditionDiscount?.basedOnWeight.endValue && (
                                    <>&nbsp;&ge; {data?.conditionDiscount?.basedOnWeight.startValue && `${data?.conditionDiscount?.basedOnWeight.startValue} Kg`}
                                    </>
                                  )}

                                  {!data?.conditionDiscount?.basedOnPrice?.startValue && !data?.conditionDiscount?.basedOnWeight.startValue && !data?.conditionDiscount?.basedOnWeight.endValue && '-'}
                                
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="shipping-rate-manage">
                            {data.isDefault === false && (
                              <Popover
                                active={this.state?.popoverActiveManage && this.state?.popoverActiveManage[id]}
                                activator={this.popOverManage(id)}
                                autofocusTarget="first-node"
                                onClose={() => this.togglePopOverManage(id)}
                              >
                                <ActionList
                                  actionRole="menuitem"
                                  items={[
                                    { content: 'Edit', onAction: () => this.handleUpdate(data._id) },
                                    { content: 'Delete', onAction: () => this.handleConfirmDelete(data) }
                                  ]}
                                />
                              </Popover>
                            )}
                            {/* <Link to="#" onClick={() => this.handleUpdate(data._id)}>Manage</Link> */}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="add-new-shipping-rates text-center">
                    <h3>Tambahkan tarif khusus atau batasan tujuan untuk kalkulasi ongkos kirim.</h3>
                    <Link to={`/shipping-rate/create`}>Create new custom shipping area and discount</Link>
                  </div>
                </div>

                {/* <div id="footer">
                    <Button
                      primary
                      onClick={() => {
                        this.onSubmit();
                      }}
                      disabled={this.state.disabledButton}
                    >
                      {t('config.save').toString()}
                    </Button>
                  </div> */}
              </div>

              {this.state.showToast && (
                <>
                  {/* TODO: Create base toast */}
                  <Frame>
                    <Toast
                      content={this.state.toastValue.content}
                      duration={this.state.toastValue.duration}
                      onDismiss={() => {
                        this.closeToast();
                      }}
                      error={this.state.toastValue.error}
                    />
                  </Frame>
                </>
              )}

              <Modal
                large
                open={this.state.isShowMoreCity}
                onClose={() => this.handleCloseMoreListCity()}
                title={t('config.shippingRate.modal.showMoreCity.title')}
                primaryAction={{
                  content: `${t('config.shippingRate.modal.button.save')}`,
                  onAction: () => this.handleUpdateCity(this.state.selectedData, this.state.idData)
                }}
                secondaryActions={[
                  {
                    content: t('config.shippingRate.modal.button.cancel').toString(),
                    onAction: () => this.handleCloseMoreListCity()
                  }
                ]}
              >
                <Modal.Section>
                  <Form onSubmit={() => { }} subscription={{ submitting: true }}>
                    {({ handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                          <Field name="searchCity" render={({ input, meta }) => (
                            <TextField
                              {...input as any}
                              prefix={<Icon source={SearchMinor} color="base" />}
                              autoComplete="off"
                              onChange={(value) => this.handleChangeSearchCity(input, value)}
                            />
                          )} />
                        </div>
                        <Field name="listCity" render={({ input, meta }) => (
                          this.state.listAddress && this.state.listAddress.map((province, provinceIndex) => (
                            <div className="accordion" id="accordion" key={`pI` + provinceIndex}>
                              <div className="card">
                                <div className="card-header" id={`heading${province.provinceCode}`}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <Checkbox
                                        label={province.provinceName}
                                        checked={this.checkParent(province)?.isChecked}
                                        onChange={e => this.handleSelectedProvince(e, province, provinceIndex)}
                                      />
                                    </div>
                                    <div className="mb-0 w-50 text-right collapsed" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target={`#collapse${province.provinceCode}`} aria-expanded="true" aria-controls={`collapse${province.provinceCode}`}>
                                      <span className="mr-3">{this.checkParent(province)?.checkedLength} of {province.data.length} Kabupaten</span>
                                      <img src="../../assets/img/arrow.svg" className="arrow" alt="arrow" />
                                    </div>
                                  </div>
                                </div>

                                <div id={`collapse${province.provinceCode}`} className="collapse" aria-labelledby={`heading${province.provinceCode}`}>
                                  <div className="card-body">
                                    {province.data && province.data.map((city, cityIndex) => (
                                      <div className="ml-4" key={cityIndex}>
                                        <Checkbox
                                          label={city.cityName}
                                          checked={city?.isChecked}
                                          onChange={e => this.handleSelectedCity(e, city, provinceIndex, cityIndex)}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )} />
                      </form>
                    )}
                  </Form>
                </Modal.Section>
              </Modal>

              <Modal
                open={this.state.isShowDeleteModal}
                onClose={() => this.setState({ isShowDeleteModal: false })}
                title={t('config.shippingRate.modal.delete.title')}
                primaryAction={{
                  content: `${t('config.shippingRate.modal.button.delete')}`,
                  onAction: () => this.handleDelete(this.state.selectedData?._id)
                }}
                secondaryActions={[
                  {
                    content: t('config.shippingRate.modal.button.cancel').toString(),
                    onAction: () => {
                      this.setState({
                        isShowDeleteModal: false
                      })
                    }
                  }
                ]}
              >
                <Modal.Section>
                  {t('config.shippingRate.modal.delete.description', { name: this.state.selectedData ? this.state.selectedData.zoneName : '' })}
                </Modal.Section>
              </Modal>
            </>
          )
        }
      </Translation>
    );
  }
}

export default withRouter(ConfigFormComponent);
