import Axios, { AxiosRequestConfig, CancelTokenStatic } from 'axios';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';

export class ServerApiHttpService {
  axios = Axios.create();
  cancelToken: CancelTokenStatic = Axios.CancelToken;

  private static bearerToken = '';

  constructor(baseUrl = '') {
    this.axios.defaults.baseURL = baseUrl;
    this.axios.defaults.withCredentials = true;
  }

  post<T = any>(url: string, data: any = null, config: AxiosRequestConfig = {}) {
    this.attachBearerToken();

    return new Observable<T>(subscriber => {
      const cancelSource = this.cancelToken.source();
      config.cancelToken = cancelSource.token;

      const promise = this.axios.post(url, data, config).then(response => response.data);
      fromPromise(promise).subscribe(subscriber);

      return () => cancelSource.cancel();
    });
  }

  put<T = any>(url: string, data: any = null, config: AxiosRequestConfig = {}) {
    this.attachBearerToken();

    return new Observable<T>(subscriber => {
      const cancelSource = this.cancelToken.source();
      config.cancelToken = cancelSource.token;

      const promise = this.axios.put(url, data, config).then(response => response.data);
      fromPromise(promise).subscribe(subscriber);

      return () => cancelSource.cancel();
    });
  }

  patch<T = any>(url: string, data: any = null, config: AxiosRequestConfig = {}) {
    this.attachBearerToken();

    return new Observable<T>(subscriber => {
      const cancelSource = this.cancelToken.source();
      config.cancelToken = cancelSource.token;

      const promise = this.axios.patch(url, data, config).then(response => response.data);
      fromPromise(promise).subscribe(subscriber);

      return () => cancelSource.cancel();
    });
  }

  get<T = any>(url: string, config: AxiosRequestConfig = {}, allowModify = false) {
    this.attachBearerToken();

    return new Observable<T>(subscriber => {
      const cancelSource = this.cancelToken.source();
      config.cancelToken = cancelSource.token;

      const promise = this.axios.get(url, config).then(response => (allowModify) ? response : response.data);
      fromPromise(promise).subscribe(subscriber);

      return () => cancelSource.cancel();
    });
  }

  delete<T = any>(url: string, data: any = null, config: AxiosRequestConfig = {}) {
    this.attachBearerToken();

    return new Observable<T>(subscriber => {
      const cancelSource = this.cancelToken.source();
      config.cancelToken = cancelSource.token;

      const promise = this.axios.request({
        url,
        method: 'delete',
        data,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.data);
      fromPromise(promise).subscribe(subscriber);

      return () => cancelSource.cancel();
    });
  }

  attachBearerToken(): this {
    if (ServerApiHttpService.bearerToken) {
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${ServerApiHttpService.bearerToken}`;
    }

    return this;
  }

  public static setBearerToken(bearerToken: string) {
    ServerApiHttpService.bearerToken = bearerToken;
  }
}
