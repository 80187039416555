import React from 'react';
import style from './main-footer.module.scss';
import { Translation } from 'react-i18next';

export default function MainFooterLayout() {
  return (
    <Translation>
      {
        t => (
          <React.Fragment>
            <div
              className={style.footer}
            >
              <span>
                {
                  t('footer.title')
                }
              </span>
            </div>
          </React.Fragment>
        )
      }
    </Translation>
  )
}
