import React from 'react';
import {
  Layout,
  Page
} from '@shopify/polaris';
import { ShippingFormComponent } from './shipping-form.component';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  TitleBar
} from "@shopify/app-bridge-react";

export class AuthenticationPage extends React.Component<any, any> {
  render() {
    return (
      <>
        <head>
          <title>Shipping Information</title>
        </head>
        <Translation>
          {t => (
            <Page fullWidth>
              <div className="shipping-page">
                <div className="shipping-information-header px-4">
                  <h2 className="title">Shipping Information</h2>
                  <p className="description">Aktifkan fitur shipping dari shipdeo di toko shopify kamu dan mulai pengalaman pengiriman kamu menggunakan shipdeo. Untuk aktivasi pengiriman silahkan masukan Client ID dan Client Secret dari akun shipdeo kamu disini.</p>
                </div>
                <ShippingFormComponent />
              </div>
            </Page>
          )}
        </Translation>
      </>
    );
  }
}
