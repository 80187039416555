import React from 'react';
import {
  Button,
  Layout,
  Page
} from '@shopify/polaris';
import { Translation } from 'react-i18next';
import ShippingRateFormComponent from '../form/shipping-rate-form.component';
import { Link } from 'react-router-dom';
import { ArrowLeftMinor } from '@shopify/polaris-icons';

export class ShippingRateUpdateComponent extends React.Component<any, any> {
  render() {
    return (
      <Translation>
        {
          t => (
            <Page
              fullWidth
            >
              <div className="container mb-5">
                <div
                  style={{
                    paddingTop: '10px',
                    paddingBottom: '30px',
                    width: '100%'
                  }}
                >
                  <div className="d-flex">
                    <div className="pr-3">
                      <Link to={`/config`}>
                        <Button outline icon={ArrowLeftMinor} />
                      </Link>
                    </div>
                    <p
                      style={{
                        fontWeight: 500,
                        fontSize: '18pt',
                        marginTop: '6px'
                      }}
                    >
                      {t('config.shippingRate.update.title')}
                    </p>
                  </div>
                </div>
                <ShippingRateFormComponent formType="update" />
              </div>
            </Page>
          )
        }
      </Translation>
    );
  }
}
