import React from 'react';
import {
  Layout,
  Thumbnail,
  Spinner
} from '@shopify/polaris';
import { ShippingRestService } from './shipping-rest.service';
import { Translation } from 'react-i18next';
import './shipping.scss';
import { LoginFormComponent } from '../login/login-form.component';
import { ShippingGuide } from './shipping-guide.component';
import { ShippingActiveCourierList } from './shipping-active-courier-list.component';
import isEmpty from 'lodash/isEmpty'

export class ShippingFormState {
  shippingData = {};
  selectedCourier = [];
  isLoading = false;
}

export class ShippingFormComponent extends React.Component<any, ShippingFormState> {

  constructor(props) {
    super(props);
    this.state = new ShippingFormState();
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    this.setState({
      isLoading: true
    });

    const shippingData = await ShippingRestService.getShipping()
      .then(
        (response) => {
          const selectedCourier = this.state.selectedCourier;

          if (response.data && response.data.couriers) {
            for (const courier of response.data.couriers) {
              selectedCourier.push(courier);
            }
          }

          this.setState({
            selectedCourier
          });

          return response.data;
        }
      )
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });

    this.setState({
      shippingData
    });
  }

  templateData = () => {
    const view = [];

    this.state.selectedCourier.map(courierCode => {
      view.push((
        <>
          <div>
            <Thumbnail
              source={
                `../../assets/img/${courierCode}.png`
              }
              size="large"
              alt={
                courierCode
              }
            />
          </div>
          <br />
        </>
      ));
    });

    return view;
  }


  render() {
    return (
      <Translation>
        {
          t => (

            <>
              {this.state.isLoading && (
                <div
                  style={{
                    zIndex: 1000,
                    display: 'block',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'black',
                    opacity: 0.5
                  }}
                >
                  <div
                    style={{
                      paddingTop: '23%',
                      paddingLeft: '48.5%'
                    }}
                  >
                    <Spinner
                      accessibilityLabel="Loading..."
                      size="large"
                    />
                  </div>
                </div>
              )
              }
              <Layout>
                <Layout.Section>
                  <div className={`shipping-credential ${isEmpty(this.state.shippingData) === false && 'shipping-credential-border-bottom'}`}>
                    <div className='row'>
                      <div className="col-6 border-right-2">
                        <div className="pr-3 my-3 px-4">
                          <LoginFormComponent loadDataShippingData={() => this.loadData()} />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="pl-3 my-3 px-4">
                          <ShippingGuide />
                        </div>
                      </div>
                    </div>
                  </div>
                </Layout.Section>
                {isEmpty(this.state.shippingData) === false && (
                  <Layout.Section>
                    <div className="shipping-active-couriers pt-4">
                      <div className="shipping-active-text px-4 pb-3">
                        <h2 className="pb-3">Active Courier</h2>
                        <p>Aktivasi kamu sudah berhasil jika kamu sudah melihat logo kurir pada list dibawah ini. Ayo aktifkan lebih banyak kurir untuk menikmati lebih banyak layanan pengiriman mereka yang ada di shipdeo.</p>
                      </div>
                      {/* Active Courier List */}
                      <div className="border-top">
                        <ShippingActiveCourierList shippingData={this.state.shippingData} />
                      </div>

                    </div>
                  </Layout.Section>
                )}
              </Layout>
            </>
          )}
      </Translation>
    );
  }
}
