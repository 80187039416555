import React from 'react';
import {
  Layout,
  Page
} from '@shopify/polaris';
import { Translation } from 'react-i18next';
import ShippingRateFormComponent from '../form/shipping-rate-form.component';

export class ShippingRateCreateComponent extends React.Component<any, any> {
  render() {
    return (
      <Translation>
        {
          t => (
            <Page
              fullWidth
            >
              <div className="container mb-5">
                <div
                  style={{
                    paddingTop: '10px',
                    paddingBottom: '30px',
                    width: '100%'
                  }}
                >
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: '18pt'
                    }}
                  >
                    {t('config.shippingRate.create.title')}
                  </p>
                </div>
                <ShippingRateFormComponent formType="create" />
              </div>
            </Page>
          )
        }
      </Translation>
    );
  }
}
