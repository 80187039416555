import React from 'react';
import {
  AlphaCard,
  Badge,
  Banner,
  Button,
  Card,
  Checkbox,
  ChoiceList,
  DisplayText,
  Form,
  Frame,
  Icon,
  LegacyCard,
  Modal,
  Pagination,
  ResourceItem,
  ResourceList,
  Spinner,
  Tag,
  Toast,
  Text,
  LegacyStack,
} from '@shopify/polaris';
import { Link, useParams, withRouter } from 'react-router-dom';
import { ArrowLeftMinor, EditMajor } from '@shopify/polaris-icons';
import { DetailOrderRestService } from './detail-order-rest.service';
import { Translation } from 'react-i18next';
import dayjs from 'dayjs';
import './detail-order.scss';
import { DetailOrderUpdateAddressPage } from './update-address/detail-order-update-address.page';
import { has, isArray, isEmpty, isUndefined, reverse } from 'lodash';
import { ManageOrderRestService } from '../manage-order/manage-order-rest.service';
import { MessageParserService } from '../../libraries/common/message-parser.service';
import UpdateOriginComponent from './update-origin';
import { ConfigRestService } from '../config/config-rest.service';
import CheckSuccess from '../../assets/img/detail-order/check-success.png';
import CheckInfo from '../../assets/img/detail-order/check-info.png';
import CheckGrey from '../../assets/img/detail-order/check-grey.png';
import MapPin from '../../assets/img/detail-order/map-pin.png';
import Truck from '../../assets/img/detail-order/truck.png';
import ArrowRight from '../../assets/img/detail-order/arrow-right.png';
import { DetailOrderUpdateCustomer } from './update-customer';

export class DetailOrderFormState {
  airwaybill: string = '';
  courier: string = '';
  choiceShippingCharge: any = [];
  dataPDF: any;
  detailData: any = {};
  failMessage: any = '';
  failToast: boolean = false;
  isLoading: boolean = false;
  isSkeleton: boolean = true;
  insuranceDisabled: boolean = false;
  currentInsuranceValue: number;
  insuranceValue: number;
  insuranceChecked: boolean = false;
  isHideInsurance: boolean = false;
  isModalShippingChargeShow: boolean = false;
  isModalShippingChargeLoading: boolean = false;
  isModalShippingLabelShow: boolean = false;
  isModalShippingLoading: boolean = false;
  isModalUpdateAddressShow: boolean = false;
  isModalUpdateOriginShow: boolean = false;
  isModalUpdateCustomerShow: boolean = false;
  noInsuranceCouriersData: any = {};
  outbondData: any = [];
  thermalMode: boolean = false;
  successToast: boolean = false;
  successMessage: any = '';
  tempShippingChargeData: any = {};
  shippingChargeValue: number;
  transactionSubtotal = 0;
  transactionTotal: number = 0;
  triggerChange: boolean = true;
  selectedShippingCharge: any[] = [];
  updateAddress: any = {};
  updateCustomer: any = {};
  notMatchAddress: boolean = false;
  sourceItems: any = [];
  items: any = [];
  pageNumber = 1;
  skipNumber = 0;
  page: any = {
    take: 5,
    skip: 0,
  };
}

class DetailOrderFormComponent extends React.Component<
  any,
  DetailOrderFormState
> {
  constructor(props) {
    super(props);
    this.state = new DetailOrderFormState();
    this.handleThermalModeChange = this.handleThermalModeChange.bind(this);
    this.updateCustomerPhone = this.updateCustomerPhone.bind(this);
  }

  componentDidMount() {
    this.loadDetail();
  }

  loadDetail() {
    this.setState({
      detailData: {},
      tempShippingChargeData: {},
      isLoading: true,
      isSkeleton: true,
    });

    const orderNumber = this.props.location.pathname.split('/');

    DetailOrderRestService.getDetailOrder(orderNumber[2])
      .then((response) => {
        if (response) {
          const discountShippingCharge = response.data.transaction.discountShippingCharge > response.data.transaction.originalShippingCharge ?
            response.data.transaction.originalShippingCharge : response.data.transaction.discountShippingCharge;
          this.setState(
            {
              detailData: response.data,
              airwaybill: response.data.trackingInfo.airwaybill,
              tempShippingChargeData: {
                courierCode: response.data.trackingInfo.courier,
                duration: response.data.trackingInfo.etd,
                originalPrice: response.data.transaction.originalShippingCharge,
                price: response.data.transaction.originalShippingCharge - discountShippingCharge,
                returnLevel: null,
                returnRate: null,
                service: response.data.trackingInfo.service,
              },
              transactionSubtotal:
                response.data.transaction.subtotal -
                response.data.transaction.discountCoupon,
              transactionTotal: response.data.transaction.totalValue,
              insuranceChecked: response.data.transaction.isInsuranced,
              sourceItems: response.data.items,
              insuranceDisabled: ['gosend', 'grab'].includes(response.data.trackingInfo.courier)
            },
            () => this.setPagination()
          );

          DetailOrderRestService.calculatePricing(
            response.data._id,
            response.data.customer.address
          )
            .then((price) => {
              const filterPrice = price.data.filter(
                (e) =>
                  e.courierCode === response.data.trackingInfo.courier &&
                  e.service === response.data.trackingInfo.service
              );
              const tempShippingChargeData = this.state.tempShippingChargeData;
              if (filterPrice.length && filterPrice[0].insuranceValue) {
                if (tempShippingChargeData) {
                  // add property insuranceValue on selectedShippingCharge
                  tempShippingChargeData.insuranceValue = response.data
                    ?.transaction?.isInsuranced
                    ? filterPrice[0].insuranceValue
                    : response.data?.transaction?.feeInsurance || 0;

                  this.setState({
                    tempShippingChargeData,
                    currentInsuranceValue: filterPrice[0].insuranceValue || 0,
                    insuranceValue: response.data.transaction.isInsuranced
                      ? filterPrice[0].insuranceValue
                      : 0,
                    transactionTotal: response.data.transaction.totalValue,
                  });
                }
              }

              ConfigRestService.noInsuranceCourier()
                .then(insuranceCourier => {
                  const hasShopInsuranceConfiguration = (insuranceCourier.data.couriers || []).includes(response.data.trackingInfo.courier) && has(insuranceCourier.data, 'shopName');
                  const conditionalShopInsurance = hasShopInsuranceConfiguration && response.data.transaction.isInsuranced;

                  this.setState({
                    noInsuranceCouriersData: insuranceCourier.data,
                    currentInsuranceValue: conditionalShopInsurance
                      ? 0
                      : filterPrice[0].insuranceValue,
                    insuranceValue: response.data.transaction.isInsuranced
                      ? conditionalShopInsurance
                        ? 0
                        : filterPrice[0].insuranceValue
                      : 0,
                    insuranceChecked: conditionalShopInsurance
                      ? false
                      : response.data.transaction.isInsuranced,
                    isHideInsurance: hasShopInsuranceConfiguration ? true : false,
                    transactionTotal: conditionalShopInsurance
                      ? response.data.transaction.totalValue -
                      filterPrice[0].insuranceValue
                      : response.data.transaction.totalValue,
                  });
                }
                );
            })
            .catch((err) => {
              throw err;
            })
            .finally(() => {
              DetailOrderRestService.trackingInfo({
                courier: response.data.trackingInfo.courier,
                airwaybill: response.data.trackingInfo.airwaybill,
              })
                .catch((err) => {
                  throw err;
                })
                .then((res) => {
                  this.setState({
                    outbondData: reverse(res.data.outbounds || []),
                  });
                })
                .finally(() => {
                  this.setState({
                    isLoading: false,
                  });
                });
            });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          failMessage: MessageParserService.parseSpesific(err),
          failToast: true,
        });
      })
      .finally(() => {
        this.setState({
          isSkeleton: false,
        });
      });
  }

  // _renderPayment(t, type, payment) {
  //   if (type === 'badge') {
  //     switch (payment) {
  //       case 1:
  //         return (
  //           <Badge progress="complete" status="success">
  //             {t('detail.status.payment.paid')}
  //           </Badge>
  //         );
  //         break;
  //       case 2:
  //         return (
  //           <Badge progress="incomplete">
  //             {t('detail.status.payment.unpaid')}
  //           </Badge>
  //         );
  //         break;
  //       case 3:
  //         return (
  //           <Badge progress="incomplete" status="critical">
  //             {t('detail.status.payment.refunded')}
  //           </Badge>
  //         );
  //         break;
  //       case 99:
  //         return (
  //           <Badge status="critical">{t('detail.status.payment.error')}</Badge>
  //         );
  //         break;
  //     }
  //   } else if (type === 'tag') {
  //     switch (payment) {
  //       case 1:
  //         return <Tag>{t('detail.status.payment.paid')}</Tag>;
  //         break;
  //       case 2:
  //         return <Tag>{t('detail.status.payment.unpaid')}</Tag>;
  //         break;
  //       case 3:
  //         return <Tag>{t('detail.status.payment.refunded')}</Tag>;
  //         break;
  //       case 99:
  //         return <Tag>{t('detail.status.payment.error')}</Tag>;
  //         break;
  //     }
  //   }
  // }

  _renderPayment(t, type, payment) {
    if (type === 'badge') {
      switch (payment) {
        case 1:
          return (
            <Badge progress="complete" status="success">
              {t('detail.status.payment.paid')}
            </Badge>
          );
          break;
        case 2:
          return (
            <Badge progress="incomplete">
              {t('detail.status.payment.unpaid')}
            </Badge>
          );
          break;
        case 3:
          return (
            <Badge progress="incomplete" status="critical">
              {t('detail.status.payment.refunded')}
            </Badge>
          );
          break;
        case 99:
          return (
            <Badge status="critical">{t('detail.status.payment.error')}</Badge>
          );
          break;
      }
    } else if (type === 'status') {
      switch (payment) {
        case 1:
          return <>{t('detail.status.payment.paid')}</>;
          break;
        case 2:
          return <>{t('detail.status.payment.unpaid')}</>;
          break;
        case 3:
          return <>{t('detail.status.payment.refunded')}</>;
          break;
        case 99:
          return <>{t('detail.status.payment.error')}</>;
          break;
      }
    }
  }

  renderLoading(position, borderBottomLeftRadius?, borderBottomRightRadius?) {
    return (
      <>
        <div
          style={{
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            left: 0,
            position,
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            opacity: 0.5,
            borderBottomLeftRadius,
            borderBottomRightRadius,
          }}
        >
          <Spinner accessibilityLabel="Loading..." size="large" />
        </div>
      </>
    );
  }

  // _renderFulfillment(t, type, fulfillment, item) {
  //   if (type === 'badge') {
  //     switch (fulfillment) {
  //       case 1:
  //         return (
  //           <Badge progress="incomplete">
  //             {t('detail.status.fulfillment.unfulfilled')}
  //           </Badge>
  //         );
  //         break;
  //       case 2:
  //         return (
  //           <Badge progress="complete" status="success">
  //             {t('detail.status.fulfillment.fulfilled')}
  //           </Badge>
  //         );
  //         break;
  //       case 3:
  //         return (
  //           <Badge progress="incomplete" status="critical">
  //             {t('detail.status.fulfillment.cancelled')}
  //           </Badge>
  //         );
  //         break;
  //       case 99:
  //         return (
  //           <Badge status="critical">
  //             {t('detail.status.fulfillment.error')}
  //           </Badge>
  //         );
  //         break;
  //     }
  //   } else if (type === 'tag') {
  //     switch (fulfillment) {
  //       case 1:
  //         return (
  //           <Tag>
  //             {t('detail.status.fulfillment.unfulfilled') + ` (${item})`}
  //           </Tag>
  //         );
  //         break;
  //       case 2:
  //         return (
  //           <Tag>{t('detail.status.fulfillment.fulfilled') + ` (${item})`}</Tag>
  //         );
  //         break;
  //       case 3:
  //         return (
  //           <Tag>{t('detail.status.fulfillment.cancelled') + ` (${item})`}</Tag>
  //         );
  //         break;
  //       case 99:
  //         return (
  //           <Tag>{t('detail.status.fulfillment.error') + ` (${item})`}</Tag>
  //         );
  //         break;
  //     }
  //   }
  // }

  _renderFulfillment(t, type, fulfillment, item) {
    if (type === 'badge') {
      switch (fulfillment) {
        case 1:
          return (
            <Badge progress="incomplete">
              {t('detail.status.fulfillment.unfulfilled')}
            </Badge>
          );
          break;
        case 2:
          return (
            <Badge progress="complete" status="success">
              {t('detail.status.fulfillment.fulfilled')}
            </Badge>
          );
          break;
        case 3:
          return (
            <Badge progress="incomplete" status="critical">
              {t('detail.status.fulfillment.cancelled')}
            </Badge>
          );
          break;
        case 99:
          return (
            <Badge status="critical">
              {t('detail.status.fulfillment.error')}
            </Badge>
          );
          break;
      }
    } else if (type === 'status') {
      switch (fulfillment) {
        case 1:
          return (
            <>{t('detail.status.fulfillment.unfulfilled') + ` (${item})`}</>
          );
          break;
        case 2:
          return <>{t('detail.status.fulfillment.fulfilled') + ` (${item})`}</>;
          break;
        case 3:
          return <>{t('detail.status.fulfillment.cancelled') + ` (${item})`}</>;
          break;
        case 99:
          return <>{t('detail.status.fulfillment.error') + ` (${item})`}</>;
          break;
      }
    }
  }

  handlePrintShippingLabel(orderId) {
    this.setState({
      isModalShippingLabelShow: true,
    });

    this.printShippingLabel(orderId, this.state.thermalMode);
  }

  handleThermalModeChange(value) {
    this.setState({ thermalMode: value });
    this.printShippingLabel(this.state.detailData._id, value);
  }

  async printShippingLabel(param, thermalMode) {
    this.setState({
      isModalShippingLoading: true,
    });
    let payload = {
      display_type: 'pdf',
      ordersId: [param],
      showField: {
        orderDetail: true,
        senderAddress: true,
        tenantLogo: true,
        showBarcodeNoOrder: true,
        showBarcodeTrackingNumber: true,
        showCreatedDateRelated: true,
        showShippingCharge: true,
      },
      config: {
        template: {
          format: 'a4',
          marginsType: 0,
          landscape: false,
          printBackground: false,
          fitToPage: true,
        },
      },
    };

    const thermalConfig = {
      additional_info: {
        printThermal: true,
      },
      config: {
        template: {
          ...payload.config.template,
          width: '80mm',
          height: '100mm',
          marginTop: '10px',
          marginRight: '10px',
          marginBottom: '10px',
          marginLeft: '10px',
        },
      },
    };

    if (thermalMode) {
      delete thermalConfig.config.template.format;
      payload = {
        ...payload,
        ...thermalConfig,
      };
    }

    await ManageOrderRestService.getShippingLabel(payload)
      .then((res) => {
        this.setState({
          dataPDF: `data:application/pdf;base64,${res}`,
        });
      })
      .catch((err) => {
        this.setState({
          failMessage: MessageParserService.parseSpesific(err),
          failToast: true,
        });
      })
      .finally(() => {
        this.setState({
          isModalShippingLoading: false,
        });
      });
  }

  // _renderShipment(t, type, shipment, isCOD) {
  //   if (type === 'badge') {
  //     switch (shipment) {
  //       case 1:
  //         return (
  //           <Badge progress="incomplete">
  //             {t('detail.status.shipment.unprocessed')}
  //           </Badge>
  //         );
  //         break;
  //       case 2:
  //         return (
  //           <Badge progress="partiallyComplete">
  //             {t('detail.status.shipment.processing')}
  //           </Badge>
  //         );
  //         break;
  //       case 3:
  //         return (
  //           <Badge progress="incomplete" status="info">
  //             {t('detail.status.shipment.ready')}
  //           </Badge>
  //         );
  //         break;
  //       case 4:
  //         return (
  //           <Badge progress="partiallyComplete" status="info">
  //             {t('detail.status.shipment.shipped')}
  //           </Badge>
  //         );
  //         break;
  //       case 5:
  //         return (
  //           <Badge progress="complete" status="success">
  //             {t('detail.status.shipment.delivered')}
  //           </Badge>
  //         );
  //         break;
  //       case 6:
  //         return (
  //           <Badge progress="incomplete" status="critical">
  //             {t('detail.status.shipment.cancel')}
  //           </Badge>
  //         );
  //         break;
  //       case 7:
  //         return (
  //           <Badge progress="complete" status="critical">
  //             {t('detail.status.shipment.returned')}
  //           </Badge>
  //         );
  //         break;
  //       case 8:
  //         return (
  //           <Badge progress="incomplete" status="critical">
  //             {t('detail.status.shipment.problem')}
  //           </Badge>
  //         );
  //         break;
  //       case 9:
  //         return (
  //           <Badge progress="partiallyComplete" status="attention">
  //             {t('detail.status.shipment.returning')}
  //           </Badge>
  //         );
  //         break;
  //       case 99:
  //         return (
  //           <Badge status="critical">{t('detail.status.shipment.error')}</Badge>
  //         );
  //         break;
  //     }
  //   } else if (type === 'tag') {
  //     switch (shipment) {
  //       case 1:
  //         return (
  //           <Tag>
  //             {t('detail.status.shipment.unprocessed') +
  //               `${isCOD === true ? ' (COD)' : ''}`}
  //           </Tag>
  //         );
  //         break;
  //       case 2:
  //         return (
  //           <Tag>
  //             {t('detail.status.shipment.processing') +
  //               `${isCOD === true ? ' (COD)' : ''}`}
  //           </Tag>
  //         );
  //         break;
  //       case 3:
  //         return (
  //           <Tag>
  //             {t('detail.status.shipment.ready') +
  //               `${isCOD === true ? ' (COD)' : ''}`}
  //           </Tag>
  //         );
  //         break;
  //       case 4:
  //         return (
  //           <Tag>
  //             {t('detail.status.shipment.shipped') +
  //               `${isCOD === true ? ' (COD)' : ''}`}
  //           </Tag>
  //         );
  //         break;
  //       case 5:
  //         return (
  //           <Tag>
  //             {t('detail.status.shipment.delivered') +
  //               `${isCOD === true ? ' (COD)' : ''}`}
  //           </Tag>
  //         );
  //         break;
  //       case 6:
  //         return (
  //           <Tag>
  //             {t('detail.status.shipment.cancel') +
  //               `${isCOD === true ? ' (COD)' : ''}`}
  //           </Tag>
  //         );
  //         break;
  //       case 7:
  //         return (
  //           <Tag>
  //             {t('detail.status.shipment.returned') +
  //               `${isCOD === true ? ' (COD)' : ''}`}
  //           </Tag>
  //         );
  //         break;
  //       case 8:
  //         return (
  //           <Tag>
  //             {t('detail.status.shipment.problem') +
  //               `${isCOD === true ? ' (COD)' : ''}`}
  //           </Tag>
  //         );
  //         break;
  //       case 9:
  //         return (
  //           <Tag>
  //             {t('detail.status.shipment.returning') +
  //               `${isCOD === true ? ' (COD)' : ''}`}
  //           </Tag>
  //         );
  //         break;
  //       case 99:
  //         return (
  //           <Tag>
  //             {t('detail.status.shipment.error') +
  //               `${isCOD === true ? ' (COD)' : ''}`}
  //           </Tag>
  //         );
  //         break;
  //     }
  //   }
  // }

  _renderShipment(t, type, shipment, isCOD) {
    if (type === 'badge') {
      switch (shipment) {
        case 1:
          return (
            <Badge progress="incomplete">
              {t('detail.status.shipment.unprocessed')}
            </Badge>
          );
          break;
        case 2:
          return (
            <Badge progress="partiallyComplete">
              {t('detail.status.shipment.processing')}
            </Badge>
          );
          break;
        case 3:
          return (
            <Badge progress="incomplete" status="info">
              {t('detail.status.shipment.ready')}
            </Badge>
          );
          break;
        case 4:
          return (
            <Badge progress="partiallyComplete" status="info">
              {t('detail.status.shipment.shipped')}
            </Badge>
          );
          break;
        case 5:
          return (
            <Badge progress="complete" status="success">
              {t('detail.status.shipment.delivered')}
            </Badge>
          );
          break;
        case 6:
          return (
            <Badge progress="incomplete" status="critical">
              {t('detail.status.shipment.cancel')}
            </Badge>
          );
          break;
        case 7:
          return (
            <Badge progress="complete" status="critical">
              {t('detail.status.shipment.returned')}
            </Badge>
          );
          break;
        case 8:
          return (
            <Badge progress="incomplete" status="critical">
              {t('detail.status.shipment.problem')}
            </Badge>
          );
          break;
        case 9:
          return (
            <Badge progress="partiallyComplete" status="attention">
              {t('detail.status.shipment.returning')}
            </Badge>
          );
          break;
        case 10:
          return (
            <Badge status="info">
              {t('order.resourceItem.badge.shipment.pending')}
            </Badge>
          );
        case 99:
          return (
            <Badge status="critical">{t('detail.status.shipment.error')}</Badge>
          );
          break;
      }
    } else if (type === 'status') {
      switch (shipment) {
        case 1:
          return (
            <>
              {t('detail.status.shipment.unprocessed') +
                `${isCOD === true ? ' (COD)' : ''}`}
            </>
          );
          break;
        case 2:
          return (
            <>
              {t('detail.status.shipment.processing') +
                `${isCOD === true ? ' (COD)' : ''}`}
            </>
          );
          break;
        case 3:
          return (
            <>
              {t('detail.status.shipment.ready') +
                `${isCOD === true ? ' (COD)' : ''}`}
            </>
          );
          break;
        case 4:
          return (
            <>
              {t('detail.status.shipment.shipped') +
                `${isCOD === true ? ' (COD)' : ''}`}
            </>
          );
          break;
        case 5:
          return (
            <>
              {t('detail.status.shipment.delivered') +
                `${isCOD === true ? ' (COD)' : ''}`}
            </>
          );
          break;
        case 6:
          return (
            <>
              {t('detail.status.shipment.cancel') +
                `${isCOD === true ? ' (COD)' : ''}`}
            </>
          );
          break;
        case 7:
          return (
            <>
              {t('detail.status.shipment.returned') +
                `${isCOD === true ? ' (COD)' : ''}`}
            </>
          );
          break;
        case 8:
          return (
            <>
              {t('detail.status.shipment.problem') +
                `${isCOD === true ? ' (COD)' : ''}`}
            </>
          );
          break;
        case 9:
          return (
            <>
              {t('detail.status.shipment.returning') +
                `${isCOD === true ? ' (COD)' : ''}`}
            </>
          );
          break;
        case 99:
          return (
            <>
              {t('detail.status.shipment.error') +
                `${isCOD === true ? ' (COD)' : ''}`}
            </>
          );
          break;
      }
    }
  }

  modalShippingChargeShow() {
    this.setState({
      isModalShippingChargeShow: true,
    });

    this.loadPricing();
  }

  async loadPricing() {
    this.setState({
      isModalShippingChargeLoading: true,
      notMatchAddress: false,
    });
    const payloadPricing = isEmpty(this.state.updateAddress)
      ? this.state.detailData.customer &&
      this.state.detailData.customer.address && {
        address1: this.state.detailData?.customer?.address?.address1,
        address2: this.state.detailData?.customer?.address?.address2,
        addressCode: this.state.detailData?.customer?.address?.addressCode,
        city: this.state.detailData?.customer?.address?.city,
        province: this.state.detailData?.customer?.address?.province,
        zip: this.state.detailData?.customer?.address?.zip,
        country: 'ID',
        isInsuranced: this.state.detailData?.transaction?.isInsuranced,
      }
      : {
        address1: this.state.updateAddress.address1,
        address2: this.state.updateAddress.address2,
        addressCode: this.state.updateAddress.addressCode,
        city: this.state.updateAddress.city,
        province: this.state.updateAddress.province,
        zip: this.state.updateAddress.zip,
        country: 'ID',
        isInsuranced: this.state.detailData?.transaction?.isInsuranced,
      };

    await DetailOrderRestService.calculatePricing(
      this.state.detailData._id,
      payloadPricing
    )
      .then((price) => {
        if (price) {
          const pricings = this.state.insuranceChecked ? price.data.filter(value => !['gosend', 'grab'].includes(value.courierCode)) : price.data;
          const mappedPrice = pricings.map((p) => ({
            ...p,
            label: `${p.courier} ${p.service} - ${p.price?.toLocaleString(
              'id',
              {
                style: 'currency',
                currency: 'IDR',
              }
            )}`,
            value: p,
          }));
          this.setState({
            choiceShippingCharge: mappedPrice,
          });
        }

        if (!price || !price.data) {
          this.setState({
            isModalShippingChargeShow: false,
            failMessage: 'Shipping Charge tidak ditemukan!',
            failToast: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          notMatchAddress: true,
        });
      })
      .finally(() => {
        this.setState({
          isModalShippingChargeLoading: false,
        });
      });
  }

  handleShippingChargeChange(selected) {
    this.setState({
      selectedShippingCharge: selected,
    });
  }

  handleModalCloseShippingCharge() {
    this.setState({
      isModalShippingChargeShow: false,
      choiceShippingCharge: [],
      selectedShippingCharge: [],
    });
  }

  handleShippingChargeSubmit() {
    const hasShopInsuranceConfiguration = (this.state.noInsuranceCouriersData.couriers || []).includes(
      this.state.selectedShippingCharge[0].courierCode
    ) && has(this.state.noInsuranceCouriersData, 'shopName');
    const conditionalShopInsurance = hasShopInsuranceConfiguration && this.state.detailData.transaction.isInsuranced;
    const disableInsuranceByCourier = ['gosend', 'grab'].includes(this.state.selectedShippingCharge[0].courierCode);

    this.setState({
      tempShippingChargeData: this.state.selectedShippingCharge[0],
      currentInsuranceValue: conditionalShopInsurance
        ? 0
        : this.state.selectedShippingCharge[0].insuranceValue,
      insuranceValue: this.state.detailData.transaction.isInsuranced
        ? conditionalShopInsurance || disableInsuranceByCourier
          ? 0
          : this.state.selectedShippingCharge[0].insuranceValue
        : 0,
      shippingChargeValue: this.state.selectedShippingCharge[0].price,
      courier: this.state.selectedShippingCharge[0].courierCode.toUpperCase(),
      insuranceChecked: conditionalShopInsurance || disableInsuranceByCourier
        ? false
        : this.state.detailData.transaction.isInsuranced,
      transactionTotal:
        this.state.detailData.transaction.subtotal +
        this.state.selectedShippingCharge[0].price +
        this.state.detailData.uniqueCode +
        (this.state.detailData.transaction.isInsuranced
          ? conditionalShopInsurance
            ? 0
            : this.state.selectedShippingCharge[0].insuranceValue
          : 0) + (this.state.detailData.transaction.isTaxed ? 0 : this.state.detailData.transaction.tax),
      airwaybill: '-',
      isModalShippingChargeShow: false,
      choiceShippingCharge: [],
      insuranceDisabled: disableInsuranceByCourier,
      triggerChange: false,
      isHideInsurance: hasShopInsuranceConfiguration ? true : false,
    });
  }

  handleInsuranceChecked(checked) {
    const tempShippingChargeData = this.state.tempShippingChargeData;
    if (checked) {
      tempShippingChargeData.insuranceValue = this.state.currentInsuranceValue
        ? this.state.currentInsuranceValue
        : has(this.state.tempShippingChargeData, 'insuranceValue')
          ? this.state.tempShippingChargeData.insuranceValue
          : this.state.detailData.transaction.feeInsurance;

      this.setState({
        tempShippingChargeData,
        insuranceChecked: checked,
        insuranceValue: this.state.currentInsuranceValue
          ? this.state.currentInsuranceValue
          : this.state.tempShippingChargeData.insuranceValue
            ? this.state.tempShippingChargeData.insuranceValue
            : this.state.detailData.transaction.feeInsurance,
        transactionTotal:
          this.state.transactionSubtotal +
          (has(this.state.tempShippingChargeData, 'price')
            ? this.state.tempShippingChargeData.price
            : this.state.detailData.transaction.shippingCharge) +
          this.state.detailData.uniqueCode +
          (this.state.currentInsuranceValue
            ? this.state.currentInsuranceValue
            : has(this.state.tempShippingChargeData, 'insuranceValue')
              ? this.state.tempShippingChargeData.insuranceValue
              : this.state.detailData.transaction.feeInsurance) +
          (this.state.detailData.transaction.isTaxed ? 0 : this.state.detailData.transaction.tax),
        triggerChange: false,
      });
    } else {
      tempShippingChargeData.insuranceValue = 0;
      this.setState({
        tempShippingChargeData,
        insuranceChecked: checked,
        insuranceValue: 0,
        transactionTotal:
          this.state.transactionSubtotal +
          (has(this.state.tempShippingChargeData, 'price')
            ? this.state.tempShippingChargeData.price
            : this.state.detailData.transaction.shippingCharge) +
          this.state.detailData.uniqueCode +
          0 + (this.state.detailData.transaction.isTaxed ? 0 : this.state.detailData.transaction.tax),
        triggerChange: false,
      });
    }
  }

  handleUpdateOrigin = (data) => {
    this.setState({
      isModalUpdateOriginShow: false,
    });

    const payload = {
      id: data?.id,
    };

    DetailOrderRestService.updateOrigin(this.state.detailData?.orderId, payload)
      .then((response) => {
        if (response?.success) {
          const detailData = {
            ...this.state.detailData,
            origin: data,
          };

          this.setState({
            detailData,
            triggerChange: false,
            insuranceChecked: false,
            insuranceValue: 0,
            tempShippingChargeData: {},
            selectedShippingCharge: [],
            shippingChargeValue: 0,
            transactionTotal:
              this.state.detailData.transaction.subtotal +
              0 +
              this.state.detailData.uniqueCode +
              0,
            insuranceDisabled: true,
            airwaybill: '-',
            courier: '-',
          });
        }
      })
      .catch((err) => {
        this.setState({
          failToast: true,
          failMessage: MessageParserService.parseSpesific(err),
        });
      });
  };

  handleUpdateAddress(v) {
    this.setState({
      updateAddress: v,
      isModalUpdateAddressShow: false,
      insuranceChecked: false,
      insuranceValue: 0,
      tempShippingChargeData: {},
      selectedShippingCharge: [],
      shippingChargeValue: 0,
      transactionTotal:
        this.state.detailData.transaction.subtotal +
        0 +
        this.state.detailData.uniqueCode +
        0,
      triggerChange: false,
      insuranceDisabled: true,
      airwaybill: '-',
      courier: ' ',
    });
  }

  updateCustomerPhone(phone: string) {
    this.setState({
      updateCustomer: {
        customer: {
          phone
        }
      },
      isModalUpdateCustomerShow: false,
      triggerChange: false
    });
  }

  async handleRefreshAwb() {
    this.setState({
      isLoading: true,
    });

    const { deliveryTime, deliveryType } = this.state.detailData;

    const payload = {
      orders: [
        {
          id: this.state.detailData._id,
          deliveryTime,
          deliveryType,
        },
      ],
      deliveryTime,
      deliveryType,
    };

    await ManageOrderRestService.changeStatusOrder('ready-to-ship', payload)
      .then((response) => {
        if (response.success) {
          this.setState(
            {
              isLoading: false,
              successToast: true,
              successMessage: 'Refresh AWB Successfully!',
            },
            () => setTimeout(() => this.loadDetail(), 1000)
          );
        }
      })
      .catch((err) => {
        this.setState({
          failMessage: MessageParserService.parseSpesific(err),
          failToast: true,
        });
      });
  }

  handleCancel() {
    this.setState({
      courier: '',
      airwaybill: '',
      choiceShippingCharge: [],
      insuranceDisabled: false,
      insuranceValue: this.state.detailData.transaction.feeInsurance,
      shippingChargeValue: this.state.detailData.transaction.shippingCharge,
      tempShippingChargeData: {},
      transactionTotal: 0,
      triggerChange: true,
      updateAddress: {},
      updateCustomer: {}
    });

    this.loadDetail();
  }

  handleUpdate(t) {
    this.setState({
      isLoading: true,
    });

    const { originalPrice, ...rest } = this.state.tempShippingChargeData;
    const pricing = { originalShippingCharge: originalPrice, ...rest };

    const assignObj = {
      address: {
        ...(!isEmpty(this.state.updateAddress)
          ? this.state.updateAddress
          : this.state.detailData.customer.address),
      },
      customer: {
        phone: this.state.updateCustomer?.customer?.phone ?? this.state.detailData.customer.phone
      },
      isInsuranced: this.state.insuranceChecked,
      pricing,
    };

    if (isEmpty(assignObj.pricing)) {
      return this.setState({
        failToast: true,
        isLoading: false,
        failMessage: t('order.detail.update.error'),
      });
    }

    DetailOrderRestService.updateCustomerAddress(
      this.state.detailData._id,
      assignObj
    )
      .then((res) => {
        this.setState(
          {
            successToast: true,
            isLoading: false,
            triggerChange: true,
            successMessage: t('order.detail.update.success'),
          },
          () => this.loadDetail()
        );
      })
      .catch((err) => {
        this.setState({
          failToast: true,
          isLoading: false,
          failMessage: MessageParserService.parseSpesific(err),
        });
      });
  }

  async handlePagination(type) {
    let currentPage = this.state.pageNumber;
    let skip = this.state.skipNumber;
    let paramsPage = {
      take: 0,
      skip: 0,
    };

    if (type === 'prev') {
      const page = this.state.page;
      --currentPage;
      paramsPage = {
        take: page.take,
        skip: page.take * --skip,
      };
    } else if (type === 'next') {
      const page = this.state.page;
      ++currentPage;
      paramsPage = {
        take: page.take,
        skip: page.take * ++skip,
      };
    }

    this.setState(
      {
        page: paramsPage,
        skipNumber: skip,
        pageNumber: currentPage,
      },
      () => this.setPagination()
    );
  }

  setPagination() {
    const newItems = [];
    const item = this.state.sourceItems?.map((item) => {
      if (Array.isArray(item)) {
        return item[0];
      }

      return item;
    });
    const pageNumber = this.state.pageNumber;
    const { take, skip } = this.state.page;

    for (let i = 0; i < item.length; i += take) {
      const result = item.slice(i, i + take);
      newItems.push(result);
    }

    this.setState({
      items: newItems[pageNumber - 1],
    });
  }

  render() {
    let selectedShipCost = this.state.selectedShippingCharge[0];
    let shipChargeValue = this.state.shippingChargeValue;
    let detailData = this.state.detailData;
    let formatterCurrency = (value) =>
      value?.toLocaleString('id', {
        style: 'currency',
        currency: 'IDR',
      });
    let shipCost = selectedShipCost
      ? formatterCurrency(selectedShipCost?.originalPrice)
      : shipChargeValue || shipChargeValue === 0
        ? formatterCurrency(shipChargeValue)
        : has(detailData?.transaction, 'originalShippingCharge') &&
          detailData?.transaction?.originalShippingCharge
          ? formatterCurrency(detailData?.transaction?.originalShippingCharge)
          : formatterCurrency(detailData?.transaction?.shippingCharge);

    return (
      <Translation>
        {(t) => (
          <>
            {this.state.isLoading && this.renderLoading('fixed')}

            {/* HEADER */}
            <div className="row align-items-center pb-4">
              <div className="col-1">
                <Link to={`/manage-order`}>
                  <Button outline icon={ArrowLeftMinor} />
                </Link>
              </div>

              <div className="col-7">
                <div className="row">
                  <div id="order-number">
                    <DisplayText size="medium">
                      #{this.state.detailData?.orderNumber}
                    </DisplayText>
                  </div>

                  <div id="status-order">
                    {this._renderFulfillment(
                      t,
                      'badge',
                      this.state.detailData?.fulfillment,
                      this.state.detailData?.item
                    )}
                    {this._renderShipment(
                      t,
                      'badge',
                      this.state.detailData?.shipment,
                      this.state.detailData?.isCOD
                    )}
                    {this._renderPayment(
                      t,
                      'badge',
                      this.state.detailData?.payment
                    )}
                  </div>
                </div>

                <div className="row">
                  <h5>
                    {dayjs(this.state.detailData?.createdAt).format(
                      'MMMM D, YYYY [at] h:mm a'
                    )}{' '}
                    from Online Shop
                  </h5>
                </div>
              </div>

              <div className="col-4 text-right">
                {this.state.detailData?.shipment === 2 && (
                  <>
                    {this.state.detailData.deliveryType && (
                      <span className="mr-3">
                        <Button outline onClick={() => this.handleRefreshAwb()}>
                          Refresh AWB
                        </Button>
                      </span>
                    )}
                    {/* {this.state.detailData.deliveryType.includes('dropoff') ? (
                      <span className="mr-3">
                        <Button outline onClick={() => this.handleRefreshAwb()}>
                          Refresh AWB
                        </Button>
                      </span>
                    ) : (
                      this.state.detailData.deliveryType.includes('pickup') &&
                      this.state.detailData.deliveryTime && (
                        <span className="mr-3">
                          <Button
                            outline
                            onClick={() => this.handleRefreshAwb()}
                          >
                            Refresh AWB
                          </Button>
                        </span>
                      )
                    )} */}
                  </>
                )}
                {(this.state.detailData.shipment < 3 ||
                  this.state.detailData.shipment === 99) && (
                    <>
                      <span className="mr-3">
                        <Button
                          disabled={this.state.triggerChange}
                          outline
                          onClick={() => this.handleCancel()}
                        >
                          Cancel
                        </Button>
                      </span>
                      <span>
                        <Button
                          disabled={this.state.triggerChange}
                          outline
                          onClick={() => this.handleUpdate(t)}
                        >
                          Update
                        </Button>
                      </span>
                    </>
                  )}
                {this.state.detailData.shipment > 2 &&
                  this.state.detailData.shipment !== 99 && (
                    <Button
                      outline
                      onClick={() =>
                        this.handlePrintShippingLabel(
                          this.state.detailData.orderId
                        )
                      }
                    >
                      Print
                    </Button>
                  )}
              </div>
            </div>

            {/* CONTENT */}
            <div className="row">
              {/* LEFT SECTION */}
              <div className="col-8">
                <div className="Polaris-Card card-fulfillment">
                  <div className="Polaris-Card__Header">
                    <div className="row col-12 d-flex align-items-center">
                      <div className="col-1 px-0 text-center icon-title mr-2">
                        <img
                          src={
                            this.state.detailData?.fulfillment === 2
                              ? CheckSuccess
                              : CheckGrey
                          }
                          alt=""
                        />
                      </div>

                      <div className="col-11 px-0">
                        <Text variant="headingMd" as="h4">
                          {this._renderFulfillment(
                            t,
                            'status',
                            this.state.detailData?.fulfillment,
                            this.state.detailData?.item
                          )}
                        </Text>
                      </div>
                    </div>
                  </div>

                  <div className="Polaris-Card__Section p-0 py-2 mt-4 table-heading">
                    <div className="row col-12 pl-5">
                      <div className="col-3">
                        <span>Item</span>
                      </div>

                      <div className="col-3">
                        <span>SKU</span>
                      </div>

                      <div className="col-3">
                        <span>Quantity</span>
                      </div>

                      <div className="col-3">
                        <span>Total</span>
                      </div>
                    </div>
                  </div>

                  <div className="Polaris-Card__Section p-0 py-0 border-0">
                    {this.state.items?.length > 0 &&
                      (this.state.items || []).map((item) => {
                        const {
                          id,
                          title,
                          sku,
                          discount_allocations,
                          price,
                          quantity,
                        }: any = item;

                        return (
                          <div className="row col-12 pl-5" key={id}>
                            <div className="col-3 py-2 border-bottom">
                              <span>{title || ''}</span>
                            </div>

                            <div className="col-3 py-2 border-bottom">
                              <span>{sku === '' ? '—' : sku}</span>
                            </div>

                            <div className="col-3 py-2 border-bottom">
                              {discount_allocations?.length ? (
                                <div>
                                  <span
                                    style={{
                                      textDecoration: 'line-through',
                                    }}
                                  >
                                    {parseInt(price).toLocaleString('id', {
                                      style: 'currency',
                                      currency: 'IDR',
                                    })}
                                  </span>

                                  <br />

                                  <span>
                                    {(
                                      parseInt(price) -
                                      discount_allocations.reduce(
                                        (acc, curr) =>
                                          acc + parseInt(curr.amount),
                                        0
                                      ) /
                                      quantity
                                    ).toLocaleString('id', {
                                      style: 'currency',
                                      currency: 'IDR',
                                    })}
                                  </span>

                                  <span> x {quantity}</span>
                                </div>
                              ) : (
                                <span>
                                  {`${parseInt(price || 0).toLocaleString(
                                    'id',
                                    {
                                      style: 'currency',
                                      currency: 'IDR',
                                    }
                                  )} x ${quantity || 1}`}
                                </span>
                              )}
                            </div>

                            <div className="col-3 py-2 border-bottom">
                              <span>
                                {(
                                  (discount_allocations?.length
                                    ? parseInt(price) -
                                    discount_allocations.reduce(
                                      (acc, curr) =>
                                        acc + parseInt(curr.amount),
                                      0
                                    ) /
                                    quantity
                                    : parseInt(price || 0)) * (quantity || 1)
                                ).toLocaleString('id', {
                                  style: 'currency',
                                  currency: 'IDR',
                                })}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <div className="Polaris-Card__Section d-flex justify-content-center rounded-0 border-0">
                    <Pagination
                      label={this.state.pageNumber}
                      hasNext={
                        this.state.page.skip + 5 >
                          this.state.sourceItems?.length
                          ? false
                          : true
                      }
                      hasPrevious={this.state.pageNumber <= 1 ? false : true}
                      onNext={async () => {
                        await this.handlePagination('next');
                      }}
                      onPrevious={async () => {
                        await this.handlePagination('prev');
                      }}
                    />
                  </div>
                </div>

                {/* <div className="Polaris-Card card-shipment">
                  <div className="card-wrapper">
                    <Card.Section>
                      <div className="card-title">
                        {this._renderShipment(
                          t,
                          'tag',
                          this.state.detailData?.shipment,
                          this.state.detailData?.isCOD
                        )}
                      </div>
                    </Card.Section>

                    <Card.Section>
                      <div className="card-content">
                        <div className="mb-4">
                          <img src="/assets/img/pin.png" alt="pin" />
                          <span className="mr-3">
                            {`${this.state.detailData?.origin?.address2?.toUpperCase()}, ${this.state.detailData?.origin?.city?.toUpperCase()}` ||
                              '-'}{' '}
                            -{' '}
                            {isEmpty(this.state.updateAddress)
                              ? `${this.state.detailData?.customer?.address?.address2}, ${this.state.detailData?.customer?.address?.city}`
                              : `${this.state.updateAddress.address2}, ${this.state.updateAddress.city}`}
                          </span>
                          {has(this.state.detailData, 'deliveryType') && (
                            <Badge
                              status={
                                this.state.detailData?.deliveryType === 'pickup'
                                  ? 'attention'
                                  : 'warning'
                              }
                            >
                              {
                                (
                                  <h3
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 600,
                                    }}
                                  >
                                    {`${t(
                                      `order.resourceItem.badge.deliveryType.${this.state.detailData?.deliveryType}`
                                    )}`}
                                  </h3>
                                ) as any
                              }
                            </Badge>
                          )}
                        </div>

                        <div className="col-12 airwaybill">
                          <div className="row wrapper">
                            <span className="title">
                              {this.state.courier
                                ? `${this.state.courier} - ${this.state.tempShippingChargeData?.service}`
                                : this.state.detailData.trackingInfo
                                ? `${this.state.detailData.trackingInfo?.courier?.toUpperCase()} - ${
                                    this.state.detailData.trackingInfo?.service
                                  }`
                                : '—'}
                            </span>
                            <span className="awb">
                              {this.state.airwaybill
                                ? this.state.airwaybill
                                : this.state.detailData.trackingInfo
                                ? this.state.detailData.trackingInfo
                                    .airwaybill === '-'
                                  ? '—'
                                  : this.state.detailData.trackingInfo.airwaybill?.toUpperCase()
                                : '—'}
                            </span>
                          </div>
                        </div>

                        <div className="col-12 charge">
                          <div className="row">
                            <div className="col-12 d-flex title">
                              <div className="col-9">
                                {(this.state.detailData.shipment < 3 ||
                                  this.state.detailData.shipment === 99) && (
                                  <Link
                                    to={'#'}
                                    onClick={() =>
                                      this.modalShippingChargeShow()
                                    }
                                  >
                                    <span>
                                      {t('detail.card.shipment.shipping')}
                                    </span>
                                  </Link>
                                )}

                                {this.state.detailData.shipment > 2 &&
                                  this.state.detailData.shipment !== 99 && (
                                    <span>
                                      {t('detail.card.shipment.shipping')}
                                    </span>
                                  )}
                              </div>

                              <div className="col-3">
                                <span>{shipCost}</span>
                              </div>
                            </div>

                            {!this.state.isHideInsurance && (
                              <div className="col-12 d-flex align-items-center value">
                                <div className="col-9">
                                  {(this.state.detailData.shipment < 3 ||
                                    this.state.detailData.shipment === 99) && (
                                    <Checkbox
                                      label={t(
                                        'detail.card.shipment.insurance'
                                      )}
                                      checked={this.state.insuranceChecked}
                                      disabled={this.state.insuranceDisabled}
                                      onChange={(v) =>
                                        this.handleInsuranceChecked(v)
                                      }
                                    />
                                  )}
                                  {this.state.detailData.shipment > 2 &&
                                    this.state.detailData.shipment !== 99 && (
                                      <span>
                                        {t('detail.card.shipment.insurance')}
                                      </span>
                                    )}
                                </div>

                                <div className="col-3">
                                  <span>
                                    {this.state.insuranceValue ||
                                    this.state.insuranceValue === 0
                                      ? this.state.insuranceValue?.toLocaleString(
                                          'id',
                                          {
                                            style: 'currency',
                                            currency: 'IDR',
                                          }
                                        )
                                      : this.state.detailData.transaction
                                      ? this.state.detailData.transaction.feeInsurance?.toLocaleString(
                                          'id',
                                          {
                                            style: 'currency',
                                            currency: 'IDR',
                                          }
                                        )
                                      : '—'}
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="col-12 d-flex">
                              <div className="col">
                                <span>
                                  {t('detail.card.shipment.shippingRate')}
                                </span>
                              </div>
                              <div className="col">
                                <span>
                                  {this.state.detailData
                                    ?.shippingRateSelected || '-'}
                                </span>
                              </div>
                              <div className="col-3">
                                <span>
                                  {this.state.tempShippingChargeData.price?.toLocaleString(
                                    'id',
                                    { style: 'currency', currency: 'IDR' }
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Section>
                  </div>
                </div> */}

                <div className="Polaris-Card card-shipment">
                  <div className="Polaris-Card__Header">
                    <div className="row col-12 d-flex align-items-center">
                      <div className="col-1 px-0 text-center icon-title mr-2">
                        <img
                          src={
                            this.state.detailData?.shipment === 3 ||
                              this.state.detailData?.shipment === 4 ||
                              this.state.detailData?.shipment === 5
                              ? CheckInfo
                              : CheckGrey
                          }
                          alt=""
                        />
                      </div>

                      <div className="col-11 px-0">
                        <Text variant="headingMd" as="h4">
                          {this._renderShipment(
                            t,
                            'status',
                            this.state.detailData?.shipment,
                            this.state.detailData?.isCOD
                          )}
                        </Text>
                      </div>
                    </div>
                  </div>

                  <div className="Polaris-Card__Section py-3">
                    <div className="row col-12 d-flex align-items-center pb-2">
                      <div className="col-1 px-0 text-center icon-title mr-2">
                        <img src={MapPin} alt="" />
                      </div>

                      <div className="col-11 px-0">
                        <span className="subtitle">Location</span>

                        <div className="d-flex">
                          <span>
                            {`${this.state.detailData?.origin?.address2?.toUpperCase()}, ${this.state.detailData?.origin?.city?.toUpperCase()}` ||
                              '-'}
                          </span>

                          <img src={ArrowRight} alt="" className="mx-2" />

                          <span>
                            {isEmpty(this.state.updateAddress)
                              ? `${this.state.detailData?.originalDestinationAddress?.subdistrict}, ${this.state.detailData?.originalDestinationAddress?.city}`
                              : `${this.state.updateAddress.address2}, ${this.state.updateAddress.city}`}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row col-12 d-flex align-items-center py-2">
                      <div className="col-1 px-0 text-center icon-title mr-2">
                        <img src={Truck} alt="" />
                      </div>

                      <div className="col-11 px-0">
                        <span className="subtitle">Shipping Profile</span>

                        <div className="d-flex">
                          <span>
                            {this.state.courier
                              ? `${this.state.courier} (${this.state.tempShippingChargeData?.service})`
                              : this.state.detailData.trackingInfo
                                ? `${this.state.detailData.trackingInfo?.courier?.toUpperCase()} (${this.state.detailData.trackingInfo?.service
                                })`
                                : '—'}
                          </span>

                          {(this.state.airwaybill ||
                            this.state.detailData.trackingInfo?.airwaybill) && (
                              <div className="d-flex">
                                <img src={ArrowRight} alt="" className="mx-2" />

                                <span>
                                  {this.state.airwaybill
                                    ? this.state.airwaybill
                                    : this.state.detailData.trackingInfo
                                      ?.airwaybill
                                      ? this.state.detailData.trackingInfo.airwaybill?.toUpperCase()
                                      : '—'}
                                </span>
                              </div>
                            )}

                          <span className="pl-2">
                            {(this.state.detailData.shipment < 3 ||
                              this.state.detailData.shipment === 99) && (
                                <Link
                                  to={'#'}
                                  onClick={() => this.modalShippingChargeShow()}
                                >
                                  <span>Change</span>
                                </Link>
                              )}
                          </span>
                        </div>
                      </div>
                    </div>

                    {!this.state.isHideInsurance && (
                      <div className="col-12 d-flex justify-content-between align-items-center px-1 pt-1">
                        {(this.state.detailData.shipment < 3 ||
                          this.state.detailData.shipment === 99) && (
                            <Checkbox
                              label={t('detail.card.shipment.insurance')}
                              checked={this.state.insuranceChecked}
                              disabled={this.state.insuranceDisabled}
                              onChange={(v) => this.handleInsuranceChecked(v)}
                            />
                          )}

                        {this.state.detailData.shipment > 2 &&
                          this.state.detailData.shipment !== 99 && (
                            <span className="py-1">
                              {t('detail.card.shipment.insurance')}
                            </span>
                          )}

                        <span>
                          {this.state.insuranceValue ||
                            this.state.insuranceValue === 0
                            ? this.state.insuranceValue?.toLocaleString('id', {
                              style: 'currency',
                              currency: 'IDR',
                            })
                            : this.state.detailData.transaction
                              ? this.state.detailData.transaction.feeInsurance?.toLocaleString(
                                'id',
                                {
                                  style: 'currency',
                                  currency: 'IDR',
                                }
                              )
                              : '—'}
                        </span>
                      </div>
                    )}

                    <div className="col-12 d-flex justify-content-between p-1 pt-1">
                      <div className="d-flex">
                        <span>{t('detail.card.shipment.shippingRate')}</span>

                        <span className="pl-2">
                          {this.state.detailData?.shippingRateSelected || '—'}
                        </span>
                      </div>

                      <span>
                        {this.state.tempShippingChargeData.price?.toLocaleString(
                          'id',
                          { style: 'currency', currency: 'IDR' }
                        ) || '—'}
                      </span>
                    </div>

                    <div className="col-12 d-flex justify-content-between p-1 pt-1 shipping-charge">
                      <span>{t('detail.card.shipment.shipping')}</span>

                      <span>{shipCost || '—'}</span>
                    </div>
                  </div>
                </div>

                {/* <div className="Polaris-Card card-fulfillment">
                  <div className="card-wrapper">
                    <Card.Section>
                      <div className="card-title">
                        {this._renderFulfillment(
                          t,
                          'tag',
                          this.state.detailData?.fulfillment,
                          this.state.detailData?.item
                        )}
                      </div>
                    </Card.Section>

                    <Card.Section>
                      <div className="card-content">
                        <div className="row col-12 title">
                          <div className="col-3">
                            <span>Item</span>
                          </div>

                          <div className="col-3">
                            <span>SKU</span>
                          </div>

                          <div className="col-3">
                            <span>Quantity</span>
                          </div>

                          <div className="col-3">
                            <span>Total</span>
                          </div>
                        </div>

                        <div className="table">
                          <ResourceList
                            items={this.state.items}
                            renderItem={(item) => {
                              const {
                                id,
                                title,
                                sku,
                                discount_allocations,
                                price,
                                quantity,
                              }: any = item;

                              return (
                                <ResourceItem key={id} id={id} url={''}>
                                  <div className="row col-12">
                                    <div className="col-3">
                                      <span>{title || ''}</span>
                                    </div>

                                    <div className="col-3">
                                      <span>{sku === '' ? '—' : sku}</span>
                                    </div>

                                    <div className="col-3">
                                      {discount_allocations?.length ? (
                                        <div>
                                          <span
                                            style={{
                                              textDecoration: 'line-through',
                                            }}
                                          >
                                            {parseInt(price).toLocaleString(
                                              'id',
                                              {
                                                style: 'currency',
                                                currency: 'IDR',
                                              }
                                            )}
                                          </span>

                                          <br />

                                          <span>
                                            {(
                                              parseInt(price) -
                                              discount_allocations.reduce(
                                                (acc, curr) =>
                                                  acc + parseInt(curr.amount),
                                                0
                                              ) /
                                                quantity
                                            ).toLocaleString('id', {
                                              style: 'currency',
                                              currency: 'IDR',
                                            })}
                                          </span>

                                          <span> x {quantity}</span>
                                        </div>
                                      ) : (
                                        <span>
                                          {`${parseInt(
                                            price || 0
                                          ).toLocaleString('id', {
                                            style: 'currency',
                                            currency: 'IDR',
                                          })} x ${quantity || 1}`}
                                        </span>
                                      )}
                                    </div>

                                    <div className="col-3">
                                      <span>
                                        {(
                                          (discount_allocations?.length
                                            ? parseInt(price) -
                                              discount_allocations.reduce(
                                                (acc, curr) =>
                                                  acc + parseInt(curr.amount),
                                                0
                                              ) /
                                                quantity
                                            : parseInt(price || 0)) *
                                          (quantity || 1)
                                        ).toLocaleString('id', {
                                          style: 'currency',
                                          currency: 'IDR',
                                        })}
                                      </span>
                                    </div>
                                  </div>
                                </ResourceItem>
                              );
                            }}
                          />
                        </div>

                        <div className="d-flex justify-content-center pt-3">
                          <Pagination
                            label={this.state.pageNumber}
                            hasNext={
                              this.state.page.skip + 5 >
                              this.state.sourceItems?.length
                                ? false
                                : true
                            }
                            hasPrevious={
                              this.state.pageNumber <= 1 ? false : true
                            }
                            onNext={async () => {
                              await this.handlePagination('next');
                            }}
                            onPrevious={async () => {
                              await this.handlePagination('prev');
                            }}
                          />
                        </div>
                      </div>
                    </Card.Section>
                  </div>
                </div> */}

                <div className="Polaris-Card card-payment">
                  <div className="Polaris-Card__Header">
                    <div className="row col-12 d-flex align-items-center">
                      <div className="col-1 px-0 text-center icon-title mr-2">
                        <img
                          src={
                            this.state.detailData?.payment === 1
                              ? CheckSuccess
                              : CheckGrey
                          }
                          alt=""
                        />
                      </div>

                      <div className="col-11 px-0">
                        <Text variant="headingMd" as="h4">
                          {this._renderPayment(
                            t,
                            'status',
                            this.state.detailData?.payment
                          )}
                        </Text>
                      </div>
                    </div>
                  </div>

                  <div className="Polaris-Card__Section p-0 px-2 pt-2">
                    <div className="col-12 d-flex justify-content-between py-1">
                      <span>{t('detail.card.payment.subtotal')}</span>

                      <span>
                        {this.state.detailData.transaction
                          ? (
                            this.state.detailData.transaction.subtotal -
                            (this.state.detailData.transaction.discountCoupon
                              ? this.state.detailData.transaction
                                .discountCoupon
                              : 0)
                          ).toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                          })
                          : '—'}
                      </span>
                    </div>

                    <div className="col-12 d-flex justify-content-between py-1">
                      <span>{t('detail.card.payment.shipping')}</span>

                      <div>
                        <span>
                          {
                            this.state.tempShippingChargeData.originalPrice !==
                            this.state.tempShippingChargeData.price && (
                              <span style={{ textDecoration: 'line-through' }}>
                                {this.state.tempShippingChargeData.originalPrice.toLocaleString(
                                  'id',
                                  {
                                    style: 'currency',
                                    currency: 'IDR',
                                  }
                                )}
                                &nbsp;
                              </span>
                            )
                          }
                        </span >

                        <span>
                          {this.state.shippingChargeValue ||
                            this.state.shippingChargeValue === 0
                            ? this.state.shippingChargeValue.toLocaleString(
                              'id',
                              {
                                style: 'currency',
                                currency: 'IDR',
                              }
                            )
                            : this.state.detailData.transaction
                              ? this.state.detailData.transaction.shippingCharge?.toLocaleString(
                                'id',
                                {
                                  style: 'currency',
                                  currency: 'IDR',
                                }
                              )
                              : '—'}
                        </span>
                      </div >
                    </div >

                    <div className="col-12 d-flex justify-content-between py-1">
                      <span>{t('detail.card.payment.unique')}</span>

                      <span>
                        {this.state.detailData.transaction
                          ? has(this.state.detailData, 'uniqueCode')
                            ? this.state.detailData.uniqueCode?.toLocaleString(
                              'id',
                              {
                                style: 'currency',
                                currency: 'IDR',
                              }
                            )
                            : '-'
                          : '—'}
                      </span>
                    </div>

                    <div className="col-12 d-flex justify-content-between py-1">
                      <span>{t('detail.card.payment.insurance')}</span>

                      <span>
                        {this.state.insuranceValue ||
                          this.state.insuranceValue === 0
                          ? this.state.insuranceValue?.toLocaleString('id', {
                            style: 'currency',
                            currency: 'IDR',
                          })
                          : this.state.detailData.transaction
                            ? this.state.detailData.transaction.feeInsurance?.toLocaleString(
                              'id',
                              {
                                style: 'currency',
                                currency: 'IDR',
                              }
                            )
                            : '—'}
                      </span>
                    </div>

                    {
                      this.state.detailData.transaction?.subtotal && (
                        <div className="col-12 d-flex justify-content-between py-1">
                          <span>{t('detail.card.payment.tax')}</span>

                          <span>
                            {this.state.detailData.transaction
                              ? this.state.detailData.transaction.tax?.toLocaleString(
                                'id',
                                {
                                  style: 'currency',
                                  currency: 'IDR',
                                }
                              )
                              : '—'}
                          </span>
                        </div>
                      )
                    }
                  </div >

                  <div className="Polaris-Card__Section p-0 px-2 pb-3">
                    <div className="col-12 d-flex justify-content-between py-1 total">
                      <span>{t('detail.card.payment.total')}</span>

                      <span>
                        {this.state.transactionTotal?.toLocaleString('id', {
                          style: 'currency',
                          currency: 'IDR',
                        }) || '—'}
                      </span>
                    </div>
                  </div>
                </div >

                {/* <div className="Polaris-Card card-payment">
                  <div className="card-wrapper">
                    <Card.Section>
                      <div className="card-title">
                        {this._renderPayment(
                          t,
                          'tag',
                          this.state.detailData?.payment
                        )}
                      </div>
                    </Card.Section>

                    <Card.Section>
                      <div className="card-content">
                        <div className="row">
                          <div className="col-12 d-flex subtotal">
                            <div className="col-9">
                              <span>{t('detail.card.payment.subtotal')}</span>
                            </div>

                            <div className="col-3">
                              <span>
                                {this.state.detailData.transaction
                                  ? (
                                      this.state.detailData.transaction
                                        .subtotal -
                                      (this.state.detailData.transaction
                                        .discountCoupon
                                        ? this.state.detailData.transaction
                                            .discountCoupon
                                        : 0)
                                    ).toLocaleString('id', {
                                      style: 'currency',
                                      currency: 'IDR',
                                    })
                                  : '—'}
                              </span>
                            </div>
                          </div>

                          <div className="col-12 d-flex shipping-cost">
                            <div className="col-9">
                              <span>{t('detail.card.payment.shipping')}</span>
                            </div>

                            <div className="col-3">
                              <span>
                                {this.state.detailData?.transaction
                                  ?.originalShippingCharge !==
                                  this.state.detailData?.transaction
                                    ?.shippingCharge && (
                                  <span
                                    style={{ textDecoration: 'line-through' }}
                                  >
                                    {this.state.detailData?.transaction?.originalShippingCharge?.toLocaleString(
                                      'id',
                                      {
                                        style: 'currency',
                                        currency: 'IDR',
                                      }
                                    )}
                                    &nbsp;
                                  </span>
                                )}
                              </span>
                              <span>
                                {this.state.shippingChargeValue ||
                                this.state.shippingChargeValue === 0
                                  ? this.state.shippingChargeValue.toLocaleString(
                                      'id',
                                      {
                                        style: 'currency',
                                        currency: 'IDR',
                                      }
                                    )
                                  : this.state.detailData.transaction
                                  ? this.state.detailData.transaction.shippingCharge?.toLocaleString(
                                      'id',
                                      {
                                        style: 'currency',
                                        currency: 'IDR',
                                      }
                                    )
                                  : '—'}
                              </span>
                            </div>
                          </div>

                          <div className="col-12 d-flex unique-code">
                            <div className="col-9">
                              <span>{t('detail.card.payment.unique')}</span>
                            </div>

                            <div className="col-3">
                              <span>
                                {this.state.detailData.transaction
                                  ? has(this.state.detailData, 'uniqueCode')
                                    ? this.state.detailData.uniqueCode?.toLocaleString(
                                        'id',
                                        {
                                          style: 'currency',
                                          currency: 'IDR',
                                        }
                                      )
                                    : '-'
                                  : '—'}
                              </span>
                            </div>
                          </div>

                          <div className="col-12 d-flex insurance">
                            <div className="col-9">
                              <span>{t('detail.card.payment.insurance')}</span>
                            </div>

                            <div className="col-3">
                              <span>
                                {this.state.insuranceValue ||
                                this.state.insuranceValue === 0
                                  ? this.state.insuranceValue?.toLocaleString(
                                      'id',
                                      {
                                        style: 'currency',
                                        currency: 'IDR',
                                      }
                                    )
                                  : this.state.detailData.transaction
                                  ? this.state.detailData.transaction.feeInsurance?.toLocaleString(
                                      'id',
                                      {
                                        style: 'currency',
                                        currency: 'IDR',
                                      }
                                    )
                                  : '—'}
                              </span>
                            </div>
                          </div>

                          {this.state.detailData.transaction?.subtotal && (
                            <div className="col-12 d-flex tax">
                              <div className="col-9">
                                <span>{t('detail.card.payment.tax')}</span>
                              </div>

                              <div className="col-3">
                                <span>
                                  {this.state.detailData.transaction
                                    ? this.state.detailData.transaction.tax?.toLocaleString(
                                        'id',
                                        {
                                          style: 'currency',
                                          currency: 'IDR',
                                        }
                                      )
                                    : '—'}
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="col-12 d-flex total">
                            <div className="col-9">
                              <span>{t('detail.card.payment.total')}</span>
                            </div>

                            <div className="col-3">
                              <span>
                                {this.state.transactionTotal?.toLocaleString(
                                  'id',
                                  {
                                    style: 'currency',
                                    currency: 'IDR',
                                  }
                                ) || '—'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Section>
                  </div>
                </div> */}
              </div >

              {/* RIGHT SECTION */}
              < div className="col-4" >
                <div className="Polaris-Card card-notification">
                  <div className="card-wrapper">
                    <div className="card-title">
                      {t('detail.card.notification.title')}
                    </div>

                    <p>
                      {this.state.detailData.notification === '' ||
                        this.state.detailData.notification === null ||
                        this.state.detailData.notification === undefined
                        ? '—'
                        : this.state.detailData.notification}
                    </p>
                  </div>
                </div>

                <div className="Polaris-Card card-notes">
                  <div className="card-wrapper">
                    <div className="card-title">
                      {t('detail.card.notes.title')}
                    </div>

                    <p>
                      {this.state.detailData?.customer?.note
                        ? this.state.detailData.customer.note
                        : '—'}
                    </p>
                  </div>
                </div>

                <div className="Polaris-Card card-customer">
                  <div className="Polaris-Card__Section">
                    <div className="card-title">
                      <span>{t('detail.card.customer.title')}</span>
                      {(this.state.detailData.shipment < 3 ||
                        this.state.detailData.shipment === 99) && (
                          <span
                            onClick={() =>
                              this.setState({ isModalUpdateCustomerShow: true })
                            }
                            className="float-right"
                            style={{ cursor: 'pointer' }}
                          >
                            <Icon source={EditMajor} color="base" />
                          </span>
                        )}
                    </div>

                    <p>
                      {this.state.detailData.customer
                        ? this.state.detailData.customer.fullName
                        : '—'}
                    </p>

                    <p>
                      {this.state.detailData.customer
                        ? this.state.detailData.customer.phone === '-' ||
                          this.state.detailData.customer.phone === null
                          ? '—'
                          : (this.state.updateCustomer?.customer?.phone ?? this.state.detailData.customer.phone)
                        : '—'}
                    </p>

                    <p>
                      {this.state.detailData.customer
                        ? this.state.detailData.customer.email
                        : '—'}
                    </p>
                  </div>

                  <div className="Polaris-Card__Section">
                    <div className="card-title">
                      <span>{t('detail.card.customer.subtitle')}</span>

                      {(this.state.detailData.shipment < 3 ||
                        this.state.detailData.shipment === 99) && (
                          <span
                            onClick={() =>
                              this.setState({ isModalUpdateAddressShow: true })
                            }
                            className="float-right"
                            style={{ cursor: 'pointer' }}
                          >
                            <Icon source={EditMajor} color="base" />
                          </span>
                        )}
                    </div>

                    <p>
                      {isEmpty(this.state.updateAddress)
                        ? this.state.detailData.customer
                          ? this.state.detailData.customer.address
                            ? `${this.state.detailData.customer.address.address1}, ${this.state.detailData.customer.address.address2}, ${this.state.detailData.customer.address.city}, ${this.state.detailData.customer.address.province}. ${this.state.detailData.customer.address.zip} - ${this.state.detailData.customer.address.country}`
                            : '—'
                          : '—'
                        : `${this.state.updateAddress.address1}, ${this.state.updateAddress.address2}, ${this.state.updateAddress.city}, ${this.state.updateAddress.province}. ${this.state.updateAddress.zip} - ${this.state.updateAddress.country}`}
                    </p>
                  </div>
                </div>

                <div className="Polaris-Card card-seller">
                  <div className="Polaris-Card__Section">
                    <div className="card-title">
                      {t('detail.card.seller.title')}
                    </div>

                    <p>
                      {this.state.detailData.items
                        ? this.state.detailData.shopInfo
                          ? this.state.detailData.shopInfo
                            .name
                          : '—'
                        : '—'}
                    </p>

                    <p>
                      {this.state.detailData.items
                        ? this.state.detailData.shopInfo
                          ? this.state.detailData.shopInfo
                            .phone === '-' ||
                            this.state.detailData.shopInfo
                              .phone === null
                            ? '—'
                            : this.state.detailData.shopInfo
                              .phone
                          : '—'
                        : '—'}
                    </p>

                    <p>
                      {this.state.detailData.items
                        ? this.state.detailData.shopInfo
                          ? this.state.detailData.shopInfo.email
                          : '—'
                        : '—'}
                    </p>
                  </div>

                  <div className="Polaris-Card__Section">
                    <div className="card-title">
                      <span>{t('detail.card.seller.subtitle')}</span>
                    </div>

                    <p>
                      {this.state.detailData.items
                        ? this.state.detailData.origin
                          ? `${this.state.detailData.origin.address1}, ${this.state.detailData.origin.address2}, ${this.state.detailData.origin.city}, ${this.state.detailData.origin.province}. ${this.state.detailData.origin.zip} - ${this.state.detailData.origin.country_code}`
                          : '—'
                        : '—'}
                    </p>
                  </div>
                </div>
              </div >
            </div >

            {
              this.state.outbondData?.length > 0 && (
                <div className="outbond">
                  <div className="outbond-title">Outbond</div>
                  <div className="outbond-content">
                    {this.state.outbondData.map((outbond, i) => {
                      return (
                        <div className="row mb-4">
                          <div className="col-12 col-md-6">
                            <div className="row">
                              <div className="col-4">
                                <span
                                  className={
                                    i === 0
                                      ? `outbond-active`
                                      : `outbond-inactive`
                                  }
                                ></span>
                                <span className="ml-4">
                                  {dayjs(outbond.outbound_date).format(
                                    'DD/MM/YYYY hh:mm a'
                                  )}
                                </span>
                              </div>
                              <div className="col-4">
                                <strong>{outbond.outbound_code}</strong>
                              </div>
                              <div className="col-4">
                                {outbond.outbound_description || '-'}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )
            }

            < div >
              <Modal
                open={this.state.isModalUpdateOriginShow}
                onClose={() =>
                  this.setState({ isModalUpdateOriginShow: false })
                }
                title="Update Origin"
              >
                <Modal.Section>
                  <UpdateOriginComponent
                    handleSubmit={this.handleUpdateOrigin}
                  />
                </Modal.Section>
              </Modal>
            </div >

            <div>
              <Modal
                open={this.state.isModalUpdateAddressShow}
                onClose={() =>
                  this.setState({ isModalUpdateAddressShow: false })
                }
                title={`${t('order.detail.modal.title')}`}
              >
                <Modal.Section>
                  <DetailOrderUpdateAddressPage
                    handleSubmit={(v) => this.handleUpdateAddress(v)}
                    address={
                      isEmpty(this.state.updateAddress)
                        ? this.state.detailData.customer &&
                        this.state.detailData.customer.address && {
                          address1: this.state.detailData?.customer?.address
                            ?.address1,
                          address2: this.state.detailData?.customer?.address
                            ?.address2,
                          addressCode: this.state.detailData?.customer
                            ?.address?.addressCode,
                          city: this.state.detailData?.customer?.address
                            ?.city,
                          province: this.state.detailData?.customer?.address
                            ?.province,
                          zip: this.state.detailData?.customer?.address?.zip,
                        }
                        : {
                          address1: this.state.updateAddress.address1,
                          address2: this.state.updateAddress.address2,
                          addressCode: this.state.updateAddress.addressCode,
                          city: this.state.updateAddress.city,
                          province: this.state.updateAddress.province,
                          zip: this.state.updateAddress.zip,
                        }
                    }
                  />
                </Modal.Section>
              </Modal>
            </div>

            <div>
              <Modal
                open={this.state.isModalUpdateCustomerShow}
                onClose={() =>
                  this.setState({ isModalUpdateCustomerShow: false })
                }
                title={`${t('order.detail.modal.customer.title')}`}
              >
                <Modal.Section>
                  <DetailOrderUpdateCustomer
                    data={{
                      customer: {
                        phone: isEmpty(this.state.updateCustomer) && this.state.detailData.customer ? this.state.detailData?.customer?.phone : this.state.updateCustomer?.customer?.phone
                      }
                    }}
                    onSubmit={this.updateCustomerPhone}
                    onCancel={() => this.setState({ isModalUpdateCustomerShow: false })}
                  />
                </Modal.Section>
              </Modal>
            </div>

            <div>
              <Modal
                open={this.state.isModalShippingLabelShow}
                onClose={() =>
                  this.setState({
                    isModalShippingLabelShow: false,
                    dataPDF: null,
                  })
                }
                title={`${t('order.detail.modal.print.title')}`}
                noScroll
              >
                {this.state.isModalShippingLoading && (
                  <div
                    style={{
                      display: 'block',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'black',
                      opacity: 0.5,
                      borderBottomLeftRadius: '8px',
                      borderBottomRightRadius: '8px',
                    }}
                  >
                    <div
                      style={{
                        paddingTop: '10%',
                        paddingBottom: '10%',
                        paddingLeft: '46%',
                      }}
                    >
                      <Spinner accessibilityLabel="Loading..." size="large" />
                    </div>
                  </div>
                )}

                <div className="shipping-label-control">
                  <Checkbox
                    label={`${t('order.modal.shipping.thermal')}`}
                    checked={this.state.thermalMode}
                    onChange={this.handleThermalModeChange}
                  />
                </div>

                <embed
                  width="100%"
                  height="345px"
                  src={this.state.dataPDF}
                  key={this.state.dataPDF}
                />
              </Modal>
            </div>

            <div>
              <Modal
                open={this.state.isModalShippingChargeShow}
                onClose={() => this.handleModalCloseShippingCharge()}
                title={t('order.detail.modal.shipping.title')}
              >
                {this.state.isModalShippingChargeLoading &&
                  this.renderLoading('absolute', '8px', '8px')}

                <Modal.Section>
                  <Form onSubmit={() => this.handleShippingChargeSubmit()}>
                    {this.state.notMatchAddress ? (
                      <Banner
                        title="Shipping charge tidak ditemukan"
                        status="critical"
                      >
                        <p>
                          Mohon periksa kembali alamat pengiriman yang dituju
                          karena sistem mendeteksi alamat tersebut belum sesuai.
                        </p>
                      </Banner>
                    ) : (
                      <>
                        <ChoiceList
                          title={''}
                          selected={this.state.selectedShippingCharge}
                          choices={this.state.choiceShippingCharge}
                          onChange={(v) => this.handleShippingChargeChange(v)}
                        />
                        <div className="mt-4 text-right">
                          <Button submit>{`${t(
                            'order.detail.modal.shipping.update'
                          )}`}</Button>
                        </div>
                      </>
                    )}
                  </Form>
                </Modal.Section>
              </Modal>
            </div>

            {
              this.state.successToast && (
                <Frame>
                  <Toast
                    content={this.state.successMessage}
                    onDismiss={() => this.setState({ successToast: false })}
                    duration={10000}
                  />
                </Frame>
              )
            }

            {
              this.state.failToast && (
                <Frame>
                  <Toast
                    error
                    content={this.state.failMessage}
                    onDismiss={() => {
                      this.setState({
                        failToast: false,
                      });
                    }}
                    duration={10000}
                  />
                </Frame>
              )
            }
          </>
        )
        }
      </Translation>
    );
  }
}

export default withRouter(DetailOrderFormComponent);
