import { ServerApiHttpService } from '../../libraries/server/server-api-http.service';
import { environment } from '../../environments/environment';
import { ResponseShopConfiguration, ShopConfigurationPayload } from './config.model';

export class ConfigRestService extends ServerApiHttpService {
  private static serverApiHttpService = new ServerApiHttpService(environment.server.mainApiBaseUrl + '/v1/main/shop-config');

  public static async getConfig() {
    return this.serverApiHttpService.get<ResponseShopConfiguration>('configuration').toPromise();
  }

  public static async saveConfig(data: ShopConfigurationPayload) {
    return this.serverApiHttpService.post('configuration', data).toPromise();
  }

  public static async noInsuranceCourier() {
    return this.serverApiHttpService.get('no-insurance-couriers').toPromise();
  }
}
