import React from 'react';
import {
  Layout,
  Page
} from '@shopify/polaris';
import { LoginFormComponent } from './login-form.component';
import { Translation } from 'react-i18next';

export class LoginPage extends React.Component<any, any> {
  render() {
    return (
      <Translation>
        {
          t => (
            <Page
              fullWidth
            >
              <div className="login-page mt-5">
                <LoginFormComponent />
              </div>
            </Page>
          )
        }
      </Translation>
    );
  }
}
