import { get, has } from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

declare interface IHandleLoadAllRequest {
  obs?: (params?: any) => Observable<any>;
  method?: 'post' | 'get'
  successMessage?: string;
  qParams?: any;
  errorMessage?: string;
  onDone?: (data: any) => void;
  onError?: (err: any) => void;
  onUpdate?: (data: any) => void;
  hideError?: boolean;
  handleTap?: (response) => any;
  clearOnDone?: boolean;
}

export class HandleService {
  dataLoadAll = {
    records: [],
    totalSkip: 0,
    totalRecord: 0,
  };

  handleLoadAllReq(data: IHandleLoadAllRequest) {
    let params = { ...data.qParams };
    const { obs, onDone, onError, onUpdate, handleTap, method, clearOnDone } = data;

    return obs(params)
      .pipe(
        catchError((error) => {
          const errs = get(error.response, 'data.errors[0].error_message', '') || error;
          onError && onError(errs || '');
          return throwError(error);
        }),
        tap((response) => {
          handleTap && handleTap(response);
        })
      )
      .subscribe((response) => {
        this.dataLoadAll.records = [
          ...this.dataLoadAll.records,
          ...has(response.data, 'list') ? response.data['list'] : has(response, 'list') ? response.list : response.data,
        ];
        this.dataLoadAll.totalSkip += has(response, 'list') ? response.list.take : response.data.take || 0;
        this.dataLoadAll.totalRecord = has(response, 'list') ? response.list.take : response.data.total || 0;
        if (this.dataLoadAll.totalSkip < this.dataLoadAll.totalRecord) {
          switch (method) {
            case 'post':
              params.skip = this.dataLoadAll.totalSkip;
              break;
            default:
              params['skip'] = this.dataLoadAll.totalSkip;
              break;
          }
          data.qParams = params;
          this.handleLoadAllReq(data);
          onUpdate && onUpdate(this.dataLoadAll);
        } else {
          this.handleLoadAllReq(data).unsubscribe();
          this.dataLoadAll.totalSkip = this.dataLoadAll.totalRecord;
          onDone && onDone(this.dataLoadAll);
          if (clearOnDone) {
            this.dataLoadAll = {
              records: [],
              totalSkip: 0,
              totalRecord: 0,
            };
          }
        }
      });
  }
}
