import React from 'react';
import {
  Layout,
  Page
} from '@shopify/polaris';
import { url } from 'inspector';
import { HomePageRestService } from './home-page-rest.service';
import i18n from 'i18next';
export class HomePage extends React.Component<any, any> {
  componentDidMount() {
    HomePageRestService.getLanguage().then((res) => {
      i18n.changeLanguage(res);
    });
  }

  render() {
    return (
      <img
        style={{
          width: '100%',
          paddingTop: '5%',
          paddingLeft: '5%',
          paddingRight: '5%',
          paddingBottom: '5%',
          backgroundSize: 'cover'
        }}
        src='../../assets/img/Shipdeo.png'
      />
    );
  }
}
