import React from 'react';
import {
  Button,
  Form,
  FormLayout,
  Layout,
  Modal,
  Spinner,
  Toast,
  Frame,
  TextField
} from '@shopify/polaris';
import { Field, Form as ReactFinalForm } from 'react-final-form';
import { FinalFormPolarisAdapter } from '../../libraries/final-form/polaris/final-form-polaris.adapter';
import { LoginRestService } from './login-rest.service';
import { Translation } from 'react-i18next';
import './login.scss';
import isEmpty from 'lodash/isEmpty';
import { DuplicateMinor, HideMinor, ViewMinor } from '@shopify/polaris-icons';
export class LoginFormState {
  shopConfig: any;
  confirmModal = false;
  isLoading = false;
  successToast = {
    visible: false,
    content: '',
    duration: 0
  };
  failToast = {
    visible: false,
    content: '',
    duration: 0
  };
  isShowSecret = false
}

export class LoginFormComponent extends React.Component<any, LoginFormState> {
  constructor(props) {
    super(props);
    this.state = new LoginFormState();
  }

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    this.setState({
      isLoading: true
    });

    const shopConfig = await LoginRestService.getConfig()
      .then(
        (response) => {
          return response.data;
        }
      )
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });

    this.setState({
      shopConfig
    });
  }

  async onSettingSave(t, value, isTest?) {
    await LoginRestService.saveConfig(value)
      .then(
        (response) => {
          this.setState({
            successToast: {
              visible: true,
              content: isTest ? "Connection Successful" : t('login.success').toString(),
              duration: 4000,
            }
          });
          this.loadData();
          this.props.loadDataShippingData();
        },
        (err) => {
          this.setState({
            failToast: {
              visible: true,
              content: isTest ? "Connection Unsuccessful (Silahkan masuk ke menu Setting > Location)" : "Failed save Credentials",
              duration: 10000
            },
          });
        }
      )
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  onCheckConnection(t, value) {
    this.setState({
      isLoading: true
    });

    LoginRestService.checkConnection()
      .then(
        (response) => {
          // this.setState({
          //   successToast: {
          //     visible: true,
          //     content: "Connection Successful",
          //     duration: 4000,
          //   }
          // });
          // this.loadData();
          this.onSettingSave(t, value, true)
        },
        (err) => {
          this.setState({
            failToast: {
              visible: true,
              content: "Connection Unsuccessful (Silahkan masuk ke menu Setting > Location)",
              duration: 10000
            },
            isLoading: false
          });
        }
      )
      .finally(() => {
        // this.setState({
        //   isLoading: false
        // });
      });
  }

   copyToClipboard(id) {
    const element: any = document.getElementById(id);
    element.setAttribute('type', 'text');
    const isClientSecret = id === 'clientSecret';
    element.select();
    navigator.clipboard.writeText(element.value);
    this.setState({
      successToast: {
        visible: true,
        content: 'Success copy to clipboard!',
        duration: 2000
      }
    })
    isClientSecret && element.setAttribute('type', 'password');
  }

  showClientSecret() {

  }

  render() {
    return (
      <Translation>
        {
          t => (
            <ReactFinalForm
              initialValues={
                this.state.shopConfig
              }
              onSubmit={(values) => {
                this.onSettingSave(t, values);
              }}
            >
              {({
                handleSubmit
              }) => (
                <>
                  {this.state.isLoading && (
                    <div
                      style={{
                        zIndex: 1000,
                        display: 'block',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'black',
                        opacity: 0.5
                      }}
                    >
                      <div
                        style={{
                          paddingTop: '23%',
                          paddingLeft: '48.5%'
                        }}
                      >
                        <Spinner
                          accessibilityLabel="Loading..."
                          size="large"
                        />
                      </div>
                    </div>
                  )
                  }
                  <div className="d-none">
                    <Frame>
                      {this.state.successToast.visible ? (
                        <div id="success-toast">
                          <Toast
                            content={
                              this.state.successToast.content
                            }
                            onDismiss={
                              () => {
                                this.setState({
                                  successToast: {
                                    visible: false,
                                    content: '',
                                    duration: 0,
                                  }
                                });
                              }
                            }
                            duration={
                              this.state.successToast.duration
                            }
                          />
                        </div>
                      ) : this.state.failToast.visible && (
                        <div id="fail-toast">
                          <Toast
                            error
                            content={
                              this.state.failToast.content
                            }
                            onDismiss={
                              () => {
                                this.setState({
                                  failToast: {
                                    visible: false,
                                    content: '',
                                    duration: 0,
                                  }
                                });
                              }
                            }
                            duration={
                              this.state.failToast.duration
                            }
                          />
                        </div>
                      )}
                    </Frame>
                  </div>
                  <Layout>
                    <Layout.Section>
                      <Form
                        onSubmit={handleSubmit}
                      >
                        <FormLayout>
                          <Field
                            name={
                              'clientId'
                            }
                            // label={
                            //   t('login.clientId')
                            // }
                            // component={
                            //   FinalFormPolarisAdapter.textField
                            // }
                          >
                            {props => (
                              <>
                                <div className='Polaris-Labelled__LabelWrapper'>
                                  <div className="Polaris-Label">
                                    <label className='Polaris-Label__Text'>{t('login.clientId')}</label>
                                  </div>

                                  {(!isEmpty(this.state.shopConfig?.clientId) && !isEmpty(this.state.shopConfig?.clientSecret)) && (
                                    <div className="Polaris-Label">
                                      <label className='Polaris-Label__Text'>
                                        <Button plain destructive onClick={() => {
                                          this.setState({
                                            shopConfig: {
                                              ...this.state.shopConfig,
                                              clientId: null,
                                              clientSecret: null
                                            }
                                          })
                                        }}>
                                          Update
                                        </Button>
                                      </label>
                                    </div>
                                  )}
                                </div>

                                <div className="d-flex align-items-center">
                                  <div className="w-100">
                                    <TextField
                                      id="clientID"
                                      autoComplete=""
                                      label=""
                                      name={props.input.name}
                                      value={props.input.value}
                                      onChange={props.input.onChange}
                                    />
                                  </div>
                                  
                                  {/* {props.input.value && (
                                    <div className="ml-1">
                                      <Button icon={DuplicateMinor} accessibilityLabel="Copy Client ID" onClick={() => this.copyToClipboard('clientID')} />
                                    </div>
                                  )} */}
                                </div>
                              </>
                            )}
                          </Field>

                          <Field
                            name={
                              'clientSecret'
                            }
                            // label={
                            //   t('login.clientSecret')
                            // }
                            // component={
                            //   (val) => FinalFormPolarisAdapter.textField({ type: 'password', ...val })
                            // }
                          >
                            {props => (
                              <>
                                <div className='Polaris-Labelled__LabelWrapper'>
                                  <div className="Polaris-Label">
                                    <label className='Polaris-Label__Text'>{t('login.clientSecret')}</label>
                                  </div>
                                </div>
                              
                                <div className="d-flex align-items-center">
                                  <div className="w-100">
                                    <TextField
                                      id="clientSecret"
                                      autoComplete=""
                                      label=""
                                      name={props.input.name}
                                      value={props.input.value}
                                      onChange={props.input.onChange}
                                      type={this.state.isShowSecret ? "text" : "password"}
                                    />
                                  </div>
                                  
                                  {props.input.value && (
                                    <div className="ml-1">
                                      <Button icon={this.state.isShowSecret ? HideMinor : ViewMinor} accessibilityLabel="Copy Client Secret" onClick={() => this.setState({isShowSecret: !this.state.isShowSecret})} />
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </Field>
                        </FormLayout>
                        <div className="pt-3 row">
                          <div className="col-8">
                            {(!isEmpty(this.state.shopConfig?.clientId) && !isEmpty(this.state.shopConfig?.clientSecret)) && (
                              <div className="custom-badge-checklist">
                                <img src="../../assets/img/checklist.png" alt="checklist-active" /><span>Active</span>
                              </div>
                            )}
                          </div>
                          <div className="col-4 pl-0">
                            {(!isEmpty(this.state.shopConfig?.clientId) && !isEmpty(this.state.shopConfig?.clientSecret)) ? (
                              <Button
                                primary
                                fullWidth
                                onClick={
                                  () => {this.onCheckConnection(t, this.state.shopConfig)}
                                }
                              >
                                {t('shipping.test-connection').toString()}
                              </Button>
                            ) : (
                              <Modal
                                title={
                                  t('login.confirm')
                                }
                                activator={
                                  <Button
                                    primary
                                    fullWidth
                                    onClick={
                                      () => {
                                        this.setState({
                                          confirmModal: true
                                        })
                                      }
                                    }
                                  >
                                    {t('login.save').toString()}
                                  </Button>
                                }
                                open={
                                  this.state.confirmModal
                                }
                                onClose={
                                  () => {
                                    this.setState({
                                      confirmModal: false
                                    })
                                  }
                                }
                                primaryAction={{
                                  content: t('login.save').toString(),
                                  onAction: () => {
                                    handleSubmit();

                                    this.setState({
                                      confirmModal: false,
                                      isLoading: true
                                    });
                                  }
                                }}
                                secondaryActions={[
                                  {
                                    content: t('login.cancel').toString(),
                                    onAction: () => {
                                      this.setState({
                                        confirmModal: false
                                      })
                                    }
                                  }
                                ]}
                              >
                                <Modal.Section>
                                  <p>
                                    {t('login.confirmText')}
                                  </p>
                                </Modal.Section>
                              </Modal>
                            )}
                          </div>
                        </div>
                      </Form>
                    </Layout.Section>
                  </Layout>
                </>
              )}
            </ReactFinalForm>
          )
        }
      </Translation>
    );
  }
}
