import { ServerApiHttpService } from '../../../libraries/server/server-api-http.service';
import { environment } from '../../../environments/environment';

export class DetailOrderUpdateAddressRestService extends ServerApiHttpService {
  private static serverApiHttpService = new ServerApiHttpService(
    environment.server.mainApiBaseUrl + '/v1/main/location'
  );

  public static async getLocation(data) {
    return this.serverApiHttpService
      .post('', data)
      .toPromise();
  }

  public static async getPostalCode(data) {
    return this.serverApiHttpService
      .post('/postalcode', data)
      .toPromise();
  }
}
