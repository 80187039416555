import React from 'react';
import {
  Layout,
  Page,
  Button,
  PageActions
} from '@shopify/polaris';
import DetailOrderUpdateAddressFormComponent from './detail-order-update-address-form.component';
import { Translation } from 'react-i18next';

export class DetailOrderUpdateAddressPage extends React.Component<any, any> {
  render() {
    return (
      <Translation>
        {
          t => (
            <Page fullWidth>
              <DetailOrderUpdateAddressFormComponent handleSubmit={this.props.handleSubmit} orderId={this.props.orderId} address={this.props.address} isInsuranced={this.props.isInsuranced} />
            </Page>
          )
        }
      </Translation>
    );
  }
}
