import React, { createContext } from 'react';
import { appReducer } from './app-reducer';

const initialState = {
  subscriptionData: null,
};

export const AppContext = createContext<{
    state: typeof initialState;
    dispatch: React.Dispatch<any>;
  }>({
    state: initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispatch: () => {},
  });

export class AppContextProvider extends React.Component {
  state = initialState;

  dispatch = action => {
    this.setState(prevState => appReducer(prevState, action));
  };

  render() {
    return (
      <AppContext.Provider value={{ state: this.state, dispatch: this.dispatch }}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}